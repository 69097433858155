
import * as React from 'react'
import * as PropTypes from 'prop-types'

import { circle } from 'react-dom-factories'

class ReactCircle extends React.PureComponent {
  props!: CircleProps

  render() {
    // loc = "#{this.props.location.top}, #{this.props.location.left}"
    // console.log "loc: #{loc}"

    return circle({
      cx: this.props.location.left,
      cy: this.props.location.top,
      r: (this.props.size / 2) - 6,
      stroke: this.props.stroke,
      strokeWidth: this.props.preview ? '3' : '6',
      fill: 'none',
      opacity: 0.95
    })
  }
}

interface CircleProps {
  location: {
    left: number
    top: number
  }
  size: number
  stroke: string
  preview: boolean
}

// ReactCircle.propTypes = {
//   location: PropTypes.shape({
//     left: PropTypes.number,
//     top: PropTypes.number
//   }).isRequired,
//   size: PropTypes.number.isRequired,
//   stroke: PropTypes.string.isRequired,
//   preview: PropTypes.bool.isRequired
// }

export { ReactCircle }
