/* eslint no-console: ["error", { allow: ["log", "warn", "error"] }] */
/* eslint no-constant-condition: "off" */

import { TypeKeys } from '../types'
import * as ActionTypes from '../actions'

import * as _ from 'lodash'

import { UserStates } from '../user_states'
import { setCurrentMove } from './explorerActions'
import {
  currentPosition, nextChoicesForAmbiguousMoves,
  moveChildrenIndices, squaresInCommonOfAmbiguousMoves
} from '../reducers/explorerFunctions'

import { Rules } from '../../engine/rules'
import { Position } from '../../engine/position'
import { Move } from '../../engine/move'

import { getCookie } from '../../enhancements/utilities'

import { RootState } from '../reducers/root'
import { ExplorerState } from '../reducers/explorerReducer'
import { Dispatch } from 'redux'

// End requirements

let enableQuickMoveStr = getCookie('enable_quick_move').toLowerCase() || 'false'
let enableQuickMove = enableQuickMoveStr === 'true'

// End cookie values

const debugAction = function debugAction(msg: string) {
  // console.log(`Action: ${msg}`)
}

// Start functions

// ADD_TO_MOVE dragged to square to bump pieceMoveIndex
const didClickSquare = (pdns: ReadonlyArray<number>) => pdns.length === 1

const didDragPiece = (pdns: ReadonlyArray<number>) => pdns.length === 2

const didDragNewPiece = (pdns: ReadonlyArray<number>, state: RootState) =>
  didDragPiece(pdns) && (state.userMove.movementSquares[0] !== pdns[0])

function wrongTeamSelected(enginePosition: Position) {
  return function dispatchWrongTeamSelected(dispatch: Dispatch<RootState>) {
    const moves = Rules.getAllLegalMoves(enginePosition)
    let pieces = moves.map((move) => move.startSquare.pdn())

    pieces = enginePosition.friendlyPiecePdns()

    dispatch({
      type: TypeKeys.WRONG_TEAM_SELECTED,
      pieces
    } as ActionTypes.MovedWrongTeamAction)
  }
}

function missedCaptureMove(enginePosition: Position) {
  return function dispatchMissedCaptureMove(dispatch: Dispatch<RootState>) {
    const captureMoves = Rules.getAllLegalMoves(enginePosition)
    let capturesPdns = captureMoves.map((move) => move.touchedPdns().slice(0, 2))
    capturesPdns = _.uniq(capturesPdns)

    dispatch({
      type: TypeKeys.MISSED_CAPTURE_MOVE,
      captures: capturesPdns
    } as ActionTypes.MissedCaptureMoveAction)
  }
}

function buildMentionedSquares(pdns: ReadonlyArray<number>, state: RootState) {
  let mentioned
  if (didClickSquare(pdns)) {
    mentioned = state.userMove.movementSquares.concat(pdns)
  } else if (didDragNewPiece(pdns, state)) { // length is 2, they dragged a piece
    mentioned = pdns
  } else { // if didDragPiece(pdns)
    mentioned = state.userMove.movementSquares.concat(pdns.slice(1))
  }
  return mentioned
}

function handleNoMovesMatch(matchingMoves: Move[], enginePosition: Position,
  dispatch: Dispatch<RootState>) {
  if (matchingMoves.length === 0) {
    debugAction('No moves match.')
    if (Rules.captureRequired(enginePosition)) {
      dispatch(missedCaptureMove(enginePosition))
    } else {
      dispatch({
        type: TypeKeys.DESELECT_PIECE
      } as ActionTypes.DeselectPieceAction)
    }
    return true
  }
  return false
}

function handleMultipleMovesMatch(matchingMoves: Move[], matchingSquares: number[],
  nextAnimateIndex: number, dispatch: Dispatch<RootState>) {
  debugAction('Multiple moves match.')

  const nextChoices = nextChoicesForAmbiguousMoves(matchingMoves)
  // Need to find first occurance after current animation point of dragged
  // to square in matching squares And then animate from there.
  dispatch({
    type: TypeKeys.ADD_TO_MOVE,
    squares: matchingSquares,
    choices: nextChoices,
    pieceMoveIndex: nextAnimateIndex
  } as ActionTypes.AddSquareToMoveAction)
}

function findNextAnimateIndex(pdns: number[], matchingSquares: number[],
  state: RootState) {
  let nextAnimateIndex
  if (didClickSquare(pdns)) {
    nextAnimateIndex = state.userMove.pieceMoveIndex
  } else {
    nextAnimateIndex = _.max([state.userMove.pieceMoveIndex, 0])
    nextAnimateIndex = _.findIndex(
      matchingSquares,
      ((square) => square === _.last(pdns)),
      nextAnimateIndex,
    )

    if (nextAnimateIndex === -1) { // they didn't add a pdn relevant to the ambiguous moves.
      nextAnimateIndex = state.userMove.pieceMoveIndex
    }
  }

  return nextAnimateIndex
}

function getStoredMove(engineMove: Move, explorer: ExplorerState) {
  const currentMoveChildrenIdxs = moveChildrenIndices(explorer, explorer.currentMove)
  const currentMoveChildren = currentMoveChildrenIdxs.map((idx) => explorer.moves[idx])

  // Prefer the move on the main line.
  let storedMove = currentMoveChildren.find(
    ((move) =>
      (move.description === engineMove.toString()) &&
      _.includes(explorer.mainLine, move.id)
    )
  )

  if (!storedMove) {
    storedMove = currentMoveChildren.find((move) => move.description === engineMove.toString())
  }

  return storedMove
}

function allowedMove(state: RootState, enginePosition: Position) {
  if (state.problemInfo.problemId != null) {
    if ((state.problemInfo.playerWhite === enginePosition.whiteToPlay) ||
       state.problemInfo.solved) {
      return true
    }
    return false
  }
  // No problem so can move anything.
  return true
}

function makeMove(enginePosition: Position, engineMove: Move,
  nextAnimateIndex: number, dispatch: Dispatch<RootState>, state: RootState) {
  if (!allowedMove(state, enginePosition)) {
    debugAction(`Not allowed: ${engineMove}`)
    dispatch({
      type: TypeKeys.DESELECT_PIECE
    } as ActionTypes.DeselectPieceAction)
    return
  }

  debugAction(`making move: ${engineMove} from: ${nextAnimateIndex}`)
  const storedMove = getStoredMove(engineMove, state.explorer)

  // Order here is tricky, setting the current move rerenders the pieces
  // but they don't have a userMove to animate yet. But if you animate first
  // then it rerenders without a position. So add_new_move and set_current_move
  // need to do both.
  if (storedMove) {
    dispatch(setCurrentMove(storedMove.id, nextAnimateIndex))
  } else {
    dispatch({
      type: TypeKeys.ADD_NEW_MOVE,
      move: engineMove,
      nextPosition: enginePosition.nextPosition(engineMove),
      animateSquares: engineMove.touchedPdns(),
      animateIndex: nextAnimateIndex,
      animateCapture: engineMove.isCapture(),
    } as ActionTypes.AddNewMoveAction)
  }
}

function addToMove(pdns: number[], enginePosition: Position,
  dispatch: Dispatch<RootState>, state: RootState) {
  debugAction(`Adding ${pdns} to move.`)
  const mentioned = buildMentionedSquares(pdns, state)
  const matchingMoves = Rules.allMatchingMoves(enginePosition, mentioned)

  if (handleNoMovesMatch(matchingMoves, enginePosition, dispatch)) { return }

  const matchingSquares = squaresInCommonOfAmbiguousMoves(matchingMoves)
  const nextAnimateIndex = findNextAnimateIndex(pdns, matchingSquares, state)

  if (matchingMoves.length > 1) {
    handleMultipleMovesMatch(
      matchingMoves, matchingSquares,
      nextAnimateIndex, dispatch,
    )
  } else {
    makeMove(
      enginePosition, matchingMoves[0], nextAnimateIndex,
      dispatch, state
    )
  }
}

function quickMove(pdn: number, enginePosition: Position,
  dispatch: Dispatch<RootState>, state: RootState) {
  // Don't quick move if they've selected a different piece, they might
  // be trying to deselect it.
  const { userMove } = state
  if (!enableQuickMove ||
         ((userMove.status !== UserStates.NOTHING_SELECTED) &&
         ((userMove.movementSquares[0] !== pdn) || (userMove.movementSquares.length !== 1)))) {
    return false
  }

  const matchingMoves = Rules.allMatchingMoves(enginePosition, [pdn])
  if (matchingMoves.length !== 1) { return false }

  debugAction('quick move!')
  makeMove(enginePosition, matchingMoves[0], 0, dispatch, state)
  return true
}

function selectPiece(clickedPDN: number, enginePosition: Position,
  dispatch: Dispatch<RootState>, state: RootState) {
  const friendlyPdns = enginePosition.friendlyPiecePdns()
  const enemyPdns = enginePosition.enemyPiecePdns()

  if (_.includes(enemyPdns, clickedPDN)) {
    dispatch(wrongTeamSelected(enginePosition))
    return true
  } else if (_.includes(friendlyPdns,clickedPDN)) {
    if (_.first(state.userMove.movementSquares) === clickedPDN) {
      dispatch({
        type: TypeKeys.DESELECT_PIECE
      } as ActionTypes.DeselectPieceAction)
    } else {
      dispatch({
        type: TypeKeys.SELECT_PIECE,
        pdn: clickedPDN
      } as ActionTypes.SelectPieceAction)
    }
    return true
  }
  return false
}

export function dragPiece(startPDN: number, endPDN: number) {
  return function dispatchDragPiece(dispatch: Dispatch<RootState>,
    getState: () => RootState) {
    const state = getState()
    const curPos = currentPosition(state.explorer)

    const enemyPdns = curPos.enemyPiecePdns()
    if (_.includes(enemyPdns, startPDN)) {
      dispatch(wrongTeamSelected(curPos))
    } else {
      addToMove([startPDN, endPDN], curPos, dispatch, state)
    }
  }
}

export function clickSquare(clickedPDN: number) {
  return function dispatchClickSquare(dispatch: Dispatch<RootState>,
    getState: () => RootState) {
    const state = getState()

    const curPos = currentPosition(state.explorer)

    if (quickMove(clickedPDN, curPos, dispatch, state)) { return }
    if (selectPiece(clickedPDN, curPos, dispatch, state)) { return }
    // do nothing if first click isn't on a piece and isn't a quick move.
    if (state.userMove.movementSquares.length > 0) {
      addToMove([clickedPDN], curPos, dispatch, state)
    }
  }
}

// Unused?
export function changeUserMoveState(newState: UserStates) {
  return function dispatchChangeUserMoveState(dispatch: Dispatch<RootState>,
    getState: () => RootState) {
    dispatch({
      type: TypeKeys.CHANGE_USER_MOVE_STATE,
      newState
    } as ActionTypes.ChangeUserMoveStateAction)
  }
}

const UserActions = {
  clickSquare,
  dragPiece,
  changeUserMoveState
}

export { UserActions }
