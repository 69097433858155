import * as jQuery from 'jquery'

jQuery(document).ready(($) => {
  // Remove empty fields from GET forms
  // Author: Bill Erickson
  // URL: http://www.billerickson.net/code/hide-empty-fields-get-form/
  $('#game-search-form').submit(function OnSubmitGameSearch() {

    $(this).find(':input').filter(function isInputFilled() {
      return !(this as HTMLInputElement).value
    }).attr('disabled', 'disabled')

    $(':input[name=player_win_radio]:checked').filter(function isPlayerWinChecked() {
      return (this as HTMLInputElement).value === 'any'
    }).attr('disabled', 'disabled')

    $(':input[name=color_win_radio]:checked').filter(function isColorWinChecked() {
      return (this as HTMLInputElement).value === 'any'
    }).attr('disabled', 'disabled')

    $(':input[name=player_side_radio]:checked').filter(function isPlayerSideChecked() {
      return (this as HTMLInputElement).value === 'player_either'
    }).attr('disabled', 'disabled')

    return true
  }) // ensure form still submits
  // Un-disable form fields when page loads,
  // in case they click back after submission
  $('#game-search-form').find(':input').prop('disabled', false)
})
