
import { connect } from 'react-redux'

import { Votes, IVotesProps } from '../components/votes'
import gameInfo from '../components/game_info'

import { getCookie } from '../../enhancements/utilities'
import { RootState } from '../reducers/root'

let userId = parseInt(getCookie('user_id'), 10)
if (!userId) {
  userId = 0
}

const VotesApp = {
  // called with two params, state and ownProps
  mapStateToProps(state: RootState): IVotesProps {
    if(state.problemInfo.problemId == null){
      return {
        show: false,
        problemId: -1,
        userId
      }
    }
    return {
      problemId: state.problemInfo.problemId,
      show: state.problemInfo.solved,
      userId
    }
  },

  // called with two params, dispatch and ownProps
  mapDispatchToProps() {
    return {}
  }
}

export default connect(
  VotesApp.mapStateToProps,
  VotesApp.mapDispatchToProps,
)(Votes as any)
