/* eslint no-console: off */

import * as $ from 'jquery'
import { Direction, getDirection } from './direction'

class Square {
  squareNum:number
  whoPromotes:string
  isBorder:boolean
  // Square number is 1-45, not 1-31.
  constructor(squareNum: number) {
    this.squareNum = squareNum
    this.whoPromotes = ''
    this.isBorder = false
  }

  pdn() {
    console.assert(!this.isBorder)
    let numPadding = 4 // there are actually 5 initial borders but pdn is 1 offset.
    if (this.squareNum > 12) { numPadding += 1 }
    if (this.squareNum > 21) { numPadding += 1 }
    if (this.squareNum > 30) { numPadding += 1 } // skip borders as they appear
    return this.squareNum - numPadding
  }

  // toString for using as the key to the move previewer's hash.
  toString() {
    return `Square_${this.pdn()}`
  }

  toJQuery() {
    const id = `#s_${this.pdn()}`
    return $(id)
  }

  isAdjacent(otherSquare: Square): boolean {
    const distance = Math.abs(this.squareNum - otherSquare.squareNum)
    if (distance === 4 || distance === 5) {
      return true
    } else {
      return false
    }
  }

  isJumpAway(otherSquare: Square): boolean {
    const distance = Math.abs(this.squareNum - otherSquare.squareNum)
    if (distance === 8 || distance === 10) {
      return true
    } else {
      return false
    }
  }

  directionTo(otherSquare: Square): Direction {
    let offset = otherSquare.squareNum - this.squareNum

    if (Math.abs(offset) > 5) {
      offset = offset / 2
    }

    // The reason for the crazy square numbers is so that diagnoally
    // adjacent square are always offset by 4 or 5.
    console.assert(Math.abs(offset) == 4 || Math.abs(offset) == 5)
    return getDirection(offset)
  }
}

export { Square }

