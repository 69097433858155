import { Dispatch } from 'redux'

import { connect } from 'react-redux'

import { Arrows } from '../components/arrows'

import { RootState } from '../reducers/root'

interface StateProps {
  animationDone: boolean
  arrows: ReadonlyArray<ReadonlyArray<number>>
  highlights: ReadonlyArray<number>
  currentlyOver: number | null
  downOn: number | null
}

interface OwnProps {
  squareRefs: HTMLDivElement[]
  boardRef: HTMLDivElement | null
  boardSelection: string
}

interface DispatchProps {

}

const ArrowsApp = {
  mapStateToProps(state: RootState, ownProps: OwnProps): StateProps {
    return {
      animationDone: state.animate.done,
      arrows: state.arrows.arrows,
      highlights: state.arrows.highlights,
      currentlyOver: state.arrows.rightMouseCurrentlyOver,
      downOn: state.arrows.rightMouseDownOn
    }
  },

  mapDispatchToProps(dispatch: Dispatch<RootState>): DispatchProps {
    return {}
  }
  // return bindActionCreators(ArrowActions, dispatch)
}

export default connect(
  ArrowsApp.mapStateToProps,
  ArrowsApp.mapDispatchToProps,
)(Arrows as any)
