/* eslint no-console: ["error", { allow: ["log", "warn", "error"] }] */
/* eslint no-constant-condition: "off" */
/* eslint react/forbid-prop-types: ["error", {"forbid": ["any", "array"] }] */

import * as React from 'react'
import * as PropTypes from 'prop-types'

//@ts-ignore TODO: These are of type any. # Should replace this with classes
import createReactClass from 'create-react-class'

import { BoardPiece, BoardPieceProps } from '../components/board_piece'

//@ts-ignore, TODO: Should replace this with react-motion for classes and types.
import tweenState from 'react-tween-state'

import { playMoveSound, playCaptureSound, loadSounds } from '../sound'

import { UserStates } from '../user_states'

import { div } from 'react-dom-factories'

import * as _ from 'lodash'
import * as $ from 'jquery'

import { Progression } from '../reducers/bootReducer'
import { AnimationState} from '../reducers/animationReducer'
import { ExplorerMove } from '../reducers/explorerReducer'
import { Move } from '../../engine/move'
import { Position } from '../../engine/position'
import { UserMoveState } from '../reducers/userMoveReducer'
import { Piece } from '../../engine/piece'

import { onNextFrame } from '../utilities'

const e = React.createElement;

let timer = 0

const emptyState = {
  movingFromIdx: 0,
  leftPx: 0,
  topPx: 0
}

function debugComponent(msg: string) {
  // console.log(`Pieces: ${msg}`)
}

interface IPiecesProps {
  moveInfo: Move
  // Position to render, and animate the move from if there is one
  prePosition: Position | null
  // Position to render, if there is no move or we're not animating it.
  postPosition: Position
  // The piece set to use.
  pieceSelection: string

  userMove: UserMoveState

  animate: AnimationState

  squareRefs: HTMLDivElement[]

  storeBeginAnimation: () => void
  storeEndAnimation: () => void
  doneRenderPieces: () => void
}

interface IPiecesState {
  position: Position
  movingFromIdx: number
  leftPx: number
  topPx: number
}

interface IEasing {
  top: any //Actually a tweenState.easingTypes.linear
  left: any //Or a tweenState.easingTypes.easeOutCircular or easeInCircular
}

// TODO: Should become a regular es6 class. But then I'd need a replacement
// for tweenState that is not a mixin and I'm not sure how to do that yet.
const ReactPieces = createReactClass({
  displayName: 'Pieces',
  mixins: [tweenState.Mixin],

  propTypes: {
    // The move to animate if there is one.
    moveInfo: PropTypes.object,
    // Position to render, and animate the move from if there is one
    prePosition: PropTypes.object,
    // Position to render, if there is no move or we're not animating it.
    postPosition: PropTypes.object.isRequired,
    // The piece set to use.
    pieceSelection: PropTypes.string.isRequired,

    // A (typically partial) user move to display. For instance they dragged
    // a piece forward, it should stay there while they click on the
    // disambiguating squares
    userMove: PropTypes.object.isRequired,

    // A started and a done boolean to keep track of the animation state.
    animate: PropTypes.object.isRequired,

    // Actions to tell the rest of the world our animation state.
    storeBeginAnimation: PropTypes.func.isRequired,
    storeEndAnimation: PropTypes.func.isRequired,

    // References the squares on which we will place the pieees
    squareRefs: PropTypes.array.isRequired
  },

  // We can't correctly render the pieces until we know there is a board DOM
  // This maybe would be better done by our parent which is root but it's already
  // got the provider thing. Maybe another level?
  getInitialState() {
    return emptyState
  },

  // Called just once after the first render to the screen. Calls setState
  // which will trigger a second render.
  componentDidMount() {
    $(window).on('resize', this.handleResizeEvent)
    this.handleResizeEvent()
  },

  // Called when it receives new props but we can set state and only rerender
  // once. So we can create and save the engine move we'll be animating.
  // Not called for the initial render, but is when we get the refs
  UNSAFE_componentWillReceiveProps(nextProps: IPiecesProps) {
    debugComponent('nextProps')
    if (this.props.userMove.status !== UserStates.NOTHING_SELECTED &&
        nextProps.userMove.status === UserStates.NOTHING_SELECTED) {
      debugComponent('resetting movingFromIdx')
      this.setState({
        movingFromIdx: 0
      })
    }

    // I've debugged this animation logic so many times, just leave these.
    // debugComponent(`started: ${this.props.animate.started}, ${nextProps.animate.started}`)
    // debugComponent(`done: ${this.props.animate.done}, ${nextProps.animate.done}`)
    // debugComponent(`status: ${this.props.userMove.status}, ${nextProps.userMove.status}`)
    // debugComponent(`moveInfo: ${this.props.moveInfo && this.props.moveInfo.description},
    //   ${nextProps.moveInfo && nextProps.moveInfo.description}`)
    // debugComponent(`prePosition: ${this.props.prePosition && this.props.prePosition.fen},
    //   ${nextProps.prePosition && nextProps.prePosition.fen}`)
    // debugComponent(`postPosition: ${this.props.postPosition.fen}, ${nextProps.postPosition.fen}`)

    let forceSkipAnimation
    if ((nextProps.animate.started === false) && (nextProps.animate.done === false)) {
      if ((((nextProps.userMove.status === UserStates.HAVE_NEW_FULL_MOVE) &&
         !_.isEqual(this.props.moveInfo, nextProps.moveInfo) &&
         !_.isEqual(this.props.postPosition, nextProps.postPosition) &&
         nextProps.prePosition) ||
         (nextProps.userMove.status === UserStates.HAVE_NEW_PARTIAL_MOVE)) &&
         !nextProps.animate.started) {
        this.startAnimation(nextProps)
      } else {
        // skip when going backward or reshowing same move, or showing initial move.
        this.skipAnimation(nextProps)
        forceSkipAnimation = true
      }
    }

    // Not quite the same as skipping animation because we
    // do need to change the position when going backwards but we don't need
    // to animate.
    if ((nextProps.userMove.status === UserStates.HAVE_NEW_FULL_MOVE) &&
       nextProps.prePosition &&
       !forceSkipAnimation) {
      debugComponent(`Using new pre-position: ${nextProps.prePosition.toFenDescription()}`)
      if (!_.isEqual(this.state.position, nextProps.prePosition)) {
        this.setState({
          position: nextProps.prePosition
        })
      }
    } else {
      debugComponent(`Using new post-position: ${nextProps.postPosition.toFenDescription()}`)
      if (!_.isEqual(this.state.position, nextProps.postPosition)) {
        this.setState({
          position: nextProps.postPosition
        })
      }
    }
  },

  // DO NOT CALL setState! userful for playing with the DOM if we still
  // want to do that. Didn't work out well last time.
  componentDidUpdate(prevProps: IPiecesProps, prevState: IPiecesState) {
    // console.log(`Pieces did update: refs: ${this.props.squareRefs.length},
    //  progress: ${this.props.bootProgress}.`)
    if (this.props.squareRefs.length >= 32) { //Wait until we have all the props
      if (this.props.bootProgress == Progression.BOARD_SHOWN &&
          !timer) { //Only call once
        timer = onNextFrame(() => {
          this.props.doneRenderPieces()
          loadSounds()
        })
      }
    }
  },

  componentWillUnmount() {
    $(window).off('resize', this.handleResizeEvent)
  },

  startAnimation(nextProps: IPiecesProps) {
    // Perf.start()
    const startIndex = nextProps.userMove.pieceMoveIndex
    debugComponent('starting animation.')

    nextProps.storeBeginAnimation() // store must be first so before end
    this.continueAnimationFromIdx(startIndex, nextProps, true, 0)
  },

  skipAnimation(nextProps: IPiecesProps) {
    debugComponent('skipping animation.')
    if (!nextProps.animate.done) {
      nextProps.storeEndAnimation()
    }
  },

  // Careful, continue animation can be triggered by tween state
  // after a setState has cleared the move.
  tweenFinished() {
    debugComponent(`tween finished from ${this.state.movingFromIdx}`)
    const fromIdx = this.state.movingFromIdx
    this.continueAnimationFromIdx(fromIdx, this.props, false, 75)
  },

  continueAnimationFromIdx(startIdx: number, props: IPiecesProps, destructive: boolean, delay: number) {
    // Some ugliness so we can start the animation before the move is saved
    // into the state
    const startPdn = props.userMove.movementSquares[startIdx]
    const nextPdn = props.userMove.movementSquares[startIdx + 1]
    const isCapture = props.userMove.captureMove

    debugComponent(`Continueing animation from ${startIdx}: ${startPdn} towards ${nextPdn}`)

    if (nextPdn == null) {
      this.finishAnimation(props, startIdx)
      return
    }

    // Don't play a sound when the piece starts move, only on hops
    debugComponent(`Should play sound with startIndex: ${startIdx}. \
State: ${this.state.movingFromIdx}. destructive: ${destructive}`)
    // Don't play a sound if they clicked to initiate and we're on the first
    // square.
    if (!destructive || ((startIdx !== 0) && (startIdx !== this.state.movingFromIdx))) {
      this.playSound(props)
    }

    const startSquare = props.squareRefs[startPdn]
    const nextSquare = props.squareRefs[nextPdn]
    const easing = this.getEasingFromCapture(isCapture, startSquare, nextSquare)

    this.setState({ movingFromIdx: startIdx + 1 })
    this.setTweens(startSquare, nextSquare, easing, destructive, delay)
  },

  playSound(props: IPiecesProps) {
    debugComponent('playing sound!')
    if (props.userMove.captureMove) {
      playCaptureSound()
    } else {
      playMoveSound()
    }
  },

  // Remove captured pieces and promote to king or await move disambiguation.
  finishAnimation(props: IPiecesProps, startIdx: number) {
    debugComponent('finishing animation.')
    this.playSound(props)
    this.setState({ movingFromIdx: startIdx })
    props.storeEndAnimation()
  },

  setTweens(startSquare: HTMLDivElement, nextSquare: HTMLDivElement,
    easing: IEasing, destructive: boolean, delay: number) {
    let stackBehavior
    const duration = 250
    if (destructive) {
      stackBehavior = tweenState.stackBehavior.DESTRUCTIVE
    } else {
      stackBehavior = tweenState.stackBehavior.ADDITIVE
    }
    this.tweenState('leftPx', {
      easing: easing.left,
      duration,
      delay,
      beginValue: (startSquare ? startSquare.offsetLeft : 0),
      endValue: (nextSquare ? nextSquare.offsetLeft : 0),
      onEnd: null,
      stackBehavior
    })

    this.tweenState('topPx', {
      easing: easing.top,
      duration,
      delay,
      beginValue: (startSquare ? startSquare.offsetTop : 0),
      endValue: (nextSquare ? nextSquare.offsetTop : 0),
      onEnd: this.tweenFinished,
      stackBehavior
    })
  },


  getEasingFromCapture(capture: boolean, startSquare: HTMLDivElement,
    nextSquare: HTMLDivElement): IEasing {
    const easing = {
      top: tweenState.easingTypes.linear,
      left: tweenState.easingTypes.linear
    }
    if (capture) {
      if (nextSquare.offsetTop <= startSquare.offsetTop) {
        easing.top = tweenState.easingTypes.easeOutCirc
      } else {
        easing.top = tweenState.easingTypes.easeInCirc
      }
    }
    return easing
  },

  handleResizeEvent() {
    this.forceUpdate()
  },

  // Given an pieceJSON, (and a drag-drop move in the future) figure out
  // where to render the piece as a starting location.
  getPieceLocation(piece: Piece) {
    let movementSquares
    const piecePdn = piece.pdn()

    const moveingPiecePdn = _.first(this.props.userMove.movementSquares)

    if (piecePdn !== moveingPiecePdn) {
      // TODO: Can add tweenY state to add bounce.
      return this.refLocation(piecePdn)
    }

    if (this.props.animate.started && !this.props.animate.done) {
      // debugComponent("placing #{piecePdn} along tween")
      const location = {
        top: this.getTweeningValue('topPx'),
        left: this.getTweeningValue('leftPx')
      }
      return location

    // The animation is finished but we don't know if the piece has animated
    // from the start to the decision point or we're showing the end state of
    // a move so we can choose a different reply.
    } else if (this.props.userMove.status === UserStates.AWAITING_DISAMBIGUATION) {
      ({ movementSquares } = this.props.userMove)
      const idx = this.props.userMove.pieceMoveIndex
      const intendedLocation = movementSquares[idx]
      debugComponent(`awaiting ${piecePdn} at ${intendedLocation}`)
      return this.refLocation(intendedLocation)
    }
    // We can ignore the dragged piece square because we're rendering
    // a new position where the piece is where it stops.
    const last = this.props.userMove.movementSquares[this.state.movingFromIdx]
    debugComponent(`moving piece ${piecePdn} from ${this.state.movingFromIdx}.
      If it's done, putting on ${last}`)
    return this.refLocation(last)
  },

  refLocation(pdn: number) {
    let location
    const ref = this.props.squareRefs[pdn]
    if (ref) {
      location = { top: ref.offsetTop, left: ref.offsetLeft }
    } else {
      location = { top: 0, left: 0 }
    }
    return location
  },

  renderEnginePiece(piece: Piece) {
    const loc = this.getPieceLocation(piece)
    const pdn = piece.pdn()

    let height = 0
    if (this.props.squareRefs[1]) {
      height = this.props.squareRefs[1].offsetHeight
    }

    const locationProps = {
      color: piece.colorString(),
      level: piece.levelString(),
      size: height,
      pieceSelection: this.props.pieceSelection,

      left: loc.left,
      top: loc.top,

      pdn,
      key: pdn
    }

    return e(BoardPiece, locationProps)
  },

  // Takes about 15 milliseconds to update.
  render() {
    if(this.props.squareRefs.length < 32) {
      return null
    }

    // const top = this.getTweeningValue('topPx')
    // const left = this.getTweeningValue('leftPx')
    // debugComponent "Render Pieces: (#{left}, #{top})"

    let reactPieces = []
    if (this.state.position && this.state.position.pieces) {
      reactPieces = this.state.position.pieces.map((piece: Piece) =>
        this.renderEnginePiece(piece))
    }

    // debugComponent 'rendering '
    return div({ className: 'pieces' }, reactPieces)
  }
})

export default ReactPieces
