// This passes movePiece and other actions to a draggable_piece.
// The piece calls move piece when react-dnd calls endDrag and also
// renders a simple piece

import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import * as _ from 'lodash'

import { UserActions } from '../actions/userActions'
import * as ArrowActions from '../actions/arrowActions'

import Piece from './draggable_piece'
import { currentMove } from '../reducers/explorerFunctions'
import { RootState } from '../reducers/root'


interface StateProps {
  userCanDragNow: boolean
  colorToPlay: string
}

interface DispatchProps {
  dragPiece: (a: number, b: number) => void
  clickSquare: (pdn: number) => void
  rightMouseDown: (pdn: number) => void
  rightMouseUp: (pdn: number) => void
  rightMouseEnter: (pdn: number) => void
}

// OPTIMIZE: Having a react-redux container at this level has potentially
// 24 connections to the store, one per piece. Faster is probably to pass
// these down directly as props from the pieces component.
const ConnectPieceApp = {
  mapStateToProps(state: RootState): StateProps {
    let userCanDragNow
    const move = currentMove(state.explorer)
    const postPos = state.explorer.positions[move.postPos]

    const colorToPlay = postPos.whiteToPlay ? 'white' : 'black'

    if (state.problemInfo.problemId && state.problemInfo.problemId >= 0) {
      // If the problem is not solved, and it's the computer's turn to play.
      // the user can't drag the piece.
      const started = !!state.problemInfo.startTime
      const { solved } = state.problemInfo
      const compJustPlayed = move.isComputerMove
      const stillAnimating = state.animate.started && !state.animate.done

      if (!solved && (!started || !compJustPlayed || stillAnimating)) {
        userCanDragNow = false
      } else {
        userCanDragNow = true
      }
    } else {
      userCanDragNow = true // Can when exploring an opening
    }

    const stateProps = {
      userCanDragNow,
      colorToPlay
      // Because the next move, the one the user is trying to select is
      // opposite the previous move.
    }
    return stateProps
  },

  mapDispatchToProps(dispatch: Dispatch<RootState>): DispatchProps {
    const actions = Object.assign({}, UserActions, ArrowActions)
    return bindActionCreators(actions, dispatch)
  }
}

const DraggablePieceApp = connect(
  ConnectPieceApp.mapStateToProps,
  ConnectPieceApp.mapDispatchToProps
)(Piece)

export default DraggablePieceApp
