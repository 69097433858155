
import * as React from 'react'
import * as PropTypes from 'prop-types'

import { div } from 'react-dom-factories'

import DragPieceApp from '../containers/draggable_piece_app'
const e = React.createElement;

export class BoardPiece extends React.Component<BoardPieceProps> {
  props!: BoardPieceProps

  shouldComponentUpdate(nextProps: BoardPieceProps) {
    const { props } = this
    if ((props.left === nextProps.left) &&
       (props.top === nextProps.top) &&
       (props.pdn === nextProps.pdn) &&
       (props.color === nextProps.color) &&
       (props.level === nextProps.level) &&
       (props.size === nextProps.size)) {
      return false
    }
    return true
  }

  render() {
    return div(
      {
      // Id and className make testing a little easier.
        id: `p_${this.props.pdn}`,
        className: 'p_',
        style: {
          position: 'absolute',
          left: this.props.left,
          top: this.props.top
        }
      },
      e(DragPieceApp, this.props)
      // Typescript errors because ReactDnDProps get injected
      // in draggable_piece
    )
  }
}

export interface BoardPieceProps {
  left: number
  top: number
  pdn: number
  color: string
  level: string
  size: number
  pieceSelection: string
}

// BoardPiece.propTypes = {
//   // Needed to place the piece on the board
//   left: PropTypes.number.isRequired,
//   top: PropTypes.number.isRequired,

//   // Needed by components/piece
//   pdn: PropTypes.number.isRequired,
//   color: PropTypes.string.isRequired,
//   type: PropTypes.string.isRequired,
//   size: PropTypes.number.isRequired,
//   pieceSelection: PropTypes.string.isRequired
// }

