import * as jQuery from 'jquery'

function setPrices($: JQueryStatic) {
  // console.log 'setting prices'
  const cycleTime = $('input[name="cycle_radio"]:checked').val()
  if (cycleTime === 'monthly') {
    $('#bronze-price').text('Bronze: $3.00 a month')
    $('#silver-price').text('Silver: $5.00 a month')
    $('#gold-price').text('Gold: $8.00 a month')
  } else if (cycleTime === 'yearly') {
    $('#bronze-price').text('Bronze: $24.00 a year')
    $('#silver-price').text('Silver: $40.00 a year')
    $('#gold-price').text('Gold: $64.00 a year')
  }
  // return from set prices
}

jQuery(document).ready(($) => {
  // Remove empty fields from GET forms
  // Author: Bill Erickson
  // URL: http://www.billerickson.net/code/hide-empty-fields-get-form/
  const prices = document.querySelector('#cardForm')
  // console.log 'ready'

  if (prices) {
    // console.log 'prices'

    const cycleButtons = $('input[name="cycle_radio"]')
    // console.log "have cycle buttons #{cycleButtons}"

    cycleButtons.on('change', () =>
      // console.log 'triggered on change'
      setPrices($))

    cycleButtons.trigger('change')
  }
})
