// Redux Actions these (mostly) map 1 to 1 to reducer functions but multiple
// reducers can listen to each action.
//
export enum TypeKeys {
  FLIP_BOARD = 'FLIP_BOARD',
  BEGIN_ANIMATION = 'BEGIN_ANIMATION',
  END_ANIMATION = 'END_ANIMATION',

  // Problem actions
  PROBLEM_START = 'PROBLEM_START',
  PLAYER_WIN = 'PLAYER_WIN',
  PLAYER_LOSE = 'PLAYER_LOSE',
  PLAYER_ALT = 'PLAYER_ALT',

  // AJAX actions to submit attempt results
  SUBMIT_ATTEMPT = 'SUBMIT_ATTEMPT',
  RETURN_ATTEMPT_DATA = 'RETURN_ATTEMPT_DATA',
  ATTEMPT_AJAX_ERROR = 'ATTEMPT_AJAX_ERROR',

  // Explorer actions
  INIT_FEN = 'INIT_FEN',
  INIT_PROBLEM = 'INIT_PROBLEM',
  INIT_MOVE_LIST = 'INIT_MOVE_LIST',
  FORWARD = 'FORWARD',
  BACKWARD = 'BACKWARD',
  BEGINNING = 'BEGINNING',
  END = 'END',
  TOGGLE_AUTO_PLAY = 'TOGGLE_AUTO_PLAY',

  // User move actions
  DRAG_PIECE = 'DRAG_PIECE',
  CLICK_SQUARE = 'CLICK_SQUARE',

  // Intermediate actions
  ADD_TO_MOVE = 'ADD_TO_MOVE',
  SELECT_PIECE = 'SELECT_PIECE',
  DESELECT_PIECE = 'DESELECT_PIECE',
  CHANGE_USER_MOVE_STATE = 'CHANGE_USER_MOVE_STATE',

  // Final move actions
  SET_CURRENT_MOVE = 'SET_CURRENT_MOVE',
  ADD_NEW_MOVE = 'ADD_NEW_MOVE',
  WRONG_TEAM_SELECTED = 'WRONG_TEAM_SELECTED',
  MISSED_CAPTURE_MOVE = 'MISSED_CAPTURE_MOVE',

  // Arrow and highlight actions
  RIGHT_MOUSE_DOWN = 'RIGHT_MOUSE_DOWN',
  RIGHT_MOUSE_ENTER = 'RIGHT_MOUSE_ENTER',
  RIGHT_MOUSE_UP = 'RIGHT_MOUSE_UP',
  RIGHT_MOUSE_LEAVE_BOARD = 'RIGHT_MOUSE_LEAVE_BOARD',

  // Misc actions
  NONE = 'NONE', //No player result, poorly named.
  DONE_RENDER_BOARD = 'DONE_RENDER_BOARD',
  DONE_RENDER_PIECES = 'DONE_RENDER_PIECES',

  // ### These aren't actions they are used for other things
  // React-dnd draggable types.
  PIECE = 'CHECKER_PIECE',

  // Undispatched action to ensure switch handles all cases by typescript.
  // https://spin.atomicobject.com/2017/07/24/redux-action-pattern-typescript/
  OTHER_ACTION = 'ANY_OTHER_ACTION_TYPE'
}

export interface GonAttemptInfo {
  correct: boolean | null
  created_at: string
  finished_at: string | null
  id: number
  mistake: string | null
  problem_id: number
  problem_next_rating: number | null
  problem_next_rd: number | null
  problem_previous_rating: number | null
  problem_previous_rd: number | null
  solve_time: number | null
  started_at: string
  updated_at: string
  user_id: number
  user_next_rating: number | null
  user_next_rd: number | null
  user_previous_rating: number | null
  user_previous_rd: number | null
}

export interface GonGameInfo {
  black_player_name: string
  black_win: boolean
  created_at: string
  date_string: string
  event_name: string
  id: number
  imported_from: string
  move_list: number[]
  problem_scanned: string
  updated_at: string
  white_player_name: string
  white_win: boolean
}

export interface GonPositionInfo {
  board: string
  created_at: string
  id: number
  updated_at: string
  white_to_play: boolean //From the ruby side.
}

export interface GonObject {
  capture_sound_mp3_path: string
  capture_sound_ogg_path: string
  move_sound_mp3_path: string
  move_sound_ogg_path: string
  isUserSignedIn: boolean

  problem?: string
  attempt?: GonAttemptInfo
  game?: GonGameInfo
  position?: GonPositionInfo
  moves?: string[]

  brain_tree_client_token?: string
}
