
import { TypeKeys } from '../types'
import { ActionTypes } from '../actions'

export interface AnimationState {
  readonly started: boolean
  readonly done: boolean
}

// True, true is resting done. False, false is pending. True, false is
// running. False true is illigal.
function initialState(): AnimationState {
  return {
    started: true,
    done: true,
  }
}

export function animate(state: AnimationState, action: ActionTypes): AnimationState {
  if (!state) {
    return initialState()
  }

  const { type } = action

  switch (type) {
    case TypeKeys.BEGIN_ANIMATION:
      // console.log "BEGIN_ANIMATION"
      return Object.assign({}, state, {
        started: true,
        done: false
      })
    case TypeKeys.END_ANIMATION:
      // console.log "END_ANIMATION"
      return Object.assign({}, state, {
        started: true,
        done: true
      })
    // New animations
    case TypeKeys.SET_CURRENT_MOVE:
    case TypeKeys.BACKWARD:
    case TypeKeys.BEGINNING:
    case TypeKeys.END:
    case TypeKeys.ADD_NEW_MOVE:
    case TypeKeys.ADD_TO_MOVE:
      return Object.assign({}, state, {
        started: false,
        done: false
      })
    default:
      return state
  }
}
