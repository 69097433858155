
import * as React from 'react'
import * as PropTypes from 'prop-types'

import { div } from 'react-dom-factories'

export class ReactPiece extends React.PureComponent<PieceProps> {
  props!: PieceProps

  constructor(props: PieceProps){
    super(props)
  }

  divProps(props: PieceProps) {
    const {
      color, level, size, pieceSelection
    } = props

    return {
      className: `${color} ${level} ${pieceSelection}`,
      style: {
        height: size,
        width: size
      }
    }
  }

  render() {
    const divProps = this.divProps(this.props)

    // The image is rendered by css background image. At least until
    // we have multiple piece sets.
    return div(divProps)
  }
}

export interface PieceProps {
  color: string
  level: string
  size: number
  pieceSelection: string
}

// ReactPiece.propTypes = {
//   color: PropTypes.string.isRequired,
//   type: PropTypes.string.isRequired,
//   size: PropTypes.number.isRequired,
//   pieceSelection: PropTypes.string.isRequired
// }

