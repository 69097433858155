
import * as React from 'react'
import { div } from 'react-dom-factories'
import { connect } from 'react-redux'
import { RootState } from '../reducers/root'
import { Progression } from '../reducers/bootReducer'
import { onNextFrame } from '../utilities'

interface StateProps {
  isUrgentDone: boolean
  children?: any
}


const LazyRenderApp = {
  mapStateToProps(state: RootState): StateProps {
    return {
      isUrgentDone: state.boot.progress == Progression.FULL_INIT
    }
  },

  mapDispatchToProps() {
    return {}
  }
}

class LazyRender extends React.PureComponent<StateProps, iLazyState> {
  props!: StateProps
  state: iLazyState
  timer: number

  constructor(props: StateProps) {
    super(props)
    this.state = {
      letsRender: false,
    }
    this.timer = 0
  }

  startRendering(){
    this.setState({
      letsRender: true
    })
  }

  componentDidUpdate() {
    if ( this.props.isUrgentDone &&
      !this.state.letsRender &&
      !this.timer)
    {
      this.timer = onNextFrame(() => this.startRendering())
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  render() {
    if (this.state.letsRender) {
      return this.props.children
    } else {
      return null
    }
  }
}

interface iLazyState {
  letsRender: boolean
}


export default connect(
  LazyRenderApp.mapStateToProps,
  LazyRenderApp.mapDispatchToProps,
)(LazyRender as any)

