import * as React from 'react'
import { div } from 'react-dom-factories'

import PlayerToMove from '../containers/player_to_move_app'
import ProblemInfo from '../containers/problem_info_app'
import AttemptHistory from '../containers/attempt_history_app'
import CommentList from '../containers/comment_list_app'
import GameInfo from '../containers/game_info_app'
import GameMoves from '../containers/game_moves_app'
import GameList from '../containers/game_list_app'
import Votes from '../containers/votes_app'
import LazyRenderApp from '../containers/lazy_render_app'

import { onNextFrame } from '../utilities'

import { getCookie } from '../../enhancements/utilities'

import * as $ from 'jquery'

const e = React.createElement;

const pieceSelection = getCookie('piece_selection').toLowerCase() || 'original'

class LeftSideBar extends React.Component<ILeftSideBarProps> {
  props!: ILeftSideBarProps

  constructor(props: ILeftSideBarProps) {
    super(props)
  }

  render() {
    // An initial state can be passed to configureStore but it has to the same
    // shape as the result of all the reducers which is tough. Probably better
    // to let each reducer grab the initial state from gon or some other source.

    return div({ className: 'left-side-bar' },
      e(LazyRenderApp, {},
        [
          e(PlayerToMove, { pieceSelection, key: 'to-move' }),
          e(ProblemInfo, {key: 'prob-info'}),
          e(GameInfo, {key: 'game-info'}),
          e(Votes, {key: 'votes'}),
          e(CommentList, {key: 'comment-list'}),
          e(AttemptHistory, {key: 'attempt-history'}),
          e(GameMoves, {key: 'game-moves', boardRef: this.props.boardRef}),
          e(GameList, {key: 'game-list'})
        ]
      )
    )
  }
}

interface ILeftSideBarProps {
  boardRef: HTMLDivElement | null
}

export default LeftSideBar
