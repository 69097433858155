
import { combineReducers } from 'redux'

import { arrows, ArrowState } from './arrowReducer'
import { board, BoardState } from './boardReducer'
import { animate, AnimationState } from './animationReducer'
import { gameInfo, GameInfoState } from './gameInfoReducer'
import { problemInfo, ProblemInfoState } from './problemInfoReducer'
import { explorer, ExplorerState} from './explorerReducer'
import { userMove, UserMoveState } from './userMoveReducer'
import { boot, BootState } from './bootReducer'

export const rootReducer = combineReducers<RootState>({
  animate,
  arrows,
  board,
  boot,
  explorer,
  gameInfo,
  problemInfo,
  userMove
})

export interface RootState {
  readonly animate: AnimationState
  readonly arrows: ArrowState
  readonly board: BoardState
  readonly boot: BootState
  readonly explorer: ExplorerState
  readonly gameInfo: GameInfoState
  readonly problemInfo: ProblemInfoState
  readonly userMove: UserMoveState
}

