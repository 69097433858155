// Handles the auto-play functions for the game and problem.

import * as React from 'react'
import * as PropTypes from 'prop-types'

import { div } from 'react-dom-factories'

import { getCookie } from '../../enhancements/utilities'

let timer = 0

const moveDelayStr = getCookie('move_delay')
let moveDelay = parseInt(moveDelayStr, 10)

if (Number.isNaN(moveDelay)) {
  moveDelay = 250
}

class GameTimer extends React.Component<IGameTimerProps, IGameTimerState> {
  props!: IGameTimerProps
  state: IGameTimerState

  constructor(props: IGameTimerProps) {
    super(props)
    this.state = {
      timerStarted: false
    }
  }

  componentDidUpdate(prevProps: IGameTimerProps, prevState: IGameTimerState) {
    if (!this.state.timerStarted && this.props.autoPlay && this.props.animationDone) {
      // console.log 'starting playback'
      this.setState({ timerStarted: true })
      timer = window.setTimeout(this.forwardAndReset.bind(this), moveDelay)
    } else if (this.state.timerStarted && !this.props.autoPlay) {
      // console.log 'stopping playback'
      this.clearandReset()
    }
  }

  shouldComponentUpdate() {
    return false
  }

  componentWillUnmount() {
    clearTimeout(timer)
  }

  forwardAndReset() {
    this.setState({ timerStarted: false })
    this.props.autoForward()
  }

  clearandReset() {
    clearTimeout(timer)
    this.setState({ timerStarted: false })
  }

  render() {
    return div({})
  }
}

interface IGameTimerProps {
  // These aren't used directly but we pass them as props so we repeatedly
  // call forward.
  animationDone: boolean
  animationStarted: boolean

  autoPlay: boolean
  autoForward: () => void
}

interface IGameTimerState {
  timerStarted: boolean
}

export default GameTimer
