
import * as React from 'react'
import * as PropTypes from 'prop-types'

/* global $ */ // Vote up and down requires the rails jquery token.

import {
  h4, div, span, button
} from 'react-dom-factories'

export class Votes extends React.Component<IVotesProps, IVotesState> {
  props!: IVotesProps
  state: IVotesState

  constructor(props: IVotesProps) {
    super(props)
    this.state = {
      indexRequest: null,
      voteRequest: null,
      votes: {
        numUp: 0,
        numDown: 0,
        userVotedUp: false,
        userVotedDown: false
      },
      error: ''
    }
  }

  refresh() {
    // console.log "refresh?"
    if ((this.props.userId > 0) &&
       (this.props.problemId > 0)) {
      this.setState({
        indexRequest: this.indexRequest(this.props.problemId)
      })
    }
  }

  componentWillUnmount() {
    if (this.state.indexRequest) {
      this.state.indexRequest.abort()
    }
  }

  componentDidMount() {
    this.refresh()
  }

  // Returns an AJAX request on which you can call abort.
  indexRequest(problemId: number) {
    // console.log "Ajax call "
    return $.ajax({
      url: `/problems/${problemId}/votes`,
      dataType: 'json',
      error: ((jqXHR, textStatus, errorThrown) => {
        // console.log('ajax error')
        this.setState({
          error: `AJAX Error: ${errorThrown}`
        })
      }),
      success: ((data) =>
        // Gets textStatus and jqXHR
        // console.log "ajax return"
        this.setState({
          votes: data
        }))
    })
  }

  // Votes on the problem, direction == 'up' or 'down'
  voteRequest(problemId: number, direction: 'up' | 'down') {
    // console.log "Ajax call "
    return $.ajax({
      url: `/problems/${problemId}/vote`,
      type: 'PUT',
      data: {
        direction
      },
      error: ((jqXHR, textStatus, errorThrown) => {
        // console.log('ajax error')
        this.setState({
          error: `AJAX Error: ${errorThrown}`
        })
      }),
      success: (() =>
        // console.log "ajax return"
        this.refresh()
      )
    })
  }

  voteUp() {
    this.voteRequest(this.props.problemId, 'up')
  }

  voteDown() {
    this.voteRequest(this.props.problemId, 'down')
  }

  userVote(direction: 'up' | 'down') {
    if ((this.state.votes.userVotedUp && (direction === 'up')) ||
       (this.state.votes.userVotedDown && (direction === 'down'))) {
      return 'voted'
    }
    return ''
  }

  render() {
    if (this.props.show && (this.props.userId > 0) && (this.props.problemId > 0)) {
      return div(
        { className: 'votes' },
        h4(
          {},
          'Great Puzzle?',
        ),
        span(
          { className: 'vote-buttons' },
          button(
            {
              className: 'btn btn-default',
              onClick: this.voteUp.bind(this)
            },
            div(
              {
                className: `glyphicon glyphicon-thumbs-up ${this.userVote('up')}`
              },
              ` ${this.state.votes.numUp} `,
            ),
          ),
          ' ',
          button(
            {
              className: 'btn btn-default',
              onClick: this.voteDown.bind(this)
            },
            div(
              {
                className: `glyphicon glyphicon-thumbs-down ${this.userVote('down')}`
              },
              ` ${this.state.votes.numDown}`,
            ),
          ),
        ),
      )
    }
    return null
  }
}

export interface IVotesProps {
  problemId: number
  userId: number
  show: boolean
}

interface VoteAjaxData {
  numUp: number
  numDown: number
  userVotedUp: boolean
  userVotedDown: boolean
}

interface IVotesState {
  indexRequest: JQuery.jqXHR<VoteAjaxData> | null
  voteRequest: JQuery.jqXHR | null
  votes: VoteAjaxData
  error: string
}

