
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'

import Solution from '../components/solution'

import * as ExplorerActions from '../actions/explorerActions'

import { RootState } from '../reducers/root'
import { ExplorerState } from '../reducers/explorerReducer'

interface StateProps {
  show: boolean
  explorer: ExplorerState
  boardRef: HTMLDivElement | null
}

interface OwnProps {
  boardRef: HTMLDivElement | null
}

interface DispatchProps {
  setCurrentMove: (move: number) => void
}

const SolutionApp = {
  mapStateToProps(state: RootState, ownProps: OwnProps): StateProps {
    const show = state.problemInfo.solved || false
    return {
      show,
      explorer: state.explorer,
      boardRef: ownProps.boardRef
    }
  },

  mapDispatchToProps(dispatch: Dispatch<RootState>): DispatchProps {
    return bindActionCreators(ExplorerActions, dispatch)
  }
}

export default connect(
  SolutionApp.mapStateToProps,
  SolutionApp.mapDispatchToProps, // FIXME: why is as any needed here?
)(Solution as any)
