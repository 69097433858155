
import { Store, createStore, applyMiddleware, compose } from 'redux'

import thunk from 'redux-thunk'
import { rootReducer, RootState } from '../reducers/root'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

declare global {
  interface Window {
    store: Store<RootState>
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: null
  }
}

export default function configureStore() {
  if (!window.store) {
    window.store = createStore<RootState>(
      rootReducer,
      composeEnhancers(
        applyMiddleware(thunk)
      ),
    )
  }
  return window.store
}
