/* eslint react/forbid-prop-types: ["error", {"forbid": ["any", "array"] }] */

import * as React from 'react'
import * as PropTypes from 'prop-types'

import { ExplorerState } from '../reducers/explorerReducer'
import { resizeToBoard } from '../utilities'
import { onNextFrame } from '../utilities'

import {
  div, ol
} from 'react-dom-factories'

import SolutionSection from './solution_section'

const e = React.createElement;

class Solution extends React.Component<ISolutionProps> {
  props!: ISolutionProps

  refs!: {
    solutionContainer: HTMLDivElement
  }

  constructor(props: ISolutionProps) {
    super(props)
  }

  componentDidMount() {
    $(window).on('resize', this.handleResizeEvent.bind(this))
    onNextFrame(() => this.handleResizeEvent())
  }

  componentWillUnmount() {
    $(window).off('resize', this.handleResizeEvent.bind(this))
  }

  handleResizeEvent() {
    resizeToBoard(this.refs.solutionContainer, this.props.boardRef)
  }

  // A function to pass to our children to resize the whole thing
  queueResize(): void {
    onNextFrame(() => this.handleResizeEvent())
  }

  componentDidUpdate(prevProps: ISolutionProps) {
    if (prevProps.show !== this.props.show) {
      // console.log("Solution resizing!")
      this.queueResize()
    }
  }

  // Takes about 10 milliseconds to update
  render() {
    let display
      const { explorer } = this.props
      const { mainLine } = explorer

      // We do this rather than render null so the states all get initialized.
      if (this.props.show) {
        display = ''
      } else {
        display = 'none'
      }

    return div(
      { className: 'solution-container', style: { display }, ref: 'solutionContainer' },
      div(
        { className: 'solution' },
        div(
          { className: 'moves' },
          ol(
            {},
            mainLine.map((plyIndex, lineIndex) =>
              e(SolutionSection, {
                key: plyIndex,
                explorer,
                plyIndex,
                setCurrentMove: this.props.setCurrentMove,
                resize: this.queueResize.bind(this),
                lineIndex,
                show: this.props.show
              })
            ),
          ),
        ),
      ),
    )
  }
}

interface ISolutionProps {
  show: boolean
  explorer: ExplorerState
  setCurrentMove: (move: number) => void
  boardRef: HTMLDivElement
}

export default Solution
