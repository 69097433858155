import { connect } from 'react-redux'

import { PlayerToMove, IPlayerToMoveProps } from '../components/player_to_move'

import { RootState } from '../reducers/root'


interface IStateProps {
  whiteToPlay: boolean
}

interface IOwnProps {
  // pieceSelection: 'Old', 'Original', 'Plastic', 'Wood', 'Metal'
  pieceSelection: string
}

interface IDispatchProps {

}


const PlayerToMoveApp = {
  mapStateToProps(state: RootState, ownProps: IOwnProps): IStateProps {
    const currentMove = state.explorer.moves[state.explorer.currentMove]
    const position = state.explorer.positions[currentMove.postPos]
    return {
      whiteToPlay: position.whiteToPlay,
    }
  },

  mapDispatchToProps() {
    return {}
  }
}

export default connect(
  PlayerToMoveApp.mapStateToProps,
  PlayerToMoveApp.mapDispatchToProps,
)(PlayerToMove as any)
