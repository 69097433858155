
import { TypeKeys } from '../types'
import { ActionTypes } from '../actions'

import { UserStates } from '../user_states'

import * as _ from 'lodash'

export interface UserMoveState {
  readonly status: UserStates
  readonly movementSquares: ReadonlyArray<number>
  readonly pieceMoveIndex: number
  readonly captureMove: boolean
  readonly justNavigated: boolean
}

const positionChangers = () =>
  [
    TypeKeys.INIT_MOVE_LIST,
    TypeKeys.INIT_FEN,
    TypeKeys.PROBLEM_START,
    TypeKeys.BACKWARD,
    TypeKeys.BEGINNING,
    TypeKeys.END
  ]

const initialState = {
  status: UserStates.NOTHING_SELECTED,
  movementSquares: [], //
  pieceMoveIndex: 0,
  captureMove: false,
  justNavigated: true
} as UserMoveState

function debugReducer(msg: string) {
  // console.log(`UserMove: ${msg}`)
}

export function userMove(state: UserMoveState, action: ActionTypes): UserMoveState {
  debugReducer(action.type)
  if (!state) {
    return initialState
  } else if (_.includes(positionChangers(), action.type)) {
    return initialState
  } else if (action.type === TypeKeys.SELECT_PIECE) {
    return Object.assign({}, state, {
      movementSquares: [action.pdn],
      pieceMoveIndex: 0,
      captureMove: false,
      status: UserStates.AWAITING_DISAMBIGUATION
    })
  } else if (action.type === TypeKeys.ADD_TO_MOVE) {
    return Object.assign({}, state, {
      movementSquares: action.squares,
      pieceMoveIndex: action.pieceMoveIndex,
      captureMove: true,
      status: UserStates.HAVE_NEW_PARTIAL_MOVE,
      justNavigated: false
    })
  } else if (action.type === TypeKeys.DESELECT_PIECE) {
    return initialState
  } else if (action.type === TypeKeys.MISSED_CAPTURE_MOVE) {
    return initialState
  } else if ((action.type === TypeKeys.SET_CURRENT_MOVE) || (action.type === TypeKeys.ADD_NEW_MOVE)) {
    return Object.assign({}, state, {
      status: UserStates.HAVE_NEW_FULL_MOVE,
      movementSquares: action.animateSquares,
      pieceMoveIndex: action.animateIndex,
      captureMove: action.animateCapture,
      justNavigated: action.asNavigation
    })
  } else if (action.type === TypeKeys.END_ANIMATION) {
    if (state.status === UserStates.HAVE_NEW_PARTIAL_MOVE) {
      return Object.assign({}, state, {
        pieceMoveIndex: state.movementSquares.length - 1,
        status: UserStates.AWAITING_DISAMBIGUATION
      })
    } else if (state.status === UserStates.HAVE_NEW_FULL_MOVE) {
      return Object.assign({}, initialState, {
        justNavigated: state.justNavigated
      })
    }
    return state
  } else if (action.type === TypeKeys.CHANGE_USER_MOVE_STATE) {
    return Object.assign({}, state, {
      status: action.newState
    })
  }
  return state
}
