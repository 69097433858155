/* eslint react/forbid-prop-types: ["error", {"forbid": ["any", "array"] }] */
/* eslint react/no-did-mount-set-state: 'off' */

import { currentPosition } from '../reducers/explorerFunctions'

import * as React from 'react'
import * as PropTypes from 'prop-types'

import { h3, div, a, table, tbody, tr, th, td } from 'react-dom-factories'
import * as $ from 'jquery'

import { onNextFrame } from '../utilities'
import { ExplorerState } from '../reducers/explorerReducer'
import { Position } from '../../engine/position'

export class GameList extends React.Component<IGameListProps, IGameListState>{
  props!: IGameListProps
  state: IGameListState

  constructor(props: IGameListProps) {
    super(props)
    this.state = {
      ajax: null,
      error: '',
      show: false,
      games: []
    }
  }

  ajaxRequest(position: Position) {
    return $.ajax({
      url: '/positions/games',
      type: 'GET',
      data: {
        board: position.toPadded(),
        white_to_play: position.whiteToPlay
      },
      error: (jqXHR, textStatus, errorThrown) => {
        console.log(`AJAX Error: $(textStatus)`)
      },
      success: data => {
        this.setState({
          show: true,
          games: data
        })
      }
    })
  }

  // Cancel the previous ajax request if it hasn't returned
  // And create the next ajax request, but don't send it. We've already
  // rendered so the element will still exist
  componentDidUpdate(prevProps: IGameListProps) {
    onNextFrame(() => {
      if(prevProps.explorer.currentMove == this.props.explorer.currentMove) {
        return
      }
      if (this.state.ajax) {
        this.state.ajax.abort()
      }
      this.setState({
        ajax: this.ajaxRequest(currentPosition(this.props.explorer))
      })
    })
  }

  componentWillUnmount() {
    if (this.state.ajax) {
      this.state.ajax.abort()
    }
  }

  componentDidMount() {
    this.setState({
      ajax: this.ajaxRequest(currentPosition(this.props.explorer)
      )
    })
  }

  render() {
    if (this.props.show) {
      return div(
        {
          key: this.props.id,
          className: "games",
          style: { overflowWrap: "break-word", whiteSpace: "pre-wrap" }
        },
        table(
          { className: "table table-condensed" },
          tbody(
            {},
            tr({}, th({ colSpan: 2, style: { textAlign: "center" } }, "Games")),
            this.state.games.map((gameJson, idx) =>
              tr(
                { key: gameJson.id },
                td(
                  { colSpan: 3, style: { textAlign: "left" } },
                  a({ href: `/games/${gameJson.id}` }, gameJson.event_name)
                ),
                td({ style: { textAlign: "right" } }, gameJson.result_num)
              )
            )
          )
        )
      )
    }

    return null
  }
}

interface IGameListProps {
  explorer: ExplorerState
  id: string
  event_name: string
  result_num: string
  show: boolean
}

interface IGameListState {
  ajax: JQuery.jqXHR | null
  error: string
  show: boolean
  games: IGameListProps[]
}
