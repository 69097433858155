
/* global gon */

import { TypeKeys, GonObject, GonGameInfo } from '../types'
import { ActionTypes } from '../actions'

declare const gon: GonObject

export interface GameInfoState {
  readonly game?: GonGameInfo
}

function initialState(): GameInfoState {
  return {
    game: gon.game
  }
}

export function gameInfo(state: GameInfoState, action: ActionTypes): GameInfoState {
  if (!state) {
    return initialState()
  }
  return state
}
