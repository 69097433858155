import * as React from 'react'
import { div, section } from 'react-dom-factories'

import * as _ from 'lodash'

import { GonObject } from '../types'
import LeftSideBar from '../components/left_side_bar'
import RightSideBar from '../components/right_side_bar'
import CenterBoard from '../components/center_board'
import { Progression } from '../reducers/bootReducer'
import { onNextFrame } from '../utilities'

let squareRefsStore: HTMLDivElement[] = [] // used to have only one place to update, the

import { getCookie } from '../../enhancements/utilities'

const e = React.createElement;

declare const gon: GonObject
let bootTimer = 0
let startTimer = 0
let checkTimer = 0

function debugComponent(msg: string) {
  // console.log(`Main: ${msg}`)
}

class Main extends React.Component<IMainProps, IMainState> {
  props!: IMainProps
  state: IMainState

  constructor(props: IMainProps) {
    super(props)
    this.state = {
      squareRefs: [],
      boardRef: null
    }

    if (gon.problem) {
      props.initProblem(gon.problem, true)
    }
    else if (gon.moves) {
      props.initMoveList(gon.moves, true)
    }
    else {
      props.initMoveList([], true)
    }
  }

  pushSquareRef(pdn: number, ref: HTMLDivElement) {
    squareRefsStore[pdn] = ref
    const newRefs = _.clone(squareRefsStore)
    this.setState({ squareRefs: newRefs })
  }

  pushBoardRef(ref: HTMLDivElement) {
    this.setState({ boardRef: ref })
  }

  // We updated so continue the boot process if necessary
  componentDidUpdate() {
    debugComponent("did update")

    if (this.props.bootProgress == Progression.PIECES_SHOWN && !bootTimer) {
      debugComponent("begin init full")
      if (gon.problem) {
        bootTimer = onNextFrame(() => this.props.initProblem(gon.problem!, false))
      }
      else if (gon.moves) {
        bootTimer = onNextFrame(() => this.props.initMoveList(gon.moves!, false))
      }
      else {
        bootTimer = onNextFrame(() => this.props.initMoveList([], false))
      }
    }
    else if (this.props.bootProgress == Progression.FULL_INIT &&
             gon.problem && !this.props.problemStartTime &&
             !startTimer) {
      debugComponent("problem start")

      // We've already waited initDelay while it initializes
      const initDelay = Date.now() - this.props.shownTime
      const startDelay = _.max([1000 - initDelay, 0])
      debugComponent(`Init took ${initDelay}, further: ${startDelay}`)
      startTimer = onNextFrame(() => window.setTimeout(this.props.startProblem, startDelay))
    }

    if (gon.problem) {
      if (this.props.problemStartTime && this.props.animationDone && !checkTimer) {
        checkTimer = onNextFrame(() => {
          debugComponent("check player move")
          this.props.checkPlayerMove()
          checkTimer = 0
        })
      }
    }
  }

  render() {
    var leftSize = 0
    var centerSize = 0
    const size = getCookie('board_size').toLowerCase() || 'Normal'

    if(size === 'Large'){
      leftSize = 2
      centerSize = 7
    }else{
      leftSize = 3
      centerSize = 6
    }

    return div({},
      section({
        className: `col-md-${centerSize} col-md-push-${leftSize} col-sm-8
        col-xs-12 loader`},
        e(CenterBoard, {
          pushSquareRef: this.pushSquareRef.bind(this),
          pushBoardRef: this.pushBoardRef.bind(this),
          squareRefs: this.state.squareRefs,
          boardRef: this.state.boardRef
        })),
      section({
        className: `col-md-3 col-md-push-${leftSize} col-sm-4 col-sm-push-0
        col-xs-12 col-xs-push-0`},
        e(RightSideBar, {boardRef: this.state.boardRef})
      ),
      section({
        className: `col-md-${leftSize} col-md-pull-${centerSize+3}
          col-sm-8 col-sm-pull-0 col-xs-12 col-xs-pull-0`},
        e(LeftSideBar, {boardRef: this.state.boardRef})
      )
    )
  }
}

interface IMainProps {
  // These are typed any because they actually return function that return
  // action creators.
  initProblem: (gonProblem: string, onlyQuick: boolean) => void
  initMoveList: (moveList: string[], onlyQuick: boolean) => void
  startProblem: () => void
  checkPlayerMove: () => void

  // These aren't used directly but we pass them as props so we repeatedly
  // call checkPlayerMove.
  currentMove: number
  animationDone: boolean
  bootProgress: Progression
  shownTime: number
  problemStartTime: string
}

interface IMainState {
  squareRefs: HTMLDivElement[]
  boardRef: HTMLDivElement | null
}

export default Main
