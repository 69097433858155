/* eslint no-console: ["error", { allow: ["log", "warn", "error"] }] */
/* eslint no-constant-condition: "off" */

import * as _ from 'lodash'

import { TypeKeys } from '../types'
import { ActionTypes } from '../actions'

import { Position } from '../../engine/position'
import { Move } from '../../engine/move'

import {
  initFromProblem, initFromMoveList, initFromFen,
  createInitialGonPosition, createInitialNullMove
} from './explorerInitializers'

import {
  currentMove, prevMoveIndex
} from './explorerFunctions'

export interface ExplorerMove {
  readonly id: number
  readonly parent: number | null
  readonly description: string
  readonly prePos: number | null
  readonly postPos: number
  readonly value: number
  readonly isBestPlayerMove: boolean
  readonly isAltPlayerMove: boolean
  readonly isComputerMove: boolean
  readonly isMainLine: boolean
  readonly isPlayerWin: boolean
  readonly moveInfo: Move | null
}

export interface ExplorerState {
  readonly positions: ReadonlyArray<Position>
  readonly moves: ReadonlyArray<ExplorerMove>

  readonly mainLine: ReadonlyArray<number>
  readonly autoPlay: boolean
  readonly currentMove: number
  readonly goingForward: boolean
}
// An initial, blank, exploration tree. Used during the user's attempt
function initialState(): ExplorerState {
  const positions: Position[] = []
  const moves: ExplorerMove[] = []
  const mainLine = [0]

  createInitialGonPosition(positions)
  createInitialNullMove(moves)

  return {
    positions,
    moves,
    mainLine,
    autoPlay: false,

    currentMove: 0,
    goingForward: true
  }
}

function debugReducer(msg: string) {
  // console.log(`Explorer: ${msg}`)
}

// Actual Reducer, calls above functions. Takes a state and an action, returns
// a new state.
export function explorer(state: ExplorerState, action: ActionTypes): ExplorerState {
  let autoPlay
  let nextMove

  debugReducer(action.type)

  if (!state) {
    return initialState()
  } else if (action.type === TypeKeys.ADD_NEW_MOVE) {
    let nextPositions
    let nextPositionIndex = action.nextPosition.index
    if (nextPositionIndex < 0) {
      nextPositionIndex = state.positions.length
      nextPositions = state.positions.concat(action.nextPosition)
    } else {
      nextPositions = state.positions
    }

    nextMove = {
      id: state.moves.length,
      parent: state.currentMove,
      description: action.move.toString(),
      prePos: currentMove(state).postPos,
      postPos: nextPositionIndex,
      moveInfo: action.move,

      value: 0,
      isBestPlayerMove: false,
      isAltPlayerMove: false,
      isComputerMove: false,
      isMainLine: false,
      isPlayerWin: false
    }

    debugReducer('returning) new move.')
    return Object.assign({}, state, {
      positions: nextPositions,
      moves: state.moves.concat(nextMove),
      currentMove: nextMove.id,
      goingForward: true
    })
  } else if (action.type === TypeKeys.INIT_MOVE_LIST) {
    return initFromMoveList(action.moves, action.onlyQuick)
  } else if (action.type === TypeKeys.INIT_PROBLEM) {
    return initFromProblem(action.problem, action.onlyQuick)
  } else if (action.type === TypeKeys.INIT_FEN) {
    return initFromFen(action.fen)
  } else if (action.type === TypeKeys.SET_CURRENT_MOVE) {
    return Object.assign({}, state, {
      currentMove: action.moveId,
      goingForward: true,
      autoPlay: action.autoPlay
    })

  // Not currently used, set_current_move called by exporerActions#forward
  } else if (action.type === TypeKeys.BACKWARD) {
    nextMove = prevMoveIndex(state)
    return Object.assign({}, state, {
      currentMove: nextMove,
      goingForward: false,
      autoPlay: false
    })
  } else if (action.type === TypeKeys.ADD_TO_MOVE) {
    return Object.assign({}, state, {
      goingForward: true
    })
  } else if (action.type === TypeKeys.BEGINNING) {
    return Object.assign({}, state, {
      currentMove: 0,
      goingForward: true,
      autoPlay: false
    })
  } else if (action.type === TypeKeys.END) {
    const lastMove = _.last(state.mainLine)
    return Object.assign({}, state, {
      currentMove: lastMove,
      goingForward: true,
      autoPlay: false
    })
  } else if (action.type === TypeKeys.TOGGLE_AUTO_PLAY) {
    return Object.assign({}, state, {
      goingForward: true,
      autoPlay: !state.autoPlay
    })
  }
  return state
}

