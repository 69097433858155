
import * as React from 'react'
import * as PropTypes from 'prop-types'
import * as $ from 'jquery'

import { div, a } from 'react-dom-factories'

class Navigation extends React.Component<INavigationProps> {
  props!: INavigationProps

  constructor(props: INavigationProps) {
    super(props)
  }

  handleKeyEvent(keyEvent: KeyboardEvent) {
    const numFocus = $('textarea:focus').length + $('input:focus').length

    if (this.props.allowNavigation && (numFocus === 0)) {
      switch (keyEvent.key) {
        case 'ArrowLeft':
          this.props.backward()
          break
        case 'ArrowUp':
          this.props.beginning()
          break
        case 'ArrowRight':
          this.props.forward()
          break
        case 'ArrowDown':
          this.props.end()
          break
        case '\\':
          this.props.flipBoard()
          break
        case ' ':
          this.props.toggleAutoPlay()
          break
        default: // Anythine else
          return // We exit this handler so other key handlers might catch it.
      }
      // But if we did handle it we prevent it from propagating
      keyEvent.preventDefault()
    }
  }

  componentDidMount() {
    $(window).on('keydown', this.handleKeyEvent.bind(this))
  }

  componentWillUnmount() {
    $(window).off('keydown', this.handleKeyEvent.bind(this))
  }

  render() {
    let display = ''
    if (!this.props.allowNavigation) {
      display = 'none'
    }
    return div(
      { className: 'btn-group', style: { display } },
      a(
        { className: 'btn btn-default', onClick: this.props.beginning.bind(this) },
        'Top ↑',
      ),
      a(
        { className: 'btn btn-default', onClick: this.props.backward.bind(this) },
        'Backward ←',
      ),
      a(
        { className: 'btn btn-default', onClick: this.props.forward.bind(this) },
        'Forward →',
      ),
      a(
        { className: 'btn btn-default', onClick: this.props.end.bind(this) },
        'Bottom ↓',
      ),
      a(
        { className: 'btn btn-default', onClick: this.props.flipBoard.bind(this) },
        'Flip',
      ),
      this.props.allowNextProblem ?
        a(
          {
            className: 'btn btn-default',
            href: '/problems/start_next',
            rel: 'nofollow'
          },
          'Next',
        )
        :
        a(
          { className: 'btn btn-default', onClick: this.props.toggleAutoPlay.bind(this) },
          this.props.autoPlay ? 'Pause' : 'Play',
        ),
    )
  }
}

interface INavigationProps {
  forward: () => void
  backward: () => void
  beginning: () => void
  end: () => void
  flipBoard: () => void
  toggleAutoPlay: () => void

  allowNavigation: boolean
  allowNextProblem: boolean
  autoPlay: boolean
}

export default Navigation
