
import * as React from 'react'
import * as PropTypes from 'prop-types'

import {
  h3, div, a
} from 'react-dom-factories'

import * as $ from 'jquery'
import * as _ from 'lodash'

import NewCommentForm from './new_comment_form'
import Comment from './comment'

const e = React.createElement;

export class CommentList extends React.Component<ICommentListProps, ICommentListState>{
  props!: ICommentListProps
  state: ICommentListState

  constructor(props: ICommentListProps) {
    super(props)
    this.state = {
      comments: [],
      ajax: null,
      error: ''
    }
  }

  // Returns an AJAX request on which you can call abort.
  ajaxRequest(problemId: number) {
    // console.log "Ajax call "
    return $.ajax({
      url: `/problems/${problemId}/comments`,
      dataType: 'json',
      error: ((jqXHR, textStatus, errorThrown) => {
        // console.log('ajax error')
        this.setState({
          error: `AJAX Error: ${errorThrown}`
        })
      }),
      success: ((data) =>
        // console.log "ajax return"
        this.setState({
          comments: data
        }))
    })
  }

  refresh() {
    if (this.props.signedIn &&
       (this.props.userId > 0) &&
       (this.props.problemId > 0)) {
      this.setState({
        ajax: this.ajaxRequest(this.props.problemId)
      })
    }
  }

  componentWillUnmount() {
    if (this.state.ajax) {
      this.state.ajax.abort()
    }
  }

  componentDidMount() {
    this.refresh()
  }

  renderComments() {
    return div(
      { },
      h3(
        {},
        this.state.comments.length > 0 ?
          `${this.state.comments.length} comments.`
          :
          'No comments yet.',
      ),
      div(
        {},
        this.state.comments.map((commentJson, idx) =>
          e(Comment, {
            id: commentJson.id,
            userName: commentJson.user_name,
            commentUserId: commentJson.user_id,
            currentUserId: this.props.userId,
            date: commentJson.date,
            body: commentJson.body,
            refresh: this.refresh.bind(this),
            key: commentJson.id,
            last: idx === (this.state.comments.length - 1)
          })
        )
      ),
    )
  }

  renderNewCommentForm() {
    return e(NewCommentForm, {
      problemId: this.props.problemId,
      refresh: this.refresh.bind(this)
    })
  }

  renderError() {
    return div(
      { className: 'alert alert-danger' },
      this.state.error,
    )
  }

  renderSignUp() {
    return div(
      { className: 'alert alert-warning' },
      a(
        { href: '/users/sign_up', className: 'alert-link' },
        'Click here to sign up',
      ),
      ' and see and share comments. It\'s free!',
    )
  }

  renderReSignUp() {
    return div(
      { className: 'alert alert-warning' },
      a(
        { href: '/users/gonative_logout', className: 'alert-link' },
        'Sign out',
      ),
      ' and back in to activate problem comments.',
    )
  }

  render() {
    if (this.props.show) {
      if (!this.props.signedIn) {
        return this.renderSignUp()
      } else if (!(this.props.userId > 0)) {
        return this.renderReSignUp()
      } else if (!_.isEmpty(this.state.error)) {
        return this.renderError()
      }
      return div(
        { className: 'comments' },
        div(
          {},
          this.renderComments(),
        ),
        div(
          {},
          this.renderNewCommentForm(),
        ),
      )
    }
    return null
  } // rendering null because props.show is false
}

export interface ICommentListProps {
  problemId: number
  show: boolean
  signedIn: boolean
  userId: number
}

interface ICommentAjaxJson {
  id: number
  user_name: string
  user_id: number
  date: string
  body: string
}

interface ICommentListState {
  comments: ICommentAjaxJson[]
  ajax: JQuery.jqXHR<ICommentAjaxJson[]> | null
  error: string
}
