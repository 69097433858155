
import { connect } from 'react-redux'

import AttemptHistory from '../components/attempt_history'

import { RootState } from '../reducers/root'

const AttemptHistoryApp = {
  mapStateToProps(state: RootState) {
    let id
    let show
    if (state.problemInfo.problemId != null) {
      id = state.problemInfo.problemId
      show = state.problemInfo.solved
    } else {
      id = -1
      show = false
    }

    return {
      problemId: id,
      show
    }
  },

  mapDispatchToProps() {
    return {}
  }
}

export default connect(
  AttemptHistoryApp.mapStateToProps,
  AttemptHistoryApp.mapDispatchToProps,
)(AttemptHistory as any)
