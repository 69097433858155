
export class Direction {
  offset:number

  constructor(offset: number) {
    this.offset = offset
  }
}

export let directions = [
  new Direction(-5),
  new Direction(-4),
  new Direction(4),
  new Direction(5)
]

export function getDirection(offset: number): Direction {
  switch(offset) {
    case -5:
      return directions[0]
    case -4:
      return directions[1]
    case 4:
      return directions[2]
    case 5:
      return directions[3]
    default:
      throw new Error("The point of direction is it's either 4 or 5.")
  }
}
