
/* global gon */
import * as _ from 'lodash'

import { getCookie } from '../../enhancements/utilities'

import { TypeKeys, GonObject } from '../types'
import { ActionTypes } from '../actions'

declare const gon: GonObject

export interface BoardState {
  readonly whiteOnBottom: boolean
}

function initialState(): BoardState {
  return {
    whiteOnBottom: isWhiteOnBottom()
  }
}

function isWhiteOnBottom() {
  const playFromTop = getCookie('play_from') === 'top'
  let whiteToPlay = isWhiteToPlay()

  // Should use the context move's existence
  if (gon.problem){

    // TODO: Remove 3rd decoding and put context move description in an action
    const decoded = _.map(gon.problem, ((char) => char.charCodeAt(0) ^ 67))
    const json = JSON.parse(String.fromCharCode.apply(null, decoded))

    // Don't choose board orientation based on the context move.
    if(json.context_move_description) {
      whiteToPlay = !whiteToPlay
    }
  }

  return (whiteToPlay && !playFromTop) || (!whiteToPlay && playFromTop)
}

function isWhiteToPlay(): boolean {
  return gon.position ? gon.position.white_to_play : true
}


export function board(state: BoardState, action: ActionTypes): BoardState {
  if (!state) {
    return initialState()
  }

  const { type } = action

  switch (type) {
    case TypeKeys.FLIP_BOARD:
      return Object.assign({}, state, {
        whiteOnBottom: !state.whiteOnBottom
      })
    default:
      return state
  }
}
