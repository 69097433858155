
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'

import { GameMoves, IGameMovesProps } from '../components/game_moves'
import * as ExplorerActions from '../actions/explorerActions'
import { RootState } from '../reducers/root'
import { ExplorerState } from '../reducers/explorerReducer'
import { GonGameInfo } from '../types'

interface IStateProps {
  explorer: ExplorerState
  game?: GonGameInfo
  show: boolean
  boardRef: HTMLDivElement | null
}

interface IDispatchProps {
  setCurrentMove: (move: number) => void
}

interface IOwnProps {
  boardRef: HTMLDivElement | null
}

const GameMovesApp = {
  mapStateToProps(state: RootState, ownProps: IOwnProps): IStateProps {
    const show = (state.problemInfo.problemId == null) && (state.gameInfo.game != null)

    return {
      explorer: state.explorer,
      game: state.gameInfo.game,
      show,
      boardRef: ownProps.boardRef
    }
  },

  mapDispatchToProps(dispatch: Dispatch<RootState>): IDispatchProps {
    return bindActionCreators(ExplorerActions, dispatch)
  }
}

export default connect(
  GameMovesApp.mapStateToProps,
  GameMovesApp.mapDispatchToProps,
)(GameMoves as any)
