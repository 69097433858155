/* eslint react/forbid-prop-types: ["error", {"forbid": ["any", "array"] }] */

import * as React from 'react'
import * as PropTypes from 'prop-types'
import * as _ from 'lodash'

import { ExplorerState } from '../reducers/explorerReducer'

import { div, ol, li, button } from 'react-dom-factories'

import { moveSiblingIndices, moveDescendentIndicies } from '../reducers/explorerFunctions'

import { SolutionAnchor, ISolutionAnchorProps } from './solution_anchor'
import SolutionLine from './solution_line'

const e = React.createElement;

class SolutionSection extends React.Component<ISolutionSectionProps, ISolutionSectionState> {
  props!: ISolutionSectionProps
  state: ISolutionSectionState

  shouldComponentUpdate(nextProps: ISolutionSectionProps, nextState: ISolutionSectionState) {
    if ((this.state.ourIds.length !== nextState.ourIds.length) ||
       (this.state.collapse !== nextState.collapse) ||
       (this.props.show !== nextProps.show)) {
      return true
    }

    const currentlyActive = _.includes(this.state.ourIds, this.props.explorer.currentMove)
    if (currentlyActive) {
      return true
    }
    // TODO: When we update the state of ids, this will need to use
    // the next props to calculate ids but this is fast. And rendering
    // the solution is slow, so watch the animation speed.
    const nextActive = _.includes(this.state.ourIds, nextProps.explorer.currentMove)
    if (nextActive) {
      return true
    }
    return false
  }

  constructor(props: ISolutionSectionProps) {
    super(props)
    const ourIds = this.getReleventIds(props)
    this.state = {
      ourSelves: props.plyIndex,
      ourIds,
      siblings: this.getSiblings(props),
      collapse: true
    }
  }

  toggleCollapse() {
    this.setState({
      collapse: !this.state.collapse
    })
    this.props.resize()
  }

  getReleventIds(props: ISolutionSectionProps): number[] {
    const ourSelves = props.plyIndex
    const siblings = moveSiblingIndices(props.explorer, ourSelves)
    const nephews = _.flatten(siblings.map(((sibling) =>
      moveDescendentIndicies(props.explorer, sibling)), this))

    return _.concat(nephews, siblings, ourSelves)
  }

  getSiblings(props: ISolutionSectionProps) {
    const ourSelves = props.plyIndex
    return moveSiblingIndices(props.explorer, ourSelves)
  }

  renderLines(lineId: string) {
    const { explorer } = this.props

    if (!this.state.collapse ||
       (_.includes(this.state.ourIds, explorer.currentMove) &&
        (this.state.ourSelves !== explorer.currentMove))) {
      return div(
        {
          className: 'moves lines'
        },
        ol(
          {},
          this.state.siblings.map((sibling) =>
            e(SolutionLine, {
              key: sibling,
              explorer,
              plyIndex: sibling,
              lineId,
              setCurrentMove: this.props.setCurrentMove,
              resize: this.props.resize,
              show: this.props.show
            })
          ),
        ),
      )
    }
    return null
  }

  // We have to render the main line move as well as well as the the
  // alternate lines for all the move's siblings and their respective children
  render() {
    let lineId
    const { explorer } = this.props
    const { plyIndex } = this.props
    const move = explorer.moves[plyIndex]

    if (move.prePos != null) {
      // If red to play, use next int, white to play, use old one with ...
      if (explorer.positions[move.prePos].whiteToPlay) {
        const moveIndex = Math.floor((this.props.lineIndex + 1) / 2)
        lineId = `${moveIndex}...`
      }
      else {
        const moveIndex = Math.ceil((this.props.lineIndex + 1) / 2)
        lineId = `${moveIndex}.`
      }
      // console.log(`whitePlayed: ${whitePlayed}, line#: ${this.props.lineIndex}, got ${lineId}`)
    } else { // Checkers initial position has black to play
      lineId = '1.'
    }

    return li(
      { key: plyIndex },
      div(
        {},
        lineId,
        ' ',
        this.buildMainLine(explorer, plyIndex),
        (() => {
          if (this.state.siblings.length >= 1) {
            const btnText = move.isComputerMove ? 'Defenses' : 'Other tries'
            return button(
              {
                onClick: this.toggleCollapse.bind(this),
                className: 'btn btn-default btn-sm'
              },
              btnText,
            )
          }
          return null
        })(),
        this.renderLines(lineId),
      ),
    )
  }

  // Called by buildMainLineSection, renders the move.
  buildMainLine(explorer: ExplorerState, plyIndex: number) {
    return e(SolutionAnchor, {
      move: explorer.moves[plyIndex],
      currentMove: explorer.currentMove,
      setCurrentMove: this.props.setCurrentMove,
      show: this.props.show
    })
  }
}

interface ISolutionSectionState {
  collapse: boolean
  ourIds: number[]
  ourSelves: number
  siblings: number[]
}

interface ISolutionSectionProps {
  explorer: ExplorerState
  // The moves index in the big array of moves
  plyIndex: number
  show: boolean
  // The moves index in the lineplayed, like black's move 4
  lineIndex: number
  setCurrentMove: (move: number) => void

  resize: () => void
}

export default SolutionSection
