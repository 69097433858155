
// Used above droppable square in the hierarchy to handle droppable pieces
// This way we can pass mustMoveSquares to is_droppable.
import { connect } from 'react-redux'
import * as _ from 'lodash'

import { RootState } from '../reducers/root'
import { currentMove } from '../reducers/explorerFunctions'
import { UserStates } from '../user_states'
import Square from './droppable_square'

interface SquareAppStateProps {
  lastMoveSquares: number[]
  mustMoveSquares: number[]
  selectedSquare: number
}

interface SquareAppOwnProps {
  // Needed by by the square component
  pdn: number | null
  row: number
  col: number
  pushSquareRef: (pdn: number, ref: HTMLDivElement) => void
  // Needed just so we can update correctly
  whiteOnBottom: boolean

  boardSelection: string
  showNumberedNotation: boolean
}

interface SquareAppDispatchProps {

}

const SquareApp = {
  mapStateToProps(state: RootState, ownProps: SquareAppOwnProps): SquareAppStateProps {
    let lastMoveSquares: number[]
    let mustMoveSquares: number[]
    let selectedPDN
    const { moveInfo } = currentMove(state.explorer)
    if (moveInfo && moveInfo.startSquare && moveInfo.landedSquares.length > 0) {
      lastMoveSquares = [moveInfo.startSquare.pdn(), (_.last(moveInfo.landedSquares)!.pdn())]
    } else {
      lastMoveSquares = []
    }

    const { userMove } = state

    if (userMove.status === UserStates.AWAITING_DISAMBIGUATION) {
      selectedPDN = userMove.movementSquares[userMove.pieceMoveIndex]
      mustMoveSquares = []
    // DEAD because WRONG_TEAM_SELECTED is never stored as a state.
    // It's an action that affects the arrows reducer.
    // else if userMove.status == UserStates.WRONG_TEAM_SELECTED
    //   selectedPDN = 0
    //   mustMoveSquares = friendlyPiecePdns()
    } else {
      selectedPDN = 0
      mustMoveSquares = []
    }

    return {
      // How does must move square get handled? Does the board need to know
      // where the opponents pieces are? What about piece location for
      // bouncing pieces?
      mustMoveSquares,
      lastMoveSquares,
      selectedSquare: selectedPDN
    }
  },
  mapDispatchToProps(): SquareAppDispatchProps {
    return {}
  }
}

export default connect(
  SquareApp.mapStateToProps,
  SquareApp.mapDispatchToProps,
)(Square)
