
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'

import * as BoardActions from '../actions/boardActions'
import Pieces from '../components/pieces'
import { RootState } from '../reducers/root'

import { Move } from '../../engine/move'
import { Position } from '../../engine/position'
import { UserMoveState } from '../reducers/userMoveReducer'
import { AnimationState } from '../reducers/animationReducer'
import { Progression } from '../reducers/bootReducer'

interface StateProps {
  moveInfo: Move | null
  prePosition: Position | null
  postPosition: Position
  userMove: UserMoveState
  animate: AnimationState
  bootProgress: Progression
}

interface DispatchProps {
  storeBeginAnimation: () => void
  storeEndAnimation: () => void
  doneRenderPieces: () => void
}

const PiecesApp = {
  mapStateToProps(state: RootState): StateProps {
    const currentMove = state.explorer.moves[state.explorer.currentMove]

    let prePosition
    if (currentMove.prePos != null) {
      prePosition = state.explorer.positions[currentMove.prePos]
    }
    else {
      prePosition = null
    }

    // console.log(`pieces app giving moveInfo: ${currentMove.moveInfo && currentMove.moveInfo.description}`)
    return {
      moveInfo: currentMove.moveInfo,
      prePosition: prePosition,
      postPosition: state.explorer.positions[currentMove.postPos],
      userMove: state.userMove,
      animate: state.animate,
      bootProgress: state.boot.progress
    }
  },

  mapDispatchToProps(dispatch: Dispatch<RootState>): DispatchProps {
    // bindActionCreators is equivalent to
    // return {
    // startProblem: (moveJSON, nextPosition) ->
    //   dispatch(ProblemActions.startProblem(moveJSON, nextPosition))
    //
    // BoardActions include store animation state.
    return bindActionCreators(BoardActions, dispatch)
  }
}

export default connect(
  PiecesApp.mapStateToProps,
  PiecesApp.mapDispatchToProps,
)(Pieces)
