/* eslint react/forbid-prop-types: ["error", {"forbid": ["any", "array"] }] */

import * as React from 'react'
import * as PropTypes from 'prop-types'
import { GonGameInfo } from '../types'

import {
  div, table, tbody, tr, td, th, a, h4
} from 'react-dom-factories'

class GameInfo extends React.PureComponent<IGameInfoProps> {
  props!: IGameInfoProps

  constructor(props: IGameInfoProps){
    super(props)
  }

  render() {
    const { game } = this.props
    if (game) {
      let result = '\xbd - \xbd'
      if (game.black_win) {
        result = '1 - 0'
      } else if (game.white_win) {
        result = '0 - 1'
      }

      const display = this.props.show ? '' : 'none'

      return div(
        { className: 'game-info', style: { display } },
        div(
          { style: { 'textAlign': 'center' } },
          this.props.onPuzzle ? 'Source Game' : ''
          ),
        table(
          {},
          tbody(
            {},
            tr(
              {},
              th(
                {},
                'Event:',
              ),
              td(
                {},
                a(
                  { href: `/games/${this.props.game.id}` },
                  game.event_name,
                ),
              ),
            ),
            tr(
              {},
              th(
                {},
                'Date:',
              ),
              td(
                {},
                game.date_string,
              ),
            ),
            tr(
              {},
              th(
                {},
                'Black:',
              ),
              td(
                {},
                game.black_player_name,
              ),
            ),
            tr(
              {},
              th(
                {},
                'White:',
              ),
              td(
                {},
                game.white_player_name,
              ),
            ),
            tr(
              {},
              th(
                {},
                'Result:',
              ),
              td(
                {},
                result,
              ),
            ),
          ),
        ),
      )
    }
    return null
  }

}

interface IGameInfoProps {
  game: GonGameInfo
  show: boolean
  onPuzzle: boolean
}

export default GameInfo
