import { TypeKeys } from '../types'
import { ActionTypes } from '../actions'

export enum Progression {
  NotStarted = "NOT_STARTED",
  QUICK_INIT = "QUICK_INIT",
  BOARD_SHOWN = "BOARD_SHOWN",
  PIECES_SHOWN = "PIECES_SHOWN",
  FULL_INIT = "FULL_INIT",
}

export interface BootState {
  readonly progress: Progression
  readonly shownTime: number
}

// True, true is resting done. False, false is pending. True, false is
// running. False true is illigal.
function initialState(): BootState {
  return {
    progress: Progression.NotStarted,
    shownTime: 0
  }
}

export function boot(state: BootState, action: ActionTypes): BootState {
  if (!state) {
    return initialState()
  }

  switch (action.type) {
    case TypeKeys.INIT_PROBLEM:
    case TypeKeys.INIT_MOVE_LIST: {
      if (action.onlyQuick) {
        return Object.assign({}, state, {
          progress: Progression.QUICK_INIT
        })
      } else {
        return Object.assign({}, state, {
          progress: Progression.FULL_INIT
        })
      }
    }
    case TypeKeys.DONE_RENDER_BOARD:
      return Object.assign({}, state, {
        progress: Progression.BOARD_SHOWN
      })
    case TypeKeys.DONE_RENDER_PIECES:
      return Object.assign({}, state, {
        progress: Progression.PIECES_SHOWN,
        shownTime: Date.now()
      })
    default:
      return state
  }
}
