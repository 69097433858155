
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import * as _ from 'lodash'

import * as ExplorerActions from '../actions/explorerActions'
import PositionFen from '../components/position_fen'
import { RootState } from '../reducers/root'

interface IDispatchProps {
  initFen: (fen: string) => void
}

const PositionFenApp = {
  mapStateToProps(state: RootState) {
    const showInput = !_.isEmpty(window.location.href.match(/explore/))
    const showOutput = showInput || (state.problemInfo.solved ? state.problemInfo.solved : false)
    // Make sure showOutput is not undefined

    return {
      explorer: state.explorer,
      showInput,
      showOutput
    }
  },

  mapDispatchToProps(dispatch: Dispatch<RootState>): IDispatchProps {
    return bindActionCreators(ExplorerActions, dispatch)
  }
}

export default connect(
  PositionFenApp.mapStateToProps,
  PositionFenApp.mapDispatchToProps,
)(PositionFen as any)
