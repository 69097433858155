/* eslint react/forbid-prop-types: ["error", {"forbid": ["any", "array"] }] */

import * as React from 'react'
import * as ReactDnd from 'react-dnd'
import * as PropTypes from 'prop-types'

import { div } from 'react-dom-factories'

import { DragLayer } from 'react-dnd'

import { ReactPiece as Piece, PieceProps } from '../components/piece'

const e = React.createElement;

const layerStyles = {
  position: 'fixed' as 'fixed', // Why is the cast necessary?
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%'
}

function getItemStyles(props: IDragLayerProps) {
  const { currentOffset } = props
  if (!currentOffset) {
    return { display: 'none' }
  }
  const { x } = currentOffset
  const { y } = currentOffset
  const transform = `translate(${x}px, ${y}px)`
  return {
    transform,
    WebkitTransform: transform
  }
}

function getDraggedItemProps(props: PieceProps) {
  const draggedProps = {
    color: props.color,
    level: props.level,
    size: props.size + 7,
    pieceSelection: props.pieceSelection
    // We don't need the pdn
  }
  return draggedProps
}

// Is given a monitor by react-dnd. Injects information into the props
// into the CustomDragLayer including the item (returned from beginDrag)
// being dragged
const dragCollect = (monitor: ReactDnd.DragLayerMonitor) =>
  ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging()
  })

class CustomDragLayer extends React.PureComponent {
  props!: IDragLayerProps

  render() {
    const { item } = this.props
      // const { itemType } = this.props
      const { isDragging } = this.props

      if (isDragging) {
        const itemProps = getDraggedItemProps(item as PieceProps)
          return div(
              { style: layerStyles },
              div(
                { style: getItemStyles(this.props) },
                e(Piece, itemProps),
                ),
              )
      }
    return div({ style: layerStyles })
  }
}

interface IDragLayerProps {
  item: object
  itemType: string
  currentOffset: {
    x: number
    y: number
  }
  isDragging: boolean
}

export default DragLayer(dragCollect)(CustomDragLayer)

