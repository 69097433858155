import { Square } from './square'
import { Direction } from './direction'

function createSquares() {
  const squares: Square[] = []
  // 44 square array makes some math for square relationships easy.
  for (let i = 0; i <= 44; i += 1) {
    squares.push(new Square(i))
  }

  const borders = [0, 1, 2, 3, 4, 13, 22, 31, 40, 41, 42, 43, 44]
  borders.forEach((i) => {
    squares[i].isBorder = true
  })

  const promoteSquares = [5, 6, 7, 8, 36, 37, 38, 39]
  promoteSquares.forEach((i) => {
    if (i < 15) { // low squares are on black's side so white promotes.
      squares[i].whoPromotes = 'white'
    } else { // high squares are on white's side so black promotes.
      squares[i].whoPromotes = 'black'
    }
  })
  return squares
}

class Board {
  squares:Square[]

  constructor() {
    this.squares = createSquares()
  }

  adjacentSquare(square: Square, direction: Direction): Square {
    const index = square.squareNum + direction.offset
    console.assert(
      (index >= 0) && (index < this.squares.length),
      `index: ${index} out of bounds.`,
    )
    return this.squares[square.squareNum + direction.offset]
  }

  jumpSquare(square: Square, direction: Direction): Square | null {
    const adjacent = this.adjacentSquare(square, direction)
    if (adjacent.isBorder) {
      return null
    }
    return this.adjacentSquare(adjacent, direction)
  }

  squareByPdn(pdn: number): Square {
    let numPadding = 4 // there are actually 5 initial borders but pdn is 1 offset.
    if (pdn > 8) { numPadding += 1 }
    if (pdn > 16) { numPadding += 1 }
    if (pdn > 24) { numPadding += 1 } // skip borders as they appear

    const square = this.squares[pdn + numPadding]
    console.assert(square.pdn() == pdn)
    return square
  }
}

// An 8x8 board represented by 45 squares some of which are padding.
// There is only one board so that moves and pieces all refer to the same
// squares. Squares don't have a single occupying piece but can query a position.
export let theBoard = new Board
