/* eslint react/forbid-prop-types: ["error", {"forbid": ["any", "array"] }] */
/* global gon */

import * as React from 'react'
import * as PropTypes from 'prop-types'
import * as _ from 'lodash'

import { TypeKeys } from '.././types'

import { div, a } from 'react-dom-factories'

import { getCookie } from '../../enhancements/utilities'

const colors = getCookie('feedback_colors') || 'green-red'

import { GonObject } from '../types'
import { ProblemInfoState, SubmitStatus } from '../reducers/problemInfoReducer'

declare const gon: GonObject

class ProblemFeedback extends React.Component<IProblemFeedbackProps> {
  props!: IProblemFeedbackProps

  constructor(props: IProblemFeedbackProps){
    super(props)
  }

  shouldComponentUpdate(nextProps: IProblemFeedbackProps) {
    return !_.isEqual(this.props.problemInfo, nextProps.problemInfo)
  }

  getMessage() {
    let colorClass
    const { problemInfo } = this.props
    if (problemInfo.attempt && (problemInfo.submitStatus === SubmitStatus.STARTED)) {
      colorClass = colors === 'green-red' ? 'alert-info' : 'alert-success'
      return {
        message: 'Submitting Results...',
        className: `alert ${colorClass}`
      }
    }
    if (problemInfo.playerResults === TypeKeys.PLAYER_ALT) {
      colorClass = colors === 'green-red' ? 'alert-warning' : 'alert-success'
      return {
        message: `${problemInfo.mistake} is a good move but the computer \
claims there is better.`,
        className: `alert ${colorClass}`
      }
    } else if (problemInfo.playerResults === TypeKeys.PLAYER_LOSE) {
      colorClass = colors === 'green-red' ? 'alert-danger' : 'alert-warning'
      return {
        message: `Ouch, ${problemInfo.mistake} likely misses the win.`,
        className: `alert ${colorClass}`
      }
    } else if (problemInfo.playerResults === TypeKeys.PLAYER_WIN) {
      colorClass = colors === 'green-red' ? 'alert-success' : 'alert-info'
      return {
        message: 'Congrats! It should be a win from here.',
        className: `alert ${colorClass}`
      }
    }
    const white = this.props.problemInfo.playerWhite
    const playerColor = white ? 'White' : 'Red'
    colorClass = colors === 'green-red' ? 'alert-info' : 'alert-success'
    return {
      message: `${playerColor} to play and win.`,
      className: `alert ${colorClass}`
    }
  }

  getAttemptResults() {
    const { problemInfo } = this.props
    const { attempt } = problemInfo

    if (attempt) {
      if (problemInfo.solved) {
        if (problemInfo.submitStatus === SubmitStatus.SUCCESS) {
          //TODO: needed several exclamation points to make typescript
          //happy that is submission is successful, the ratings exist. Maybe
          //there is a better way.
          const userRating = attempt.user_next_rating!.toFixed(2)
          const userDelta = (attempt.user_next_rating! - attempt.user_previous_rating!)
          const userDeltaStr = (userDelta <= 0 ? '' : '+') + userDelta.toFixed(2)

          const probRating = attempt.problem_next_rating!.toFixed(2)
          const probDelta = (attempt.problem_next_rating! -
                       attempt.problem_previous_rating!)
          const probDeltaStr = (probDelta <= 0 ? '' : '+') + probDelta.toFixed(2)

          return {
            userRating,
            userDeltaStr,
            probRating,
            probDeltaStr,
            checkingResults: false,
            error: null
          }
        } else if (problemInfo.submitStatus === SubmitStatus.STARTED) {
          // Waiting for data
          return {
            userRating: null,
            userDeltaStr: null,
            probRating: null,
            probDeltaStr: null,
            checkingResults: true,
            error: 'still checking'
          }
        }
        // submitting does not equal started or success, error returned
        return {
          userRating: null,
          userDeltaStr: null,
          probRating: null,
          probDeltaStr: null,
          checkingResults: false,
          error: problemInfo.submitStatus
        }
      }
      // problem not solved, no error but no results either.
      return {
        userRating: null,
        userDeltaStr: null,
        probRating: null,
        probDeltaStr: null,
        checkingResults: false,
        error: null
      }
    } // No attempt, they should sign in.
    // But how to tell if there is no attempt because they are not
    // signed in or because they chose the problem?
    return {
      userRating: null,
      userDeltaStr: null,
      probRating: null,
      probDeltaStr: null,
      checkingResults: false,
      error: null
    }
  }

  getResults() {
    const { problemInfo } = this.props
    const attemptData = this.getAttemptResults()
    const colorClass = colors === 'green-red' ? 'alert-info' : 'alert-success'
    if (!problemInfo.solved) {
      // Not solved yet.
      return null
    } else if (attemptData.probRating == null && !this.props.signedIn) {
      // Solved, but nobody signed in.
      return div(
        { className: 'alert alert-warning' },
        a(
          { href: '/users/sign_up', className: 'alert-link' },
          'Click here',
        ),
        ' to track your progress and get puzzles selected for your skill.\n ' +
        'It\'s free!',
      )
    } else if (attemptData.probRating == null) {
      return div(
        { className: `alert ${colorClass}` },
        'Not a rated attempt',
      )
    } else if (attemptData.checkingResults) {
      // Solved but still checking results, above line should give message.
      return div({})
    } else if (attemptData.error) {
      return div(
        { className: 'alert alert-danger' },
        div(
          {},
          attemptData.error,
        ),
        div(
          {},
          'If this problem persists please contact the site administrators.',
        ),
      )
    }
    return div(
      { className: `alert ${colorClass}` },
      div(
        {},
        `User: ${attemptData.userRating} (${attemptData.userDeltaStr})`,
      ),
      div(
        {},
        `Problem: ${attemptData.probRating} (${attemptData.probDeltaStr})`,
      ),
    )
  }

  render() {
    if (gon.problem) {
      const line1 = this.getMessage()

      return div(
        {},
        div(
          { className: line1.className },
          line1.message,
        ),
        this.getResults(),
      )
    }
    return null
  }
}

interface IProblemFeedbackProps {
  // These are typed any because they actually return function that return
  // action creators.
  signedIn: boolean
  problemInfo: ProblemInfoState
}

export default ProblemFeedback
