// TODO: UserStates should be singular?
export enum UserStates{
  NAVIGATION = 'NAVIGATION',
  SELECTED_WRONG_SIDE = 'SELECTED_WRONG_SIDE',
  // MISSED_CAPTURE = 'MISSED_CAPTURE'
  // WATCHING_PARTIAL_ANIMATION = 'WATCHING__PARTIAL_ANIMATION'
  // WATCHING_FINAL_ANIMATION = 'WATCHING_FINAL_ANIMATION'
  HAVE_NEW_FULL_MOVE = 'HAVE_NEW_FULL_MOVE',
  HAVE_NEW_PARTIAL_MOVE = 'HAVE_NEW_PARTIAL_MOVE',
  AWAITING_DISAMBIGUATION = 'AWAITING_DISAMBIGUATION',
  NOTHING_SELECTED = 'NOTHING_SELECTED'
  // OPPONENTS_TURN: 'OPPONENTS_TURN'
}
