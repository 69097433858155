import * as React from 'react'
import { div, section } from 'react-dom-factories'

import { Provider } from 'react-redux'
import configureStore from '../store/configureStore'

import MainApp from '../containers/main_app'

// react state is a clone to avoid race conditions after many consecutive
// calls to pushSquareRef.
const e = React.createElement;

class Root extends React.Component {

  render() {
    // An initial state can be passed to configureStore but it has to the same
    // shape as the result of all the reducers which is tough. Probably better
    // to let each reducer grab the initial state from gon or some other source.
    const store = configureStore()

    return e(Provider, { store }, e(MainApp, {})) }
}

// if getCookie may be 'touch' or '' and touchevents are available, use touch.
// let context
// if (Modernizr.touchevents && (getCookie('board_drag') !== 'mouse')) {
//   context = DragDropContext(TouchBackend)(Root)
// } else {
//   context = DragDropContext(HTML5Backend)(Root)
// }

export default Root
