import * as _ from 'lodash'

import { Rules } from '../../engine/rules'
import { Position } from '../../engine/position'

import { GonObject } from '../types'
import { ExplorerMove } from './explorerReducer'

declare const gon: GonObject
/* eslint no-bitwise: ["error", { "allow": ["^"] }]
prefer-destructuring: ["error", { "array": false, "object": true }]
no-use-before-define: ["error", { "functions": false }]
no-alert: "off" */

interface RawSolution {
  moves: RawSolutionPath
}

interface RawSolutionPath {
  [ move: string ]: RawSolutionMove
}

interface RawSolutionMove {
  pv: string
  value: number
  //any other string is a move description for opponent's move
  response: 'YOU WIN!' | 'Alternate' | 'Not Good Enough!' | string
  defenses: RawSolutionDefense[]
  path?: RawSolutionPath
}

// Raw Solution is a string to move
interface RawSolutionDefense {
  description: string
  pv: string
  value: number
}

let positions:Position[] = [] // An array of position jsons
let moves:ExplorerMove[] = [] // An array of move descriptions, with values (possible repeats)
// Moves have indices into the position array
let mainLine:number[] = [] // An array of move indices

function createInitialGonPosition(positionsArray: Position[]) {
  if(!gon.position){
    throw new Error("Need gonPosition to createInitialGonPosition")
  }
  const initialPosition = Position.initFromGonPosition(gon.position)
  const newLength = positionsArray.push(initialPosition)
  initialPosition.index = newLength - 1
  return initialPosition
}

// A Null move, like we have in the database so that the current position
// is alawys the postPos and we can see the start of the game.
function createInitialNullMove(movesArray: ExplorerMove[]) {
  const nullMove = {
    id: 0,
    parent: null,
    description: 'Beginning',
    prePos: null,
    postPos: 0,

    value: 0,
    isBestPlayerMove: false,
    isAltPlayerMove: false,
    isComputerMove: true,

    isMainLine: false,
    isPlayerWin: false,
    moveInfo: null
  }
  movesArray.push(nullMove)
  return 0
}

// Return the index of the position in the module variable positions, adding
// it if necessary.
function findOrAddPosition(position: Position) {
  const index = position.index

  if (index === -1) {
    const newLength = positions.push(position)
    position.index = newLength - 1
    return newLength - 1
  }
  return index
}

interface AddMoveOptions {
  value?: number
  isMainLine?: boolean
  isBestPlayerMove?: boolean
  isAltPlayerMove?: boolean
  isComputerMove?: boolean
  isPlayerWin?: boolean
  rest?: string[]
}

// A helper function that takes a move and runs the engine to add another
// position to the tree if necessary or link an existing position. Requires
// the index of the parent move to build the right tree structure, returns
// the index of the created move to aid the recursion.
function addMove(currentPos: Position, moveDescription: string,
  parentMoveIndex: number, options: AddMoveOptions) {

  const value = options.value || 0
  const isMainLine = options.isMainLine || false
  const isBestPlayerMove = options.isBestPlayerMove || false
  const isAltPlayerMove = options.isAltPlayerMove || false
  const isComputerMove = options.isComputerMove || false
  const isPlayerWin = options.isPlayerWin || false
  const rest = options.rest || []

  const move = Rules.fastMatchingMove(currentPos, moveDescription, rest)
  const nextPos = currentPos.nextPosition(move)

  // Old moves are from a different part of the tree, repeated moves are draws
  // so they have different values, the same move in the same position can be a
  // different game state.
  const nextMove = {
    id: moves.length,
    parent: parentMoveIndex,
    description: move.toString(),
    prePos: currentPos.index,
    postPos: findOrAddPosition(nextPos),

    moveInfo: move,

    value,
    isBestPlayerMove,
    isAltPlayerMove,
    isComputerMove,
    isMainLine,
    isPlayerWin
  }

  moves.push(nextMove)
  const moveIndex = moves.length - 1

  if (isMainLine) {
    mainLine.push(moveIndex)
  }

  return moveIndex
}

// We add a move for the principal variation that is a copy of another
// move that will follow the main line in case the main line defense
// is different or has different pv as the engine moved forward through the
// solution.
function addPVMoves(parentMoveIndex: number, pvText: string, options: AddMoveOptions) {
  let currentMoveIndex = parentMoveIndex
  let currentPosition

  if (!pvText) {
    return
  }

  const pvDescriptions = pvText.split(/\s/).filter((str) =>
    (str.length !== 0) &&
      (str !== '0-1') &&
      (str !== '1-0') &&
      (str !== '1/2-1/2'))

  for (let i = 0; i < pvDescriptions.length; i += 1) {
    const description = pvDescriptions[i]

    if (currentMoveIndex) {
      currentPosition = positions[moves[currentMoveIndex].postPos]
    } else {
      currentPosition = positions[0]
    }

    const nextIndex = addMove(
      currentPosition, description, currentMoveIndex,
      {
        isBestPlayerMove: options.isBestPlayerMove,
        isAltPlayerMove: options.isAltPlayerMove,
        value: options.value,
        rest: pvDescriptions.slice(i + 1)
      },
    )

    currentMoveIndex = nextIndex
  }
}

// Adds the full solution to the tree, recursively with buildFromDefenses
function buildFromPath(currentPos: Position,
  path: RawSolutionPath, parentMoveIndex: number) {
  const moveDescriptions = _.keys(path)

  // Adds all the moves at this level of the path, we probably need to know
  // the parent move when we recurse.
  moveDescriptions.forEach((description) => {
    const { value } = path[description]
    const { pv } = path[description]
    const { response } = path[description]

    const isPlayerWin = response === 'YOU WIN!'
    const isLoss = response === 'Not Good Enough!'
    const isAltLine = response === 'Alternate'
    const isMainLine = !isLoss && !isAltLine

    addPVMoves(parentMoveIndex, pv, {
      value,
      isAltPlayerMove: isAltLine,
      isBestPlayerMove: isMainLine
    })

    if (isMainLine) {
      const moveIndex = addMove(currentPos, description, parentMoveIndex, {
        value,
        isMainLine,
        isPlayerWin
      })
      const nextPos = positions[moves[moveIndex].postPos]
      buildFromDefenses(nextPos, path[description], moveIndex)
    }
  })
}

// Takes a position that occured after a player move, a solution defenses node,
// and a parent move index and adds the defenive moves to the module variables.
// Recurses with buildFromPath to explore the whole solution.
function buildFromDefenses(currentPos: Position,
  computerMove: RawSolutionMove, parentMoveIndex: number) {
  const { response } = computerMove
  // Don't ask me why defenses are an array but user moves are a hash.
  let { defenses } = computerMove
  if (_.includes(['YOU WIN!', 'Not Good Enough!', 'Alternate'], response)) {
    if (!defenses) { defenses = [] }
  } else if (!defenses) { defenses = [{ description: computerMove.response, value: 0, pv: '' }] }

  let { value } = computerMove
  let { pv } = computerMove
  const chosenDefense = computerMove.response

  defenses.forEach((defense) => {
    const { description } = defense;
    ({ value } = defense);
    ({ pv } = defense)
    const isMainLine = chosenDefense === description

    addPVMoves(parentMoveIndex, pv, { value })

    if (isMainLine) {
      const moveIndex = addMove(currentPos, description, parentMoveIndex, {
        value,
        isMainLine,
        isComputerMove: true
      })
      const nextPosJSON = positions[moves[moveIndex].postPos]
      buildFromPath(nextPosJSON, computerMove.path!, moveIndex)
    }
  })
}

function initFromFen(fen: string) {
  positions = []
  moves = []
  mainLine = []

  try {
    const position = Position.initFromFenPosition(fen)
    const newLength = positions.push(position)
    position.index = newLength - 1
  } catch (e) {
    if (e.message.match(/invalid fen/)) {
      // Create an empty board.
      alert('Invalid FEN position.')
      createInitialGonPosition(positions)
    } else {
      throw e
    }
  }

  createInitialNullMove(moves)

  return {
    positions,
    moves,
    mainLine: [0],
    currentMove: 0,
    goingForward: true,
    autoPlay: false
  }
}

// Games don't have a solution, they have a move list. But we can store their
// moves and positions in the same explorer format, it's just simpler.
function initFromMoveList(moveList: string[], onlyQuick: boolean) {
  positions = []
  moves = []
  mainLine = []

  createInitialGonPosition(positions)
  createInitialNullMove(moves)

  if (onlyQuick){
    return {
      positions,
      moves,
      mainLine: [0],
      currentMove: 0,
      goingForward: true,
      autoPlay: false
    }
  }

  addPVMoves(0, moveList.join(' '), {})
  mainLine = _.range(moves.length)

  return {
    positions,
    moves,
    mainLine,
    currentMove: 0,
    goingForward: true,
    autoPlay: false
  }
}

// Explores the solution and adds moves and positions to the module variables
function buildFromSolution(solution: RawSolution, contextMoveDescription: string,
  quick: boolean = false
) {
  let contextMove
  let firstPlayerPosition
  const contextPosition = createInitialGonPosition(positions)
  createInitialNullMove(moves)

  if (contextMoveDescription) {
    contextMove = addMove(contextPosition, contextMoveDescription, 0, {
      isMainLine: true,
      isComputerMove: true
    })
    firstPlayerPosition = positions[1]
  } else {
    contextMove = 0
    firstPlayerPosition = positions[0]
  }

  if (!quick) {
    buildFromPath(firstPlayerPosition, solution.moves, contextMove)
  }
}

// The exploration tree as described by the problem and solution. Contains
// the context position and moves as well as the expected user moves, computer
// responses, and principal variations.
function initFromProblem(problem: string, quick: boolean = false) {
  // console.log(`Building Problem: Quick: ${quick}`)
  // Reset module variables
  positions = []
  moves = []
  mainLine = []

  const decoded = _.map(problem, ((char) => char.charCodeAt(0) ^ 67))
  const json = JSON.parse(String.fromCharCode.apply(null, decoded))

  buildFromSolution(json.solution, json.context_move_description, quick)

  return {
    positions,
    moves,
    mainLine,
    currentMove: 0,
    goingForward: true,
    autoPlay: false
  }
}

export {
  createInitialNullMove,
  createInitialGonPosition,
  initFromFen,
  initFromProblem,
  initFromMoveList
}
