
import { TypeKeys } from '../types'
import * as ActionTypes from '../actions'
import { Dispatch } from 'redux'
import { RootState } from '../reducers/root'

export function rightMouseDown(pdn: number):ActionTypes.RightMouseDownAction {
  return {
    type: TypeKeys.RIGHT_MOUSE_DOWN,
    pdn
  }
}

export function rightMouseEnter(pdn: number) {
  return (function dispatchRightMouseDown(dispatch: Dispatch<RootState>, getState: () => RootState) {
    const state = getState().arrows
    if (state.rightMouseDownOn != null) {
      dispatch({
        type: TypeKeys.RIGHT_MOUSE_ENTER,
        pdn
      } as ActionTypes.RightMouseEnterAction)
    }
  })
}

export function rightMouseUp(pdn: number) {
  return (function dispatchRightMouseUp(dispatch: Dispatch<RootState>, getState: () => RootState) {
    const state = getState().arrows
    if (state.rightMouseDownOn != null) {
      dispatch({
        type: TypeKeys.RIGHT_MOUSE_UP,
        pdn
      } as ActionTypes.RightMouseUpAction)
    }
  })
}

export function rightMouseLeftBoard() {
  return (function dispatchRightMouseLeftBoard(dispatch: Dispatch<RootState>, getState: () => RootState) {
    const state = getState().arrows
    if ((state.rightMouseDownOn != null)) {
      dispatch({
        type: TypeKeys.RIGHT_MOUSE_LEAVE_BOARD
      } as ActionTypes.RightMouseLeaveBoardAction)
    }
  })
}

