
import * as React from 'react'
import { bindActionCreators, Dispatch } from 'redux'

import { div } from 'react-dom-factories'
import { connect } from 'react-redux'


import Main from '../components/main'
import * as ExplorerActions from '../actions/explorerActions'
import * as ProblemActions from '../actions/problemActions'
import { RootState } from '../reducers/root'
import { Progression } from '../reducers/bootReducer'

interface StateProps {
  bootProgress: Progression
  shownTime: number
  problemStartTime: number
  currentMove: number // Need these to make sure main updates on player move.
  animationDone: boolean
}

interface DispatchProps {
  initProblem: (gonProblem: string, onlyQuick: boolean) => void
  startProblem: () => void
  checkPlayerMove: () => void
}

let timer = 0

const MainApp = {
  mapStateToProps(state: RootState): StateProps {
    return {
      bootProgress: state.boot.progress,
      shownTime: state.boot.shownTime,
      problemStartTime: state.problemInfo.startTime,
      currentMove: state.explorer.currentMove,
      animationDone: state.animate.done,
    }
  },

  mapDispatchToProps(dispatch: Dispatch<RootState>): DispatchProps {
    return bindActionCreators({...ExplorerActions, ...ProblemActions}, dispatch)
  }
}

export default connect(
  MainApp.mapStateToProps,
  MainApp.mapDispatchToProps,
)(Main as any)

