/* eslint react/forbid-prop-types: ["error", {"forbid": ["any", "array"] }] */
/* global gon */
import * as _ from 'lodash'

import * as React from 'react'
import * as PropTypes from 'prop-types'
import { GonObject } from '../types'

import { ExplorerState } from '../reducers/explorerReducer'
import { GonGameInfo } from '../types'

import { div, table, tbody, th, tr, td } from 'react-dom-factories'

import { SolutionAnchor, ISolutionAnchorProps } from './solution_anchor'
import { resizeToBoard } from '../utilities'
import { onNextFrame } from '../utilities'

declare const gon: GonObject

const e = React.createElement;

export class GameMoves extends React.Component<IGameMovesProps> {
  props!: IGameMovesProps

  refs!: {
    movesPlayed: HTMLDivElement
  }

  constructor(props: IGameMovesProps) {
    super(props)
  }

  // Game moves needs this because lazy rendering prevents it from rendering
  // at all until it has all the data it needs.
  componentDidMount() {
    $(window).on('resize', this.handleResizeEvent.bind(this))
    onNextFrame(() => this.handleResizeEvent())
  }

  componentWillUnmount() {
    $(window).off('resize', this.handleResizeEvent.bind(this))
  }

  handleResizeEvent() {
    resizeToBoard(this.refs.movesPlayed, this.props.boardRef)
  }

  componentDidUpdate(prevProps: IGameMovesProps) {
    if (prevProps.show !== this.props.show) {
      console.log("Game moves resizing!")
      this.props.queueResize()
    }
  }

  // Wraps build anchor in a span with a space to seperate the next anchor.
  buildAnchorSpanFromMove(explorer: ExplorerState, moveIndex: number) {
    // span {key: moveIndex},
    return this.buildAnchorFromMove(explorer, moveIndex)
  }

  // Builds a link from a move that can be clicked on to show the position
  // resulting from making that move.
  buildAnchorFromMove(explorer: ExplorerState, moveIndex: number) {
    let move = explorer.moves[moveIndex]
    // Do we need to init move here in case it doesn't exist?

    const { currentMove } = explorer
    const { setCurrentMove } = this.props

    return e(SolutionAnchor, {
      move,
      currentMove,
      setCurrentMove,
      show: true
    })
  }

  buildAnchorSpanFromResult() {
    const { game } = this.props
    const { explorer } = this.props

    if(!game){
      return null
    }

    let result = '\xbd - \xbd'
    if (game.black_win === true) {
      result = '1 - 0'
    } else if (game.white_win === true) {
      result = '0 - 1'
    }

    const move = explorer.moves[_.last(explorer.mainLine) as number]
    const { currentMove } = explorer
    const { setCurrentMove } = this.props

    return e(SolutionAnchor, {
      move,
      currentMove,
      setCurrentMove,
      show: true,
      customMoveDescription: result
    })
  }

  render() {
    if (this.props.show) {
      const { explorer } = this.props
      const mainLine = _.drop(explorer.mainLine, 1) // Ignore the initial null move

      return div(
        { className: 'moves-played', ref: 'movesPlayed' },
        div(
          { style: { textAlign: 'center' } },
          this.buildAnchorSpanFromMove(explorer, explorer.mainLine[0]),
        ),
        table(
          { className: 'table table-condensed' }, //Used to have start: 0, typescript didn't like it.
          tbody(
            {},
            _.chunk(mainLine, 2).map((twoMoves, sliceIndex) =>
              tr(
                { key: sliceIndex },
                th(
                  {},
                  `${sliceIndex + 1}.`,
                ),
                td(
                  { className: 'black-move' },
                  this.buildAnchorSpanFromMove(explorer, twoMoves[0]),
                ),
                twoMoves[1] ?
                  td(
                    { className: 'white-move' },
                    this.buildAnchorSpanFromMove(explorer, twoMoves[1]),
                  ) : undefined,
              )
            ),
            tr(
              { key: 'end' },
              td(
                { colSpan: 3, style: { textAlign: 'center' } },
                this.buildAnchorSpanFromResult(),
              ),
            ),
          ),
        ),
      )
      // this.buildMainLineSection(explorer, mainLine[1], 1)
    }
    return null
  }
}

export interface IGameMovesProps {
  explorer: ExplorerState
  game?: GonGameInfo
  show: boolean

  setCurrentMove: (move: number) => void
  queueResize: () => void

  boardRef: HTMLDivElement
}
