/* eslint no-console: off */

import { theBoard } from './board'
import { Player } from './players'
import { Square } from './square'

export class Piece {
  player: Player
  squareNum: number
  isKing: boolean
  constructor(player: Player, squareNum: number, isKing = false) {
    this.player = player
    this.squareNum = squareNum
    this.isKing = isKing
  }

  square() {
    return theBoard.squares[this.squareNum]
  }

  moveTo(destination: Square) {
    this.squareNum = destination.squareNum
  }

  promote() {
    console.assert(!this.isKing)
    this.isKing = true
  }

  // Used to undo moves, though undo isn't totally implemented
  unPromote() {
    console.assert(this.isKing)
    this.isKing = false
  }

  isEnemy(piece: Piece) {
    return piece.player !== this.player
  }

  pdn() {
    return this.square().pdn()
  }

  levelString() {
    if (this.isKing) {
      return 'king'
    }
    return 'man'
  }

  colorString() {
    return this.player.color
  }

  toPadded() {
    let char
    if (this.player.color === 'white') {
      char = 'w'
    } else {
      char = 'r'
    }
    if (this.isKing) {
      char = char.toUpperCase()
    }
    return char
  }
}

export interface PieceData {
  readonly pdn: number
  readonly color: string
  readonly level: string
}
