
import { connect } from 'react-redux'
import GameInfo from '../components/game_info'
import { GonGameInfo } from '../types'
import { RootState } from '../reducers/root'

interface StateProps {
  game?: GonGameInfo
  onPuzzle: boolean
  show: boolean
}

const GameInfoApp = {
  mapStateToProps(state: RootState): StateProps {
    let show
    let onPuzzle
    if (state.problemInfo.problemId != null) {
      onPuzzle = true
      if (state.problemInfo.solved) {
        show = true
      } else {
        show = false
      }
    } else {
      show = true
      onPuzzle = false
    }

    return {
      game: state.gameInfo.game,
      onPuzzle,
      show
    }
  },

  mapDispatchToProps() {
    return {}
  }
}

export default connect(
  GameInfoApp.mapStateToProps,
  GameInfoApp.mapDispatchToProps,
)(GameInfo as any)
