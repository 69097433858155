
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import * as _ from 'lodash'

import * as ExplorerActions from '../actions/explorerActions'
import * as BoardActions from '../actions/boardActions'
import Nav from '../components/navigation'

import { RootState } from '../reducers/root'

interface IStateProps {
  allowNavigation: boolean
  allowNextProblem: boolean
  autoPlay: boolean
}

interface IDispatchProps {
  forward: () => void
  backward: () => void
  beginning: () => void
  end: () => void
  flipBoard: () => void
  toggleAutoPlay: () => void
}

interface IOwnProps {

}

const NavApp = {
  mapStateToProps(state: RootState): IStateProps {
    let allowNavigation
    let allowNextProblem
    let autoPlay
    if (state.problemInfo.problemId && state.problemInfo.problemId >= 0) {
      allowNavigation = state.problemInfo.solved
      allowNextProblem = true;
      ({ autoPlay } = state.explorer)
    } else {
      allowNavigation = true
      allowNextProblem = false;
      ({ autoPlay } = state.explorer)
    }
    return {
      allowNavigation,
      allowNextProblem,
      autoPlay
    }
  },

  mapDispatchToProps(dispatch: Dispatch<RootState>): IDispatchProps {
    const actions = Object.assign({}, ExplorerActions, BoardActions)
    return bindActionCreators(actions, dispatch)
  }
}

export default connect(
  NavApp.mapStateToProps,
  NavApp.mapDispatchToProps,
)(Nav as any)
