
import { TypeKeys } from '../types'
import * as ActionTypes from '../actions'

// Action creators are functions that optionally take arguments and return
// plain old objects that are redux actions.
export function storeBeginAnimation(): ActionTypes.StoreBeginAnimationAction {
  return {
    type: TypeKeys.BEGIN_ANIMATION
  }
}
export function storeEndAnimation(): ActionTypes.StoreEndAnimationAction {
  return {
    type: TypeKeys.END_ANIMATION
  }
}

export function flipBoard(): ActionTypes.FlipBoardAction {
  return {
    type: TypeKeys.FLIP_BOARD
  }
}

export function doneRenderBoard(): ActionTypes.DoneRenderBoardAction {
  return {
    type: TypeKeys.DONE_RENDER_BOARD
  }
}

export function doneRenderPieces(): ActionTypes.DoneRenderPiecesAction {
  return {
    type: TypeKeys.DONE_RENDER_PIECES
  }
}
