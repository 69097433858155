/* eslint react/forbid-prop-types: ["error", {"forbid": ["any", "array"] }] */

import * as React from 'react'
import * as PropTypes from 'prop-types'

import * as _ from 'lodash'

import { ExplorerState } from '../reducers/explorerReducer'

import { span, ol, li, button } from 'react-dom-factories'

import { moveDescendentIndicies } from '../reducers/explorerFunctions'

import { SolutionAnchor, ISolutionAnchorProps } from './solution_anchor'

const e = React.createElement;

class SolutionLine extends React.Component<ISolutionLineProps, ISolutionLineState> {
  props!: ISolutionLineProps
  state: ISolutionLineState

  shouldComponentUpdate(nextProps: ISolutionLineProps, nextState: ISolutionLineState) {
    if ((this.state.ourIds.length !== nextState.ourIds.length) ||
       (this.state.collapse !== nextState.collapse) ||
       (this.props.show !== nextProps.show)) {
      return true
    }

    const currentlyActive = _.includes(this.state.ourIds, this.props.explorer.currentMove)
    if (currentlyActive) {
      return true
    }
    // TODO: When we update the state of ids, this will need to use
    // the next props to calculate ids but this is fast. And rendering
    // the solution is slow, so watch the animation speed.
    const nextActive = _.includes(this.state.ourIds, nextProps.explorer.currentMove)
    if (nextActive) {
      return true
    }
    return false
  }

  constructor(props: ISolutionLineProps) {
    super(props)
    const ids = moveDescendentIndicies(
      props.explorer,
      props.plyIndex,
    )
    ids.unshift(props.plyIndex)
    this.state = {
      ourIds: ids,
      collapse: true
    }
  }

  toggleCollapse() {
    this.setState({
      collapse: !this.state.collapse
    })
    this.props.resize()
  }

  // Called by buildMainLineSection as it loops through the main line's siblings.
  // For each sibling we render it's PV as a tree. These really could be
  // their own components.
  render() {
    const { explorer } = this.props
    const move = explorer.moves[this.props.plyIndex]

    return li(
      {},
      this.props.lineId,
      e(SolutionAnchor, {
        move,
        currentMove: explorer.currentMove,
        setCurrentMove: this.props.setCurrentMove,
        show: this.props.show
      }),
      this.buildValueSpan(),
      button(
        {
          className: 'btn btn-default btn-sm',
          onClick: this.toggleCollapse.bind(this)
        },
        'details',
      ),
      this.buildLineTree(),
    )
  }


  // Called by buildAlternateLine, we're given a move and we need to render
  // that move's and it's children as a tree. Currently though we assume
  // it's a single line.
  buildLineTree() {
    const { explorer } = this.props

    // TODO: We'll need to update the state when we can add moves to the tree
    const ids = _.drop(this.state.ourIds, 1)

    if (!this.state.collapse ||
       _.includes(this.state.ourIds, explorer.currentMove)) {
      return ol(
        { },
        ids.map((id) =>
          e(SolutionAnchor, {
            key: id,
            move: explorer.moves[id],
            show: this.props.show,
            currentMove: explorer.currentMove,
            setCurrentMove: this.props.setCurrentMove
          })
        ),
      )
    }
    return null
  }

  // Wraps the move value in a span with a class for color.
  buildValueSpan() {
    let className
    const { explorer } = this.props
    const { plyIndex } = this.props

    const move = explorer.moves[plyIndex]
    if (move.isBestPlayerMove) {
      className = 'best-player-move'
    } else if (move.isAltPlayerMove) {
      className = 'alt-player-move'
    } else if (this.playerMove(explorer, plyIndex)) {
      className = 'not-good-enough'
    } else {
      className = ''
    }
    return span(
      { className },
      `${move.value}: `,
    )
  }

  playerMove(explorer: ExplorerState, plyIndex: number) {
    // Position 0 is the context position, which makes position 1 the
    // first move where it's the player to move.
    // FIXME: What about test problems where there is no context move?
    const firstPlayerPosition = explorer.positions[1]
    const currentPosition = explorer.positions[explorer.moves[plyIndex].postPos]
    return firstPlayerPosition.whiteToPlay === currentPosition.whiteToPlay
  }

}

interface ISolutionLineState {
  ourIds: number[]
  collapse: boolean
}

interface ISolutionLineProps {
  explorer: ExplorerState
  show: boolean
  // The moves index in the big array of moves
  plyIndex: number
  // The moves index in the lineplayed, like black's move 4
  lineId: string
  setCurrentMove: (move: number) => void

  resize: () => void
}

export default SolutionLine
