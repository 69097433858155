
import * as React from 'react'
import * as PropTypes from 'prop-types'
const _ = require('lodash')

// Local jquery doesn't have scrollParent function, as it's part of jquery-ui
// const $ = require('jquery')
declare const $: JQueryStatic

import { span, a } from 'react-dom-factories'

export class SolutionAnchor extends React.Component<ISolutionAnchorProps> {
  props!: ISolutionAnchorProps

  constructor(props: ISolutionAnchorProps) {
    super(props)
  }

  shouldComponentUpdate(nextProps: ISolutionAnchorProps) {
    // Could take nextState.
    // We already know something changed, is it relevant to us?
    if(this.props.show != nextProps.show ||
      !_.isEqual(this.props.move, nextProps.move)) {
      return true
    }
    else if (nextProps.show) {
      if (nextProps.currentMove != this.props.currentMove) {
        if (this.active(this.props)){
          return true // We we the active link so need to rerender as empty
        }
        else {
          return this.active(nextProps)
        }
      }
    }
    return false
  }

  active(props: ISolutionAnchorProps) {
    return props.currentMove === props.move.id
  }

  scrollIntoView() {
    // this.refs.active.scrollIntoView()
    const item = $(this.refs.active)
    const scrollParent = item.scrollParent()
    // @ts-ignore:
    scrollParent.scrollTo(item, 100, { axis: 'y' })
    // TODO: There must be a better way to scrollTo.
  }

  componentDidMount() {
    if (this.active(this.props)) {
      this.scrollIntoView()
    }
  }

  componentDidUpdate() {
    if (this.active(this.props)) {
      this.scrollIntoView()
    }
  }

  render() {
    return span(
      { key: this.props.move.id },
      this.buildAnchorFromMove(),
      ' ',
    )
  }

  jumpToMoveResult(event: MouseEvent) {
    const plyIndex = this.props.show ? this.props.move.id : -1
    if (plyIndex >= 0) { this.props.setCurrentMove(plyIndex) }
  }

  // Builds a link from a move that can be clicked on to show the position
  // resulting from making that move.
  buildAnchorFromMove() {
    let className
    let desc
    const { move } = this.props
    if (this.props.show) {
      desc = this.props.customMoveDescription || move.description
    } else {
      desc = 'SHAME!'
    }

    if ((this.props.currentMove === move.id) &&
      !this.props.customMoveDescription) {
      className = 'current-move'
    } else {
      className = ''
    }
    // use this.props.id instead
    // const moveId = this.props.show ? move.id : -1

    return a(
      {
        className,
        href: '#',
        // 'data-move-id': moveId,
        onClick: this.jumpToMoveResult.bind(this),
        ref: 'active'
      },
      desc,
    )
  }
}

export interface ISolutionAnchorProps {
  show: boolean
  move: {
    id: number
    description: string
  }
  currentMove: number
  setCurrentMove: (move: number) => void

  customMoveDescription?: string
}

