
import * as React from 'react'
import * as PropTypes from 'prop-types'
import * as $ from 'jquery'

import {
  div, h3, table, thead, tbody, tr, th, td
} from 'react-dom-factories'

class AttemptHistory extends React.Component<AttemptHistoryProps, AttemptHistoryState> {
  props!: AttemptHistoryProps
  state: AttemptHistoryState

  constructor(props: AttemptHistoryProps) {
    super(props)
    this.state = {
      data: {
        numCorrect: 0,
        numWrong: 0,
        feedbackColors: 'green-red',
        attempts: [],
        history: []
      },
      ajax: null,
      error: ''
    }
  }

  // Returns an AJAX request on which you can call abort.
  ajaxRequest(problemId: number) {
    // console.log "Ajax call "
    return $.ajax({
      url: `/problems/${problemId}/attempts`,
      dataType: 'json',
      error: ((jqXHR, textStatus) =>
        // console.log "ajax error"
        this.setState({
          error: `AJAX Error: ${textStatus}`
        })),
      success: ((data: AttemptAjaxData) =>
        // console.log "ajax return"
        this.setState({
          data
        }))
    })
  }

  refresh() {
    if (this.props.problemId > 0) {
      this.setState({
        ajax: this.ajaxRequest(this.props.problemId)
      })
    }
  }

  componentWillUnmount() {
    if (this.state.ajax) {
      this.state.ajax.abort()
    }
  }

  componentDidMount() {
    this.refresh()
  }

  renderSummary(data: AttemptAjaxData) {
    return h3(
      {},
      div(
        {},
        'All Attempts',
      ),
      div(
        { className: 'small' },
        `${data.numCorrect} Correct, ${data.numWrong} Errors.`,
      ),
    )
  }

  attempts(data: AttemptAjaxData) {
    return table(
      { className: 'table table-condensed', style: { width: '95%' } },
      thead(
        {},
        tr(
          {},
          th(
            {},
            'Mistake',
          ),
          th(
            {},
            'Played',
          ),
          th(
            {},
            'Avg Rating',
          ),
        ),
      ),
      tbody(
        {},
        data.attempts.map(
          ((attempt, idx) =>
            tr(
              { className: 'attempt', key: idx },
              td(
                {},
                attempt.mistake,
              ),
              td(
                { style: { textAlign: 'center', width: '1%' } },
                attempt.count,
              ),
              td(
                { style: { textAlign: 'center' } },
                attempt.avgRating,
              ),
            )
          ), this,
        ),
      ),
    )
  }

  renderAttempts(data: AttemptAjaxData) {
    if (!data || !data.attempts || data.attempts.length == 0) {
      return null
    } else {
      return this.attempts(data)
    }
  }

  history(data: AttemptAjaxData) {
    return div(
      {},
      h3(
        {},
        'Your History',
      ),
      table(
        { className: 'table table-condensed', style: { width: '95%' } },
        thead(
          {},
          tr(
            {},
            th(
              {},
              'Date',
            ),
            th(
              {},
              'Mistake',
            ),
          ),
        ),
        tbody(
          {},
          data.history.map(((hist, idx) => {
            let className = `attempt ${data.feedbackColors}`
            className += hist.correct ? ' got-it-right' : ' got-it-wrong'
            return tr(
              { className, key: idx },
              td(
                {},
                hist.time,
              ),
              td(
                {},
                hist.mistake,
              ),
            )
          }), this),
        ),
      ),
    )
  }

  renderHistory(data: AttemptAjaxData) {
    if (!data || !data.history || data.history.length == 0) {
      return null
    } else {
      return this.history(data)
    }
  }

  render() {
    const { data } = this.state
    if (this.props.show && data) {
      return div(
        { id: 'attempt-history' },
        this.renderSummary(data),
        this.renderAttempts(data),
        this.renderHistory(data),
      )
    }
    return null
  }
}

interface AttemptHistoryProps {
  problemId: number
  show: boolean
}

interface AttemptAjaxData {
  numCorrect: number
  numWrong: number
  feedbackColors: 'green-red' | 'blue-yellow'
  attempts: {
    mistake: string
    count: number
    avgRating: number
  }[]
  history: {
    time: string
    mistake: string
    correct: boolean
  }[]

}

interface AttemptHistoryState {
  data: AttemptAjaxData
  ajax: JQuery.jqXHR<AttemptAjaxData> | null
  error: string
}

export default AttemptHistory
