
export enum PlayerColors {
  PLAYER_1_COLOR = 'black',
  PLAYER_2_COLOR = 'white'
}

import { Direction, directions } from './direction'

class Player {
  color: PlayerColors
  leftForward:Direction
  rightForward:Direction
  leftBackwards:Direction
  rightBackwards:Direction

  // TODO: Rename backwards to singular backward
  constructor(color: PlayerColors) {
    this.color = color
    if (this.color === PlayerColors.PLAYER_1_COLOR) {
      // The numbers are offsets into the padded square array from
      // the player's perspective.
      this.leftForward = directions[3]
      this.rightForward = directions[2]
      this.leftBackwards = directions[1]
      this.rightBackwards = directions[0]
    } else if (this.color === PlayerColors.PLAYER_2_COLOR) {
      this.leftForward = directions[0]
      this.rightForward = directions[1]
      this.leftBackwards = directions[2]
      this.rightBackwards = directions[3]
    } else {
      throw new Error('Invalid player color.')
    }
  }

  left(options = { backwards: false }) {
    if (options.backwards) {
      return this.leftBackwards
    }
    return this.leftForward
  }

  right(options = { backwards: false }) {
    if (options.backwards) {
      return this.rightBackwards
    }
    return this.rightForward
  }
}

export { Player }
export let blackPlayer = new Player(PlayerColors.PLAYER_1_COLOR)
export let whitePlayer = new Player(PlayerColors.PLAYER_2_COLOR)
