
import { connect } from 'react-redux'

import { getCookie, isSignedIn } from '../../enhancements/utilities'

import { CommentList, ICommentListProps } from '../components/comment_list'

import { RootState } from '../reducers/root'

const signedIn = isSignedIn()
const userId = parseInt(getCookie('user_id'), 10)

// TODO: This one doesn't require more type information? Why not?
const ProblemCommentsApp = {
  mapStateToProps(state: RootState) {
    let id
    let show
    if (state.problemInfo.problemId != null) {
      id = state.problemInfo.problemId
      show = state.problemInfo.solved
    } else {
      id = -1
      show = false
    }

    return {
      problemId: id,
      show,

      signedIn,
      userId
    }
  },

  mapDispatchToProps() {
    return {}
  }
}

export default connect(
  ProblemCommentsApp.mapStateToProps,
  ProblemCommentsApp.mapDispatchToProps,
)(CommentList as any)
