/* eslint no-console: 'off' */
/* global $ */

import * as React from 'react'
import * as PropTypes from 'prop-types'

import {
  div, form, button, span, a, textarea
} from 'react-dom-factories'

class Comment extends React.Component<ICommentProps, ICommentState> {
  props!: ICommentProps
  state: ICommentState
  // TODO: This bang skips typescript's check. Better would be to
  // use reacts callback style refs instead of strings.
  refs!: {
    newBody: HTMLTextAreaElement
  }

  constructor(props: ICommentProps) {
    super(props)
    this.state = {
      edit: false
    }
  }

  destroy(event: MouseEvent) {
    event.preventDefault()

    $.ajax({
      url: `/comments/${this.props.id}`,
      type: 'delete',
      error: ((jqXHR, textStatus, errorThrown) => {
        console.log(`destroy comment ajax error: ${textStatus}`)
        console.log(jqXHR)
        console.log(errorThrown)
      }),
      success: (() => this.props.refresh())
    })
  }

  edit() {
    this.setState({ edit: true })
  }

  cancelEdit(event: MouseEvent | null) {
    if (event) { // Can be called with event on mouse click or without from ajax.
      event.preventDefault()
    }
    this.setState({ edit: false })
  }

  update(event: MouseEvent) {
    event.preventDefault()
    $.ajax({
      url: `/comments/${this.props.id}`,
      type: 'PUT',
      data: {
        body: this.refs.newBody.value.trim()
      },
      error: ((jqXHR, textStatus, errorThrown) => {
        console.log(`edit comment ajax error: ${textStatus}`)
        console.log(jqXHR)
        console.log(errorThrown)
      }),
      success: (() => {
        this.cancelEdit(null)
        this.props.refresh()
      })
    })
  }

  renderEditButtons() {
    if (((this.props.currentUserId === this.props.commentUserId) &&
        this.props.last) ||
        (this.props.currentUserId === 1)) { // A cheap standin for admin.
      return div(
        { className: 'btn-toolbar' },
        button(
          {
            type: 'button',
            className: 'btn btn-warning btn-sm',
            onClick: this.edit.bind(this)
          },
          'Edit',
        ),
        ' ', // space between buttons
        button(
          {
            type: 'button',
            className: 'btn btn-danger btn-sm',
            onClick: this.destroy.bind(this)
          },
          'Delete',
        ),
      )
    }
    return null
  }

  render() {
    return div(
      {
        className: 'comment',
        style: { overflowWrap: 'break-word', whiteSpace: 'pre-wrap' }
      },
      a(
        { href: `/users/${this.props.userName}` },
        this.props.userName,
      ),
      ' ', // a space between the name and the date
      span(
        {},
        this.props.date,
      ),
      this.state.edit ?
        form(
          {
            className: 'form-horizontal',
            onSubmit: this.update.bind(this)
          },
          textarea({
            ref: 'newBody',
            autoFocus: true,
            // type: 'text',
            className: 'form-control',
            id: 'body',
            defaultValue: this.props.body,
            style: { resize: 'vertical' },
            maxLength: 500
          }),
          div(
            { className: 'btn-toolbar' },
            button(
              {
                type: 'submit',
                className: 'btn btn-primary btn-xs'
              },
              'Update',
            ),
            ' ', // space between buttons
            button(
              {
                className: 'btn btn-warning btn-xs',
                onClick: this.cancelEdit.bind(this)
              },
              'Cancel',
            ),
          ),
        )
        :
        div(
          {},
          div(
            {},
            this.props.body,
          ),
          this.renderEditButtons(),
        ),
    )
  }
}

interface ICommentProps {
  id: number
  userName: string
  date: string
  body: string
  refresh: () => void

  commentUserId: number
  currentUserId: number
  last: boolean
}

interface ICommentState {
  edit: boolean
}

interface ICommentRefs {
  newBody: HTMLTextAreaElement
}

export default Comment

