
import * as React from 'react'
import * as PropTypes from 'prop-types'

import { div, a } from 'react-dom-factories'

export class ProblemInfo extends React.PureComponent<IProblemInfoProps> {
  props!: IProblemInfoProps

  constructor(props: IProblemInfoProps) {
    super(props)
  }

  render() {
    const id = this.props.problemId
    const rating = this.props.problemRating

    if (id == null || rating == null) {
      return null
    }

    const hiddenStyle = this.props.show ? 'problem-info' : 'hidden'
    return div(
      { className: hiddenStyle },
      div(
        {},
        'Problem #: ',
        a({ href: `/problems/${id}` }, id),
      ),
      div(
        {},
        `Rating: ${rating.toFixed(1)}`,
      ),
    )
  }
}

interface IProblemInfoProps {
  problemId: number | null
  problemRating: number | null
  show: boolean
}

