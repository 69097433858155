
import * as React from 'react'
import * as PropTypes from 'prop-types'

import Square from '../containers/squareApp'
import { ReactSquareProps } from '../components/square'
// import Square from '../containers/droppable_square'

import { div } from 'react-dom-factories'

const e = React.createElement;

// The chess row and col are both 0-7 and start at the bottom left from
// white's perspective. So 0,0 should give square, 29, 7,7 should give /quare 4
// We have no representation of white squares so those return nil
function getPdnByRowCol(rowNum: number, colNum: number) {
  // First we need to reverse the "bottom" of the board.
  const realRowNum = 7 - rowNum
  const square64 = (realRowNum * 8) + colNum // 0 - 63, even numbers are light squares
  const square32 = (square64 / 2) + 1
  const pdn = Math.floor(square32)

  if (!((realRowNum + colNum) % 2)) {
    return null // white square.
  }
  return pdn
}

class ReactRow extends React.Component<ReactRowProps> {
  props!: ReactRowProps

  constructor(props: ReactRowProps) {
    super(props)
  }

  getPdns(props: ReactRowProps) {
    const columns = [0, 1, 2, 3, 4, 5, 6, 7]
    const { rowNumber } = props

    return columns.map(((colNumber) => getPdnByRowCol(rowNumber, colNumber)), this)
  }

  shouldComponentUpdate(nextProps: ReactRowProps) {
    return (this.props.whiteOnBottom !== nextProps.whiteOnBottom) ||
       (this.props.rowNumber !== nextProps.rowNumber)
  }
  // this.props.squareRefs != nextProps.squareRefs

  squares() {
    const { rowNumber } = this.props

    const squares = [0, 1, 2, 3, 4, 5, 6, 7]
    if (!this.props.whiteOnBottom) {
      squares.reverse()
    }

    // call amp, create anon, with arg0
    return squares.map((colNumber) => {
      const key = (rowNumber * 8) + colNumber
      const pdn = getPdnByRowCol(rowNumber, colNumber)

      const squareProps = {
        pdn,
        row: rowNumber,
        col: colNumber,
        key,
        pushSquareRef: this.props.pushSquareRef,
        whiteOnBottom: this.props.whiteOnBottom,
        boardSelection: this.props.boardSelection,
        showNumberedNotation: this.props.showNumberedNotation,
        closestPdn: this.props.closestPdn,
      }

      return e(Square, squareProps)
    })
  } // pass this to map and then to anon

  render() {
    let rightBorderCell
    let label = ''
    let className = 'rank-label'
    if (this.props.showAlgebraicNotation) {
      label = `${this.props.rowNumber + 1}`
    }

    if (this.props.showBoardPerspective) {
      className += ' perspective'
      rightBorderCell = null
    } else {
      className += ' no-perspective'
      rightBorderCell = div(
        { className: `${className} ${this.props.boardSelection}` },
        label,
      )
    }

    const leftBorderCell = div(
      { className: `${className} ${this.props.boardSelection}` },
      label,
    )

    return div(
      { className: 'board-row' },
      leftBorderCell,
      this.squares(),
      rightBorderCell,
    )
  }
}

interface ReactRowProps {
  whiteOnBottom: boolean
  boardSelection: string
  showNumberedNotation: boolean
  showAlgebraicNotation: boolean
  showBoardPerspective: boolean

  rowNumber: number
  pushSquareRef: (pdn: number, ref: HTMLDivElement) => void
  closestPdn: (event: any) => number | null
}

// ReactRow.propTypes = {
//   whiteOnBottom: PropTypes.bool.isRequired,
//   boardSelection: PropTypes.string.isRequired,
//   showNumberedNotation: PropTypes.bool.isRequired,
//   showAlgebraicNotation: PropTypes.bool.isRequired,
//   showBoardPerspective: PropTypes.bool.isRequired,

//   rowNumber: PropTypes.number.isRequired,
//   pushSquareRef: PropTypes.func.isRequired,
//   closestPdn: PropTypes.func.isRequired
// }

export default ReactRow
