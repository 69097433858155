
import { TypeKeys } from '../types'
import { ActionTypes } from '../actions'

import * as _ from 'lodash'

export interface ArrowState {
  readonly arrows: ReadonlyArray<ReadonlyArray<number>>
  readonly highlights: ReadonlyArray<number>
  readonly rightMouseDownOn: number | null
  readonly rightMouseCurrentlyOver: number | null
}

function initialState(): ArrowState {
  return {
    arrows: [],
    highlights: [],
    rightMouseDownOn: null,
    rightMouseCurrentlyOver: null
  }
}

// Returns a new array with or without the toggled item.
function toggleIncludesHighlights(array: ReadonlyArray<number>,
                                  toggle: number): ReadonlyArray<number> {
  let next: ReadonlyArray<number>
  const alreadyIncludes = _.some(array, ((item) =>
    // console.log "comparing #{item} to #{toggle}"k
    _.isEqual(item, toggle)
    // console.log "equal: #{equal}"
  ))

  if (alreadyIncludes) {
    // console.log "removing #{toggle}"
    next = array.filter((item) =>
      !(_.isEqual(item, toggle))
    )
  } else {
    // console.log "adding #{toggle}"
    next = array.concat(toggle)
  }

  return next
}

// Returns a new array with or without the toggled item.
function toggleIncludesArrows(array: ReadonlyArray<ReadonlyArray<number>>,
                              toggle: number[]): ReadonlyArray<ReadonlyArray<number>>{
  let next: ReadonlyArray<ReadonlyArray<number>>
  const alreadyIncludes = _.some(array, ((item) =>
    // console.log "comparing #{item} to #{toggle}"
    //
    _.intersection(item, toggle).length === 2
    // console.log "equal: #{equal}"
  ))

  if (alreadyIncludes) {
    // console.log "removing #{toggle}"
    next = array.filter(((item) =>
      !(_.intersection(item, toggle).length === 2)
    ))
  } else {
    // console.log "adding #{toggle}"
    next = array.concat([toggle])
  }

  return next
}



export function arrows(state: ArrowState, action: ActionTypes): ArrowState {
  if (!state) {
    return initialState()
  }

  switch (action.type) {
    case TypeKeys.INIT_FEN:
    case TypeKeys.SET_CURRENT_MOVE:
    case TypeKeys.PROBLEM_START:
    case TypeKeys.BACKWARD:
    case TypeKeys.BEGINNING:
    case TypeKeys.END:
    case TypeKeys.ADD_NEW_MOVE:
    case TypeKeys.DESELECT_PIECE:
    case TypeKeys.SELECT_PIECE:
      return initialState()

    case TypeKeys.RIGHT_MOUSE_DOWN:
      return Object.assign({}, state, {
        rightMouseDownOn: action.pdn
      })

    case TypeKeys.RIGHT_MOUSE_ENTER:
      return Object.assign({}, state, {
        rightMouseCurrentlyOver: action.pdn
      })

    case TypeKeys.RIGHT_MOUSE_UP: {
      if (!state.rightMouseDownOn) {
        return state
      }
      else if (action.pdn == null || action.pdn === state.rightMouseDownOn) {
        return Object.assign({}, state, {
          highlights: toggleIncludesHighlights(state.highlights, state.rightMouseDownOn),
          rightMouseDownOn: null,
          rightMouseCurrentlyOver: null
        })
      }
      else {
        return Object.assign({}, state, {
          arrows: toggleIncludesArrows(
            state.arrows,
            [state.rightMouseDownOn, action.pdn]
          ),
          rightMouseDownOn: null,
          rightMouseCurrentlyOver: null
        })
      }
    }

    // Right click on a square toggles its highlight.
    case TypeKeys.RIGHT_MOUSE_LEAVE_BOARD:
      return Object.assign({}, state, {
        rightMouseDownOn: null
      })

    case TypeKeys.MISSED_CAPTURE_MOVE: {
      const captures = _.uniqWith(action.captures, ((a, b) => _.isEqual(a, b)))

      return Object.assign({}, state, {
        arrows: captures,
        highlights: []
      })
    }

    case TypeKeys.WRONG_TEAM_SELECTED:
      return Object.assign({}, state, {
        arrows: [],
        highlights: action.pieces
      })

    case TypeKeys.ADD_TO_MOVE: {
      const choices = action.choices.map((choice) => [_.last(action.squares), choice])
      return Object.assign({}, state, {
        arrows: choices,
        highlights: []
      })
    }

    default:
      return state
  }
}
