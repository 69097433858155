/* eslint react/forbid-prop-types: ["error", {"forbid": ["any", "array"] }] */

import * as React from 'react'
import * as PropTypes from 'prop-types'
import ReactRow from './row'

import { div } from 'react-dom-factories'

import * as $ from 'jquery'
import * as _ from 'lodash'

import { Progression } from '../reducers/bootReducer'
import { onNextFrame } from '../utilities'

const e = React.createElement;

let timer = 0

export class ReactBoard extends React.Component<ReactBoardProps> {
  props!: ReactBoardProps
  // TODO: This bang skips typescript's check. Better would be to
  // use reacts callback style refs instead of strings.
  refs!: {
    board: HTMLDivElement
  }

  constructor(props: ReactBoardProps) {
    super(props)
  }

  // This doesn't seem to be needed, most of the board props don't change.
  // The one I worry about is the refs but rerendering doesn't seem
  // to break them. But if removed, code in componentWillReceiveProps should
  // probably be moved to componentDidUpdate
  shouldComponentUpdate(nextProps: ReactBoardProps) {
    const boardShouldUpdate = nextProps.whiteOnBottom !== this.props.whiteOnBottom ||
           nextProps.bootProgress == Progression.QUICK_INIT
    // console.log(`Board should update: ${boardShouldUpdate}`)
    return boardShouldUpdate
  }

  componentDidMount() {
    // console.log(`board did mount`)
    this.props.pushBoardRef(this.refs.board)
    if (this.props.squareRefs.length >= 32 &&
      this.props.bootProgress == Progression.QUICK_INIT &&
      !timer) {
      timer = onNextFrame(() => this.props.doneRenderBoard())
    }
  }

  componentDidUpdate(prevProps: ReactBoardProps) {
    // console.log(`Board receive props, length: ${prevProps.squareRefs.length},
    //   progression: ${prevProps.bootProgress}`)
    if (this.props.squareRefs.length >= 32 &&
      this.props.bootProgress == Progression.QUICK_INIT) {
      onNextFrame(() => {
        this.props.doneRenderBoard()
      })
    }
  }

  closestPdn(event: MouseEvent) {
    // console.log 'closest ref'
    let closestDist = -1
    let closest = 0

    // Have mouse in page coordinates
    const mouseTop = event.pageY
    const mouseLeft = event.pageX

    _.each(this.props.squareRefs, _.bind(((ref, idx) => {
      if(!ref) {
        return
      }

      const rect = ref.getBoundingClientRect()
      const scrollTop = document.body.scrollTop
      const scrollLeft = document.body.scrollLeft
      const squareRadius = ref.offsetHeight / 2

      // Get square centers in page coordinates.
      const squareTopCenter = rect.top + scrollTop + squareRadius
      const squareLeftCenter = rect.left + scrollLeft + squareRadius

      // compare distances
      const distance = Math.sqrt(((mouseTop - squareTopCenter) ** 2) +
        ((mouseLeft - squareLeftCenter) ** 2))

      if (distance < closestDist || closestDist < 0) {
        // console.log(`Distance to ${idx} is ${distance}`)
        closest = idx
        closestDist = distance
      }
    }), this))

    if(closest <= 0){
      throw 'Closest pdn invalid, no refs yet?'
    }
    // console.log "closest is #{closest}"
    return closest
  }

  handleContextMenu(event: MouseEvent) {
    event.preventDefault()
  }
  // if this.props.pdn?
  //   this.props.highlight(this.props.pdn)

  handleMouseDown(event: MouseEvent) {
    // console.log event.button
    const pdn = this.closestPdn(event)
    if (!pdn) {
      return
    }

    if (event.button === 2) {
      this.props.rightMouseDown(pdn)
    }
  }

  handleMouseUp(event: MouseEvent) {
    // Don't limit by props.pdn because they might lift the mouse over white
    // square
    const pdn = this.closestPdn(event)
    if (!pdn) {
      return
    }
    if (event.button === 2) {
      this.props.rightMouseUp(pdn)
    }
    else {
      this.props.clickSquare(pdn)
    }
  }


  handleMouseMove(event: MouseEvent) {
    const pdn = this.closestPdn(event)
    if(!pdn) {
      return
    }
    this.props.rightMouseEnter(pdn)
  }

  boardRows() {
    const rows = [7, 6, 5, 4, 3, 2, 1, 0] // row 8 is first on the page if white is on bottom.
    if (!this.props.whiteOnBottom) {
      rows.reverse()
    }

    return rows.map((rowNumber) =>
      // call map, create anon function, with num args
      e(ReactRow, {
        whiteOnBottom: this.props.whiteOnBottom,
        rowNumber,
        boardSelection: this.props.boardSelection,
        showNumberedNotation: this.props.showNumberedNotation,
        showAlgebraicNotation: this.props.showAlgebraicNotation,
        showBoardPerspective: this.props.showBoardPerspective,
        key: rowNumber,
        pushSquareRef: this.props.pushSquareRef,
        closestPdn: this.closestPdn
      })
    )
  } // Pass this to map which passes it to the anonymous function.

  topBorder() {
    if (this.props.showBoardPerspective) {
      return null
    }
    return div(
      { className: 'file-labels', ref: 'topBorder' },
      div({ className: `corner no-perspective ${this.props.boardSelection}` }),
      this.columnLabels(),
      div({ className: `corner no-perspective ${this.props.boardSelection}` }),
    )
  }

  bottomBorder() {
    let leftCornerClassName
    let rightCorner
    if (this.props.showBoardPerspective) {
      rightCorner = null
      leftCornerClassName = 'corner perspective'
    } else {
      rightCorner = div({ className: `corner no-perspective ${this.props.boardSelection}` })
      leftCornerClassName = 'corner no-perspective'
    }

    return div(
      { className: 'file-labels' },
      div({ className: `${leftCornerClassName} ${this.props.boardSelection}` }),
      this.columnLabels(),
      rightCorner,
    )
  }

  columnLabels() {
    const labelLetters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h']

    let className = 'file-label'
    if (this.props.showBoardPerspective) {
      className += ' perspective'
    } else {
      className += ' no-perspective'
    }

    const rows = [0, 1, 2, 3, 4, 5, 6, 7]
    if (!this.props.whiteOnBottom) {
      rows.reverse()
    }

    return rows.map((rowNumber) => {
      let label
      const key = labelLetters[rowNumber]
      if (this.props.showAlgebraicNotation) {
        label = labelLetters[rowNumber]
      } else {
        label = ' '
      }
      return div(
        { className: `${className} ${this.props.boardSelection}`, key },
        label,
      )
    })
  }

  // Takes about 45 milliseconds to update.
  render() {
    const topBorder = this.topBorder()
    const bottomBorder = this.bottomBorder()
    const board = div(
      { className: 'board' }, // Includes just the rows
      this.boardRows(),
    )

    return div(
      {
        className: 'board-container',
        onContextMenu: this.handleContextMenu.bind(this),
        onMouseDown: this.handleMouseDown.bind(this),
        onMouseUp: this.handleMouseUp.bind(this),
        onMouseMove: this.handleMouseMove.bind(this),
        ref: 'board'
      }, // includes the navigation buttons
      topBorder,
      board,
      bottomBorder,
    )
  }
}

export interface ReactBoardProps {
  whiteOnBottom: boolean
  bootProgress: Progression

  pushSquareRef: (pdn: number, ref: HTMLDivElement) => void
  pushBoardRef: (ref: HTMLDivElement) => void
  boardSelection: string
  showBoardPerspective: boolean
  showNumberedNotation: boolean
  showAlgebraicNotation: boolean

  squareRefs: [HTMLDivElement]

  // Injected by boardApp
  clickSquare: (pdn: number) => void

  rightMouseDown: (pdn: number) => void
  rightMouseUp: (pdn: number) => void
  rightMouseEnter: (pdn: number) => void
  doneRenderBoard: () => void
}
