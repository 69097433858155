/* eslint react/forbid-prop-types: ["error", {"forbid": ["any", "array"] }] */

import * as React from 'react'
import * as PropTypes from 'prop-types'

import { ReactArrow } from '../components/arrow'
import { ReactCircle } from '../components/circle'

import * as $ from 'jquery'

const e = React.createElement;

// Perf = require 'react-addons-perf'

const {
  svg, defs, path
} = require('react-dom-factories')

const debugComponent = function (msg: string) {
  // console.log(`Arrows: ${msg}`)
}

interface Point {
  top: number
  left: number
}

function lengthenLineSegment(fromPoint: Point, toPoint: Point, lengthenAmount: number) {
  let dX = toPoint.left - fromPoint.left
  let dY = toPoint.top - fromPoint.top
  const length = Math.sqrt((dX * dX) + (dY * dY))

  dX /= length
  dY /= length

  dX *= length + lengthenAmount
  dY *= length + lengthenAmount

  return {
    top: fromPoint.top + dY,
    left: fromPoint.left + dX
  }
}

export class Arrows extends React.PureComponent<IArrowsProps> {
  props!: IArrowsProps

  constructor(props: IArrowsProps) {
    super(props)
  }

  // Called just once after the first render to the screen. Calls setState
  // which will trigger a second render.
  componentDidMount() {
    $(window).on('resize', this.handleResizeEvent.bind(this))
    this.handleResizeEvent()
  }

  componentWillUnmount() {
    $(window).off('resize', this.handleResizeEvent.bind(this))
  }

  handleResizeEvent() {
    this.forceUpdate()
  }

  // Given an pieceJSON, (and a drag-drop move in the future) figure out
  // where to render the piece as a starting location.
  squareMiddlePixelLocation(pdn: number): Point {
    const ref = this.props.squareRefs[pdn]
    if (!ref || !this.props.boardRef) { return { top: 0, left: 0 } }

    const boardOffsetTop = this.props.boardRef.offsetTop
    const boardOffsetLeft = this.props.boardRef.offsetLeft
    const topPixel = (ref.offsetTop + (ref.offsetHeight / 2)) - boardOffsetTop
    const leftPixel = (ref.offsetLeft + (ref.offsetHeight / 2)) - boardOffsetLeft

    debugComponent(`Top of ${pdn} is ${topPixel}`)

    return {
      top: topPixel,
      left: leftPixel
    }
  }

  arrowEndPointLocation(fromPdn: number, toPdn: number): Point {
    const start = this.squareMiddlePixelLocation(fromPdn)
    const end = this.squareMiddlePixelLocation(toPdn)
    // The pixel height of the marker end. Maybe we can do smarter.
    const lengthenBy = -25 // shorten by 25

    const newEnd = lengthenLineSegment(start, end, lengthenBy)
    return newEnd
  }

  arrowProps(fromPdn: number, toPdn: number, preview = false) {
    debugComponent(`Drawing arrow from ${fromPdn} to ${toPdn}`)
    let height = 0
    if (this.props.squareRefs[1]) {
      height = this.props.squareRefs[1].offsetHeight
    }

    return {
      fromLocation: this.squareMiddlePixelLocation(fromPdn),
      toLocation: this.arrowEndPointLocation(fromPdn, toPdn),
      size: height,
      key: `${fromPdn}-${toPdn}`,
      stroke: this.strokeForBoardSelection(),
      preview
    }
  }

  circleProps(pdn: number, preview = false) {
    let height = 0
    if (this.props.squareRefs[1]) {
      height = this.props.squareRefs[1].offsetHeight
    }

    return {
      location: this.squareMiddlePixelLocation(pdn),
      size: height,
      key: `${pdn}`,
      stroke: this.strokeForBoardSelection(),
      preview
    }
  }

  strokeForBoardSelection() {
    switch (this.props.boardSelection) {
      case 'old':
        return '#09f'
      case 'original':
        return '#5573d4'
      default:
        return '#5573d4'
    }
  }

  preview() {
    const { downOn } = this.props
    const over = this.props.currentlyOver

    if (downOn == null) { return null }

    if ((downOn === over) || (over == null)) {
      return e(ReactCircle, this.circleProps(downOn, true))
    }
    return e(ReactArrow, this.arrowProps(downOn, over, true))
  }

  // Takes about 15 milliseconds to update.
  render() {
    if (!this.props.boardRef || !this.props.animationDone) { return null }
    return svg(
      {
        style: {
          position: 'absolute',
          height: this.props.boardRef.offsetHeight,
          width: this.props.boardRef.offsetWidth,
          top: this.props.boardRef.offsetTop,
          left: this.props.boardRef.offsetLeft,
          pointerEvents: 'none'
        },
        xmlns: 'http://www.w3.org/2000/svg',
        version: '1.1'
      },
      defs(
        {},
        e('marker',
          {
            id: 'arrowhead',
            orient: 'auto',
            markerWidth: '2',
            markerHeight: '2',
            refX: '0',
            refY: '1'
          },
          path({
            d: 'M0,0 V2 L2,1 Z',
            fill: this.strokeForBoardSelection()
          }),
        ),
      ),
      this.props.arrows.map((pdnArray) =>
        e(ReactArrow, this.arrowProps(pdnArray[0], pdnArray[1]))
      ),
      this.props.highlights.map((pdn) =>
        e(ReactCircle, this.circleProps(pdn))
      ),
      this.preview(),
    )
  }
}

export interface IArrowsProps {
  squareRefs: HTMLDivElement[]
  boardRef: HTMLDivElement | null
  boardSelection: string

  animationDone: boolean
  arrows: ReadonlyArray<ReadonlyArray<number>>
  highlights: number[]
  currentlyOver: number | null
  downOn: number | null
}

// Arrows.propTypes = {
//   // References the squares on which we will place the arrows
//   squareRefs: PropTypes.object.isRequired,
//   boardRef: PropTypes.object,
//   animationDone: PropTypes.bool.isRequired,

//   arrows: PropTypes.arrayOf(PropTypes.array).isRequired,
//   highlights: PropTypes.arrayOf(PropTypes.number).isRequired,
//   boardSelection: PropTypes.string.isRequired,
//   currentlyOver: PropTypes.number,
//   downOn: PropTypes.number
// }
