/* global gon Howl */
// gon is global from rails and Howl is created after requiring howler

import { Howl } from 'howler'
import { getCookie } from '../enhancements/utilities'
import { GonObject } from './types'

declare const gon: GonObject;

let volumeStr = getCookie('sound_volume')

let volume = parseInt(volumeStr, 10)
if (Number.isNaN(volume)) {
  volume = 80
}

volume /= 100.0 // cookie is 0-100, howler is 0.0-1.0

const moveSound = new Howl({
  src: [gon.move_sound_ogg_path, gon.move_sound_mp3_path],
  autoplay: false,
  preload: false,
  volume
})

const captureSound = new Howl({
  src: [gon.capture_sound_ogg_path, gon.capture_sound_mp3_path],
  autoplay: false,
  preload: false,
  volume
})

export function loadSounds(){
  if (moveSound.state() == 'unloaded'){
    moveSound.load()
  }
  if (captureSound.state() == 'unloaded'){
    captureSound.load()
  }
}


export function playMoveSound() {
  if ((moveSound) && (volume > 0)) {
    // console.log("play move sound!")
    moveSound.play()
  }
}

export function playCaptureSound() {
  if ((captureSound) && (volume > 0)) {
    // console.log("play capture sound!")
    captureSound.play()
  }
}

