
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'

import ProblemFeedback from '../components/problem_feedback'

import * as ExplorerActions from '../actions/explorerActions'
import * as ProblemActions from '../actions/problemActions'

import { ProblemInfoState } from '../reducers/problemInfoReducer'

import { isSignedIn } from '../../enhancements/utilities'
import { RootState } from '../reducers/root'

const signedIn = isSignedIn()

interface StateProps {
  signedIn: boolean
  problemInfo: ProblemInfoState
}

interface DispatchProps {
}

const ProblemFeedbackApp = {
  mapStateToProps(state: RootState): StateProps {
    return {
      signedIn,
      problemInfo: state.problemInfo,
    }
  },

  mapDispatchToProps(dispatch: Dispatch<RootState>): DispatchProps {
    // const actionCreators = Object.assign({}, ExplorerActions, ProblemActions)
    // return bindActionCreators(actionCreators, dispatch)
    return {}
  }
}

export default connect(
  ProblemFeedbackApp.mapStateToProps,
  ProblemFeedbackApp.mapDispatchToProps
)(ProblemFeedback as any)
