import * as React from 'react'
import { div } from 'react-dom-factories'

import SolutionApp from '../containers/solution_app'
import ProblemFeedbackApp from '../containers/problem_feedback_app'
import GameTimerApp from '../containers/game_timer_app'
import OpeningStatsApp from '../containers/opening_stats_app'
import PositionFenApp from '../containers/position_fen_app'
import LazyRenderApp from '../containers/lazy_render_app'

import * as $ from 'jquery'

const e = React.createElement;

class RightSideBar extends React.Component<IRightSideBarProps> {
  props!: IRightSideBarProps

  constructor(props: IRightSideBarProps) {
    super(props)
  }

  render() {
    return div({ className: 'side-bar' },
      e(LazyRenderApp, {},
        [
          e(ProblemFeedbackApp, {key: 'problem-timer'}),
          e(GameTimerApp, {key: 'game-timer'}),
          e(SolutionApp, {key: 'solution', boardRef: this.props.boardRef}),
          e(OpeningStatsApp, {key: 'stats'}),
          e(PositionFenApp, {key: 'fen'})
        ]
      )
    )
  }
}

interface IRightSideBarProps {
  boardRef: HTMLDivElement | null
}

export default RightSideBar
