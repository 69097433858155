
import { GonObject } from '../components/types'

declare const gon: GonObject;

function capitalize(arg: string) {
  return arg.charAt(0).toUpperCase() + arg.slice(1)
}

// Given an name of a field in the cookie text, return it's value
function getCookie(cname: string) {
  const name = `${cname}=`
  const ca = document.cookie.split(';')
  let i = 0
  while (i < ca.length) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
    i += 1
  }
  return ''
}

// Add fields, values, and expiry dates to the cookie text
function setCookie(cname: string, cvalue: string, exdays: number) {
  const d = new Date()
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
  const expires = `expires=${d.toUTCString()}`
  document.cookie = `${cname}=${cvalue}; ${expires}`
}

// Given an name of a field in the cookie text, return it's value
function isSignedIn() {
  // const signedInText = getCookie('signed_in')
  // Cookies here are bad because they don't sign out when passwords change
  return gon.isUserSignedIn
}

const isGoNative = () => navigator.userAgent.indexOf('gonative') > -1

export {
  isSignedIn,
  isGoNative,
  capitalize,
  getCookie,
  setCookie
}
