
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { ReactBoard, ReactBoardProps } from '../components/board'

import * as ExplorerActions from '../actions/explorerActions'
import * as UserActions from '../actions/userActions'
import * as ArrowActions from '../actions/arrowActions'
import * as BoardActions from '../actions/boardActions'

import { RootState } from '../reducers/root'
import { Dispatch } from 'redux'
import { Progression } from '../reducers/bootReducer'

// TODO Why is such explicit type information required here but not for
// pieces, or arrows, or other container components? Typing the constructor
// for the display seems critical, typing the connect always auto-solves it
// but must lose type information?
interface StateProps {
  whiteOnBottom: boolean
  bootProgress: Progression
}

interface OwnProps {
  pushSquareRef: (pdn: number, ref: HTMLDivElement) => void
  pushBoardRef: (ref: HTMLDivElement) => void
  boardSelection: string
  showBoardPerspective: boolean
  showNumberedNotation: boolean
  showAlgebraicNotation: boolean
  squareRefs: object
}

interface DispatchProps {
  // Injected by boardApp
  clickSquare: (pdn: number) => void
}

interface DispatchProps {
  clickSquare: (pdn: number) => void
  rightMouseDown: (pdn: number) => void
  rightMouseUp: (pdn: number) => void
  rightMouseEnter: (pdn: number) => void
  doneRenderBoard: () => void
}

const BoardApp = {
  mapStateToProps(state: RootState): StateProps {
    return {
      whiteOnBottom: state.board.whiteOnBottom,
      bootProgress: state.boot.progress
    }
  },

  mapDispatchToProps(dispatch: Dispatch<RootState>): DispatchProps {
    const actions = Object.assign({}, ExplorerActions, UserActions, ArrowActions, BoardActions)
    return bindActionCreators(actions, dispatch)
  }
}

export default connect(
  BoardApp.mapStateToProps,
  BoardApp.mapDispatchToProps,
)(ReactBoard as any)
