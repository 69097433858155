
/* global gon */
/* eslint no-bitwise: ["error", { "allow": ["^"] }] */

import * as _ from 'lodash'

import { TypeKeys, GonObject, GonAttemptInfo } from '../types'
import { ActionTypes } from '../actions'

declare const gon: GonObject

export interface ProblemInfoState {
  readonly problemId: number | null
  readonly problemRating: number
  readonly context_move: string

  readonly attempt?: GonAttemptInfo
  readonly playerWhite: boolean
  readonly solved: boolean
  readonly startTime: number
  readonly endTime: number
  readonly playerResults: TypeKeys.NONE |
    TypeKeys.PLAYER_LOSE |
    TypeKeys.PLAYER_ALT |
    TypeKeys.PLAYER_WIN
  readonly mistake: string
  readonly submitStatus: SubmitStatus
}

// Possible textStatus values from jquery ajax calls, plus 'started' and
// 'notstarted'
export enum SubmitStatus {
  NOTSTARTED = 'notstarted',
  STARTED = 'started',

  // These are ajax returns
  SUCCESS = 'success',
  NOTMODIFIED = 'notmodified',
  NOCONTENT = 'nocontent',
  ERROR = 'error',
  TIMEOUT = 'timeout',
  ABORT = 'abort',
  PARSERERROR = 'parsererror',
}

function initialState(): ProblemInfoState {
  if (gon.problem && gon.position) {
    // TODO: Remove 2nd decoding and put context move description in an action
    let playerWhite
    const decoded = _.map(gon.problem, ((char) => char.charCodeAt(0) ^ 67))
    const json = JSON.parse(String.fromCharCode.apply(null, decoded))

    if (json.context_move_description) {
      playerWhite = !gon.position.white_to_play
    } else {
      playerWhite = gon.position.white_to_play
    }

    return {
      problemId: json.id,
      problemRating: json.glicko_rating,
      context_move: json.context_move_description,

      attempt: gon.attempt,
      playerWhite,
      solved: false,
      startTime: 0,
      endTime: 0,
      playerResults: TypeKeys.NONE,
      mistake: '',
      submitStatus: SubmitStatus.NOTSTARTED
    }
  }
  return {
    problemId: null,
    problemRating: 0,
    context_move: '',
    playerWhite: false,
    solved: false,
    startTime: 0,
    endTime: 0,
    playerResults: TypeKeys.NONE,
    mistake: "",
    submitStatus: SubmitStatus.NOTSTARTED
  }
}

export function problemInfo(state: ProblemInfoState, action: ActionTypes): ProblemInfoState {
  if (!state) {
    return initialState()
  }

  switch (action.type) {
    case TypeKeys.PROBLEM_START:
      return Object.assign({}, state, {
        startTime: action.time
      })
    case TypeKeys.PLAYER_WIN:
      return Object.assign({}, state, {
        playerResults: TypeKeys.PLAYER_WIN,
        solved: true,
        endTime: action.time
      })
    case TypeKeys.PLAYER_LOSE:
      // attempt = state.attempt || {}
      return Object.assign({}, state, {
        playerResults: TypeKeys.PLAYER_LOSE,
        solved: true,
        endTime: action.time,
        mistake: action.mistake
      })
    case TypeKeys.PLAYER_ALT:
      return Object.assign({}, state, {
        playerResults: TypeKeys.PLAYER_ALT,
        mistake: action.mistake
      })
    case TypeKeys.SUBMIT_ATTEMPT:
      return Object.assign({}, state, {
        submitStatus: SubmitStatus.STARTED
      })
    case TypeKeys.RETURN_ATTEMPT_DATA:
      return Object.assign({}, state, {
        submitStatus: action.status,
        attempt: action.data
      })
    case TypeKeys.ATTEMPT_AJAX_ERROR:
      return Object.assign({}, state, {
        submitStatus: action.status
      })

    default:
      return state
  }
}
