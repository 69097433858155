/* eslint no-console: ["error", { allow: ["log", "warn", "error"] }] */

import { TypeKeys } from '../types'
import { Dispatch } from 'redux'
import { RootState } from '../reducers/root'
import * as ActionTypes from '../actions'

import * as _ from 'lodash'

// Action creators are functions that optionally take arguments and return
// plain old objects that are redux actions.

import { nextMoveIndex } from '../reducers/explorerFunctions'

function debugAction(msg: string) {
  // console.log(`explorerAction: ${msg}`)
}

export function initMoveList(moves: string[], onlyQuick: boolean): ActionTypes.InitFromMoveListAction {
  return {
    type: TypeKeys.INIT_MOVE_LIST,
    moves,
    onlyQuick
  }
}

export function initProblem(problem: string, onlyQuick: boolean): ActionTypes.InitFromProblemAction {
  return {
    type: TypeKeys.INIT_PROBLEM,
    problem,
    onlyQuick
  }
}

export function initFen(fen: string): ActionTypes.InitFromFenAction {
  return {
    type: TypeKeys.INIT_FEN,
    fen
  }
}

export function setCurrentMove(moveIndex: number, animateIndex = 0, autoPlay = false, asNavigation = false) {
  return (function dispatchSetCurrentMove(dispatch: Dispatch<RootState>,
    getState: () => RootState) {
    const { explorer } = getState()
    const nextMove = explorer.moves[moveIndex]

    let squares: number[]
    let isCapture
    if (nextMove.moveInfo) {
      squares = nextMove.moveInfo.touchedPdns()
      isCapture = !_.isEmpty(nextMove.moveInfo.capturedSquares)
    } else {
      squares = []
      isCapture = false
    }

    debugAction(`setCurrentMove from ${explorer.currentMove} to ${nextMove.id} \
with animate from ${animateIndex}`)

    dispatch({
      type: TypeKeys.SET_CURRENT_MOVE,
      moveId: nextMove.id,
      animateSquares: squares,
      animateIndex,
      animateCapture: isCapture,
      autoPlay,
      asNavigation
    } as ActionTypes.SetCurrentMoveAction)
  })
}

export function forward() {
  return function dispatchForward(dispatch: Dispatch<RootState>, getState: () => RootState) {
    const { explorer } = getState()
    const nextMove = nextMoveIndex(explorer)

    // console.log "forward from #{explorer.currentMove} to #{nextMove}"

    if (nextMove !== explorer.currentMove) {
      dispatch(setCurrentMove(nextMove, 0, false, true))
    }
  }
}

// TODO: Does this need to be different than regular forward? I think
// they should be combined.
export function autoForward() {
  return function dispatchAutoForward(dispatch: Dispatch<RootState>, getState: () => RootState) {
    const { explorer } = getState()
    const nextMove = nextMoveIndex(explorer)

    const shouldContinue = nextMove !== explorer.currentMove

    dispatch(setCurrentMove(nextMove, 0, shouldContinue))
  }
}

export function backward(): ActionTypes.GoBackwardAction {
  return {
    type: TypeKeys.BACKWARD
  }
}

export function beginning(): ActionTypes.GoToBeginningAction {
  return {
    type: TypeKeys.BEGINNING
  }
}

export function end(): ActionTypes.GoToEndAction {
  return {
    type: TypeKeys.END
  }
}

export function toggleAutoPlay(): ActionTypes.ToggleAutoPlayAction {
  return {
    type: TypeKeys.TOGGLE_AUTO_PLAY
  }
}
