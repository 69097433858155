
import { connect } from 'react-redux'

import { RootState } from '../reducers/root'
import { ProblemInfo } from '../components/problem_info'

interface StateProps {
  show: boolean
  problemId: number | null
  problemRating: number | null
}

const ProblemInfoApp = {
  mapStateToProps(state: RootState): StateProps {

    if(state.problemInfo.problemId == null){
      return {
        show: false,
        problemId: null,
        problemRating: null
      }
    }

    const show = state.problemInfo.solved
    return {
      problemId: state.problemInfo.problemId,
      problemRating: state.problemInfo.problemRating,
      show
    }
  },

  mapDispatchToProps() {
    return {}
  }
}

export default connect(
  ProblemInfoApp.mapStateToProps,
  ProblemInfoApp.mapDispatchToProps,
)(ProblemInfo as any)
