/* eslint no-console: off */

import * as _ from 'lodash'
import { Position } from './position'
import { Piece } from './piece'
import { Move } from './move'
import { theBoard } from './board'
import { Direction } from './direction'
import { Square } from './square'

// Handles all the rules of English Checkers, including taorced capture,
// promotion, and circular jumps. The primary interface returns all the legal
// moves for a piece or the whole position.
class Rules {
  // Return an array of all the legals moves for the position.
  static getAllLegalMoves(position: Position): Move[] {
    const jumps = this.getJumpMoves(position)
    const moves = this.getSimpleMoves(position)
    if (jumps.length > 0) {
      return jumps
    } else if (moves.length > 0) {
      return moves
    }
    return []
  }

  // Given a piece, returns an array of all the legal moves for that piece.
  static getLegalMoves(position: Position, piece: Piece): Move[] {
    const moves = this.getAllLegalMoves(position)
    if (moves) {
      return moves.filter((mv) => mv.startSquare.squareNum === piece.squareNum)
    }
    return []
  }

  static captureRequired(position: Position): boolean {
    const moves = this.getAllLegalMoves(position)
    return (moves.length > 0) && moves[0].isCapture()
  }

  // Doesn't enforce captures, or other rules, doesn't double check that
  // the rest of the moves in the game will be legal (excpept for multijumps).
  // Falls back on the main matching move for circular and other multijump moves.
  static fastMatchingMove(position: Position, moveStr: string, futureLegal: string[]): Move {

    if (moveStr === 'xxxx') {
      return Rules.matchingMove(position, moveStr, futureLegal)
    } else {
      const mentionedPDNstrs = moveStr.split(/-|x/).filter((str) => str.length !== 0)
      const mentionedPDNs = _.map(mentionedPDNstrs, (str) => parseInt(str, 10))

      let curSquare: Square, nextSquare: Square

      curSquare = theBoard.squareByPdn(mentionedPDNs[0])
      const move = new Move(curSquare)
      let multiJump = false

      mentionedPDNs.slice(1).forEach((pdn) => {
        nextSquare = theBoard.squareByPdn(pdn)
        if (curSquare.isAdjacent(nextSquare)) {
          move.addSimpleMove(position, curSquare.directionTo(nextSquare))
        }
        else if(curSquare.isJumpAway(nextSquare)) {
          try {
            move.addJumpMove(position, curSquare.directionTo(nextSquare))
          }
          catch(err) {
            multiJump = true
          }
        }
        else {
          multiJump = true
        }
        curSquare = nextSquare
      })
      if (multiJump) {
        return Rules.matchingMove(position, moveStr, futureLegal)
      } else{
        return move
      }
    }
}

  // Recurses with matchingFutureLegal to find one and only one move that
  // makes sense for the position, move string, and future PV.
  static matchingMove(position: Position, moveStr: string, futureLegal: string[]): Move {
    let matches: Move[]
    let mentionedPDNs: number[]
    const legal = this.getAllLegalMoves(position)

    // Circular Jump! I can't imagine a scenario where there are four legal
    if (moveStr === 'xxxx') {
      matches = this.matchCircularJump(position, legal)
      mentionedPDNs = [matches[0].startSquare.pdn()]
    } else {
      const mentionedPDNstrs = moveStr.split(/-|x/).filter((str) => str.length !== 0)
      mentionedPDNs = _.map(mentionedPDNstrs, (str) => parseInt(str, 10))
      matches = _.filter(legal, _.bind(((move) => {
        if ((move.startSquare.pdn() === _.first(mentionedPDNs)) &&
           (move.currentEndingSquare().pdn() === _.last(mentionedPDNs))) {
          return true
        }
        return false
      }), this))
    }
    if (matches.length > 1) {
      // console.log "Multiple matches: #{mentionedPDNs}"
      matches = _.filter(matches, _.bind(((move: Move) => {
        const landedSquares = _.map(move.landedSquares, (sq) => sq.pdn())
        let hasAll = true
        for (let i = 1; i < mentionedPDNs.length; i += 1) {
          // console.log "#{landedSquares} includes? #{mentionedPDNs[1]}"
          if (!_.includes(landedSquares, mentionedPDNs[i])) {
            hasAll = false
          }
        }
        return hasAll
      }), this))
    }
    // Matches has changed, so no else if.
    if (matches.length === 0) {
      const error = `${moveStr} not found among ${legal}`
      // console.log error
      throw new Error(error)
    } else if (matches.length > 1) {
      // console.log "decide #{moveStr} among #{matches} based on #{futureLegal}"
      return this.matchingFutureLegal(position, matches, futureLegal)
    } else {
      return matches[0]
    }
  }

  static matchCircularJump(position: Position, legal: Move[]): Move[] {
    const matches = legal.filter(((move) => {
      const circular = _.last(move.landedSquares) === move.startSquare
      return circular
    }), this)
    return matches
  }

  // Recurses with matchingMove
  static matchingFutureLegal(position: Position, matches: Move[], futureLegal: string[]) {
    for (let i = 0; i < matches.length; i += 1) {
      const match = matches[i]
      let nextPosition = position.nextPosition(match)

      try {
        for (let j = 0; j < futureLegal.length; j += 1) {
          const nextMove = futureLegal[j]
          const nextFuture = futureLegal.slice(j + 1)
          const futureMatch = this.matchingMove(nextPosition, nextMove, nextFuture)
          nextPosition = nextPosition.nextPosition(futureMatch)
        }

        // console.log "#{match} fits"
        return match
      } catch (e) {
        if (!e.message.match(/not found among/)) {
          throw e
        }
      }
    }
    // Else do nothing, we caught it and we'll try the next match

    // We tried all the matches, none let us parse the whole string
    const error = `${matches} all fail to allow ${futureLegal}`
    // console.log error
    throw new Error(error)
  }

  // An alternative to matching move, that returns potentially multiple matches
  // in an array and hopefully has simpler semantics for the user interface.
  static allMatchingMoves(position: Position, mentionedPDNs: ReadonlyArray<number>) {
    const legal = this.getAllLegalMoves(position)
    // mentionedPDNs = move_str.split(/-|x/).filter((str) -> str.length != 0)
    // mentionedPDNs = _.map(mentionedPDNs, (str) -> return parseInt(str))

    if (mentionedPDNs.length === 0) {
      return legal
    }

    let matches = legal.filter((move) =>
      this.moveHasMentionedSquares(move, mentionedPDNs)
    )
    // console.log "#{matches.length}: matches. #{matches}"
    matches = this.filterMovesByFirstSquare(matches, mentionedPDNs)
    // console.log "#{matches.length}: survive filter. #{matches}"
    return matches
  }

  // Takes an engine move and a list of pdns and checks if the engine move
  // starts and lands on all those pdns in the correct order.
  static moveHasMentionedSquares(move: Move, mentionedPDNs: ReadonlyArray<number>) {
    const movePDNs = move.touchedPdns()
    let moveIndex = 0
    let mentionedIndex = 0

    // Start assuming we have all of them and prove wrong by find one counter.
    let hasAllMentions = true
    while ((mentionedIndex < mentionedPDNs.length) && hasAllMentions) {
      const currentMention = mentionedPDNs[mentionedIndex]

      // Start assuming don't have one and prove wrong by finding it.
      let hasCurrentMention = false
      while ((moveIndex < movePDNs.length) && !hasCurrentMention) {
        if (movePDNs[moveIndex] === currentMention) {
          hasCurrentMention = true
        } else {
          moveIndex += 1
        }
      }

      if (!hasCurrentMention) {
        hasAllMentions = false
      } else {
        mentionedIndex += 1
      }
    }

    return hasAllMentions
  }

  // For handling ambiguous moves, select the move for which the
  // mentioned PDNs occur the earliest in the move.
  static filterMovesByFirstSquare(moves: Move[], mentionedPDNs: ReadonlyArray<number>) {
    if ((moves.length <= 1) || (mentionedPDNs.length <= 1)) {
      return moves
    }

    let filteredMoves = moves

    mentionedPDNs.forEach((function filterMoveByEachMentionedPdn(mentioned, mentionedIndex) {
      const earliestAllowed = mentionedIndex

      const whenFound = filteredMoves.map((move) =>
        move.touchedPdns().indexOf(mentioned, earliestAllowed))

      const earliestFound = _.min(whenFound)

      filteredMoves = filteredMoves.filter((move, moveIndex) =>
        whenFound[moveIndex] === earliestFound)
    }), this)

    return filteredMoves
  }

  static moveGivingResultingPosition(firstPosition: Position, resultingPosition: Position) {
    const legal = this.getAllLegalMoves(firstPosition)

    for (let i = 0; i < legal.length; i += 1) {
      const move = legal[i]
      const candidate = firstPosition.nextPosition(move)
      if (candidate.toConsole() === resultingPosition.toConsole()) {
        return move
      }
    }

    const error = `nothing among ${legal} \
leads from ${firstPosition.toConsole()} \
to ${resultingPosition.toConsole()}`
    throw new Error(error)
  }

  // Returns an array of all the non-capturing moves for the position.
  static getSimpleMoves(position: Position) {
    const moves:Move[] = []
    const pieces = position.getPieces({ player: position.playerToMove() })

    pieces.forEach((piece) => {
      const directions = this.getDirections(piece)

      directions.forEach((dir) => {
        const destination = theBoard.adjacentSquare(piece.square(), dir)
        const destinationEmpty = position.squareEmpty(destination)
        if (destinationEmpty) {
          const mv = new Move(piece.square())
          mv.addSimpleMove(position, dir)
          moves.push(mv)
        }
      })
    })
    return moves
  }

  // Returns an array of all the capturing moves available in the position.
  static getJumpMoves(position: Position) {
    const moves:Move[] = []
    const pieces = position.getPieces({ player: position.playerToMove() })

    pieces.forEach((piece) => {
      const partialMove = new Move(piece.square())
      const pieceJumps = this.buildJumps(position, partialMove)

      pieceJumps.forEach((jump) => {
        moves.push(jump)
      })
    })
    return moves
  }

  // Takes a partial move, returns an array of complete moves.
  static buildJumps(position: Position, partialMove: Move) {
    const additionalJumpDirections = this.getPossibleAdditionalJumps(
      position,
      partialMove,
    )

    if (additionalJumpDirections.length === 0) {
      if (partialMove.landedSquares.length === 0) {
        return [] // No jumps available for this piece.
      }
      return [partialMove] // partialMove is now complete!
    }
    let returnedMoves:Move[] = []

    additionalJumpDirections.forEach((dir) => {
      // We need to copy the partial move because we might create 2 partials.
      const piece = position.getPiece(partialMove.startSquare)
      if (!piece) {
        throw new Error("Trying to jump with null piece")
      }
      const newMove = partialMove.clone()
      newMove.addJumpMove(position, dir)

      returnedMoves = returnedMoves.concat(this.buildJumps(position, newMove))
    })
    return returnedMoves
  }

  // returns an array of directions for possible additional jumps.
  static getPossibleAdditionalJumps(position: Position, partialMove: Move) {
    const possibilities:Direction[] = [] // an array of jumps possible from current position
    const piece = position.getPiece(partialMove.startSquare)
    const directions = this.getDirections(piece)

    directions.forEach((dir) => {
      if (this.isValidAdditionalJump(position, partialMove, dir)) {
        possibilities.push(dir)
      }
    })
    return possibilities
  }

  // Checks whether the given direction is a possible additional jump from the
  // end of the given move.
  static isValidAdditionalJump(position: Position, partialMove: Move, direction: Direction) {
    const currentSquare = partialMove.currentEndingSquare()
    const jumpedSquare = theBoard.adjacentSquare(currentSquare, direction)
    const landedSquare = theBoard.jumpSquare(currentSquare, direction)

    const playerPiece = position.getPiece(partialMove.startSquare)
    const jumpedPiece = position.getPiece(jumpedSquare)

    if (!playerPiece) {
      throw new Error("Trying to jump with null piece")
    }

    const jumpedValid = jumpedPiece &&
      jumpedPiece.isEnemy(playerPiece) &&
      !partialMove.capturedPreviously(jumpedSquare)

    if (!jumpedValid) { return false }

    const landedPiece = position.getPiece(landedSquare)
    const landedEmpty = position.squareEmpty(landedSquare) // Checks for borders too
    const landedValid = landedSquare && (landedEmpty || (landedPiece === playerPiece))

    if (!landedValid) { return false }
    return true
  }

  // Returns the list of directions available to piece based on the pieces
  // color and king status.
  static getDirections(piece: Piece | null) {
    if (!piece) {
      return []
    }
    const directions = [piece.player.left(), piece.player.right()]
    if (piece.isKing) {
      directions.push(piece.player.left({ backwards: true }))
      directions.push(piece.player.right({ backwards: true }))
    }
    return directions
  }
}

export { Rules }

