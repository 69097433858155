/* eslint "react/no-did-update-set-state": "off" */

import * as React from 'react'
import * as PropTypes from 'prop-types'

import { div } from 'react-dom-factories'
import { onNextFrame } from '../../components/utilities'

import * as _ from 'lodash'

export class ReactSquare extends React.Component<ReactSquareProps, ReactSquareState> {
  props!: ReactSquareProps
  state: ReactSquareState
  // TODO: This bang skips typescript's check. Better would be to
  // use reacts callback style refs instead of strings.
  refs!: {
    square: HTMLDivElement
  }

  constructor(props: ReactSquareProps) {
    super(props)
    this.state = { }
  }

  squareDiv(pdn: number | null) {
    let className = 'board-square'

    if (!pdn) {
      return div({ className })
    }

    if (pdn === this.props.selectedSquare) {
      className += ' square-touched'
    } else if (_.includes(this.props.mustMoveSquares, pdn)) {
      className += ' must-move'
    } else if (_.includes(this.props.lastMoveSquares, pdn)) {
      className += ' last-move'
    }

    if (this.props.isOver) {
      className += ' hover-over'
    }

    if (this.props.showNumberedNotation) {
      return div(
        { id: `s_${pdn}`, className, ref: 'square' },
        div(
          { className: 'square-label' },
          pdn,
        ),
      )
    }
    return div({ id: `s_${pdn}`, className, ref: 'square' })
  }

  componentDidMount() {
    if (this.props.pdn != null) {
      // console.log(`pushing ${this.props.pdn}`)
      this.props.pushSquareRef(this.props.pdn, this.refs.square)
    }
  }

  // ComponentDidUpdate has caused problems. It was needed so that the pieces
  // move with a flipped board, but it interfered in touch drag and drop.
  componentDidUpdate(prevProps: ReactSquareProps) {
    if ((this.props.pdn != null) && (this.props.whiteOnBottom !== prevProps.whiteOnBottom)) {
      onNextFrame(() => {
        this.props.pushSquareRef(this.props.pdn!, this.refs.square)
      })
    }
  }

  render() {
    return this.squareDiv(this.props.pdn)
  }
}

export interface ReactSquareProps {
  pdn: number | null
  row: number
  col: number
  mustMoveSquares: number[]
  lastMoveSquares: number[]
  selectedSquare: number
  isOver?: boolean
  // So we know when to update.
  whiteOnBottom: boolean

  showNumberedNotation: boolean
  pushSquareRef: (pdn: number, ref: HTMLDivElement) => void
}

interface ReactSquareState {
}
