import * as jQuery from 'jquery'

function setPieceOnSquare($: JQueryStatic, pieceId: string, squareId: string) {
  const piece = $(`#${pieceId}`)
  const square = $(`#${squareId}`)

  let height = square.height() // can be undefined
  if(!height) {
    height = 0
  }

  piece.css('position', 'absolute')
  piece.css('top', square.position().top)
  piece.css('left', square.position().left)
  piece.css('height', height)
  piece.css('width', height)
}

jQuery(document).ready(($) => {
  // Remove empty fields from GET forms
  // Author: Bill Erickson
  // URL: http://www.billerickson.net/code/hide-empty-fields-get-form/
  const preview = document.querySelector('#board-preview')
  // console.log 'ready'

  if (preview) {
    // console.log 'preview'
    setPieceOnSquare($, 'p1', 'a1')
    setPieceOnSquare($, 'p2', 'a3')
    setPieceOnSquare($, 'p3', 'c1')
    setPieceOnSquare($, 'p4', 'c3')

    const boardSelection = $('#board-selection') as JQuery<HTMLInputElement>
    boardSelection.data('prev', boardSelection.val())
    boardSelection.on('change', function onChangeBoardSelection() {
      const jqThis = $(this)

      const oldValue = jqThis.data('prev').toLowerCase()
      const newValue = this.value.toLowerCase()

      // console.log "changing board selection from #{oldValue} to #{newValue}"

      const squares = $('.square-container')
      squares.removeClass(oldValue)
      squares.addClass(newValue)

      jqThis.data('prev', newValue)
    })

    const pieceSelection = $('#piece-selection') as JQuery<HTMLInputElement>
    pieceSelection.data('prev', pieceSelection.val())
    pieceSelection.on('change', function onChangePieceSelection() {
      const jqThis = $(this)

      const oldValue = jqThis.data('prev').toLowerCase()
      const newValue = this.value.toLowerCase()

      // console.log "changing piece selection from #{oldValue} to #{newValue}"

      const pieces = $('.pieces .black,.white')
      pieces.removeClass(oldValue)
      pieces.addClass(newValue)

      jqThis.data('prev', newValue)
    })

    boardSelection.trigger('change')
    pieceSelection.trigger('change')
  }
})
