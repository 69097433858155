/* global $ */ // Need global jquery because of authenticity token
/* eslint no-console: ["error", { allow: ["log", "warn", "error"] }] */

import * as React from 'react'
import * as PropTypes from 'prop-types'

import { div, button, form, textarea } from 'react-dom-factories'

class NewCommentForm extends React.Component<NewCommentFormProps, NewCommentFormState> {
  props!: NewCommentFormProps
  state: NewCommentFormState
  // TODO: This bang skips typescript's check. Better would be to
  // use reacts callback style refs instead of strings.
  refs!: {
    body: HTMLTextAreaElement
  }

  constructor(props: NewCommentFormProps) {
    super(props)
    this.state = {
      open: false,
      text: ''
    }
  }

  handleSubmit(event: React.FormEvent<HTMLInputElement>) {
    event.preventDefault()
    const body = this.refs.body.value.trim()
    if (!body) { return }

    $.ajax({
      url: `/problems/${this.props.problemId}/comments`,
      type: 'POST',
      data: { body },
      error: ((jqXHR, textStatus, errorThrown) => {
        console.log(`new comment ajax error: ${textStatus}`)
        console.log(jqXHR)
        console.log(errorThrown)
      }),
      success: (() => {
        // Gets a data parameter
        this.props.refresh()
        this.refs.body.value = ''
        this.closeNewForm(null)
      })
    })
  }

  openNewForm(event: React.FormEvent<HTMLInputElement>) {
    event.preventDefault()
    this.setState({
      open: true
    })
  }

  closeNewForm(event: React.FormEvent<HTMLInputElement> | null) {
    if (event) {
      event.preventDefault()
    }

    let text = ''
    if (this.refs.body) {
      text = this.refs.body.value.trim()
    }
    this.setState({
      open: false,
      text
    })
  }


  render() {
    if (this.state.open) {
      return form(
        { className: 'form-horizontal', onSubmit: this.handleSubmit.bind(this) },
        div(
          { className: 'form-group' },
          div(
            { className: 'col-md-12' },
            textarea({
              // type: 'textarea',
              className: 'form-control',
              id: 'body',
              ref: 'body',
              placeholder: 'Ask a question or add a comment about this ' +
                           'problem to help the next puzzler.',
              defaultValue: this.state.text,
              rows: 6,
              style: { resize: 'vertical' },
              autoFocus: true,
              maxLength: 500
            }),
            div(
              { className: 'btn-toolbar' },
              button(
                {
                  type: 'submit',
                  className: 'btn btn-primary'
                },
                'Post',
              ),
              ' ', // space between buttons
              button(
                {
                  className: 'btn btn-warning',
                  onClick: this.closeNewForm.bind(this)
                },
                'Cancel',
              ),
            ),
          ),
        ),
      )
    }
    return button(
      {
        className: 'btn btn-default',
        onClick: this.openNewForm.bind(this)
      },
      'Add a comment',
    )
  }
}

interface NewCommentFormProps {
  problemId: number
  refresh: () => void
}

interface NewCommentFormState {
  open: boolean
  text: string
}

export default NewCommentForm

