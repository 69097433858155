import * as _ from 'lodash'
import { Position } from '../../engine/position'
import { Move } from '../../engine/move'

import { ExplorerState } from '../reducers/explorerReducer'

export function currentMove(state: ExplorerState){
  return state.moves[state.currentMove]
}

export function nextMoveIndex(state: ExplorerState) {
  let nextMove
  const mainLineIndex = _.indexOf(state.mainLine, state.currentMove)
  // On main line
  if (mainLineIndex !== -1) {
    if (mainLineIndex < (state.mainLine.length - 1)) {
      nextMove = state.mainLine[mainLineIndex + 1]
    } else {
      nextMove = state.currentMove
    }
  } else { // Not on main line
    const nextMoves = state.moves.filter((mv) => mv.parent === state.currentMove)
    if (nextMoves.length === 0) {
      nextMove = state.currentMove
    } else {
      nextMove = nextMoves[0].id
    }
  }
  // Have determined next move
  return nextMove
}

export function prevMoveIndex(state: ExplorerState) {
  let nextMove
  const mainLineIndex = _.indexOf(state.mainLine, state.currentMove)
  // On main line
  if (mainLineIndex !== -1) {
    if (mainLineIndex > 0) {
      nextMove = state.mainLine[mainLineIndex - 1]
    } else {
      nextMove = 0
    }
  } else { // Not on main line
    const { parent } = state.moves[state.currentMove]
    if (parent) {
      nextMove = parent
    } else {
      nextMove = 0
    }
  }
  // Have determined next move
  return nextMove
}

export function currentPosition(state: ExplorerState) {
  return state.positions[currentMove(state).postPos]
}

export function squaresInCommonOfAmbiguousMoves(moves: Move[]) {
  const masterSquares = moves[0].touchedPdns()

  let numSquareInCommon = 1000
  // Start at one because we don't compare to ourselves
  _.drop(moves).forEach((slave) => {
    const slaveSquares = slave.touchedPdns()
    // Start at one because we assume ambiguous moves share a piece.
    for (let i = 1; i < masterSquares.length; i += 1) {
      if (masterSquares[i] !== slaveSquares[i]) {
        if (i < numSquareInCommon) {
          numSquareInCommon = i
        }
      }
    }
  })

  return masterSquares.slice(0, numSquareInCommon)
}

export function nextChoicesForAmbiguousMoves(moves: Move[]) {
  const squaresInCommon = squaresInCommonOfAmbiguousMoves(moves)

  const pdns = moves.map((square) => square.touchedPdns()[squaresInCommon.length])

  return _.uniq(pdns)
}

export function moveSiblingIndices(state: ExplorerState, moveIndex: number): number[] {
  const parentId = state.moves[moveIndex].parent

  const children = state.moves.filter((mv) => mv.parent === parentId)

  const siblings = children.filter((mv) => mv.id !== moveIndex)

  const ids = siblings.map((mv) => mv.id)

  return ids
}

// Returns an array of child ids.
export function moveChildrenIndices(state: ExplorerState, moveIndex: number) {
  const children = state.moves.filter((mv) => mv.parent === moveIndex)
  return children.map((mv) => mv.id)
}

export function moveDescendentIndicies(state: ExplorerState, moveIndex: number) {
  const children = moveChildrenIndices(state, moveIndex)

  let total = [moveIndex] // No children base case, always return an array.

  children.forEach((childIndex) => {
    const descendents = moveDescendentIndicies(state, childIndex)
    total = _.concat(total, descendents)
  })

  return total
}

