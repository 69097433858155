const _ = require('lodash')

export function resizeToBoard(selectorRef: HTMLDivElement, boardRef: HTMLDivElement): void {
  const boardTop = boardRef.getBoundingClientRect().top + window.pageYOffset
  const boardBottom = boardRef.offsetHeight + boardTop

  if (!(typeof _.isEmpty === 'function' ? _.isEmpty(selectorRef) : undefined)) {
    let desiredHeight
    const selectorTop = selectorRef.getBoundingClientRect().top + window.pageYOffset

    // Only limit it the height when rendered next to the board
    if (selectorTop < boardBottom) {
      desiredHeight = boardBottom - selectorTop
    } else {
      desiredHeight = boardBottom - boardTop
    }

    const currentHeight = selectorRef.offsetHeight
    if (currentHeight !== desiredHeight) {
      selectorRef.style.height = `${desiredHeight}px`
    }
  }
}

// ComponentDidUpdate is called after the componet is rendered to the virtual
// dom and that dom has been sent to the browser. *However* the browser
// has not yet necessarily painted and reflowed. This function waits a couple
// fromes to ensure react is done before triggering the callback.
// See: https://stackoverflow.com/questions/26556436/react-after-render-code
export function onNextFrame(callback: () => void) {
  return window.setTimeout(function () {
    window.requestAnimationFrame(callback)
  }, 0)
}
