
import * as React from 'react'
import * as PropTypes from 'prop-types'

import { line } from 'react-dom-factories'

// Read this.
// https://davetayls.me/blog/2017/06/12/the-power-of-typescript-for-react

class ReactArrow extends React.PureComponent<ArrowProps> {
  props!: ArrowProps

  constructor(props: ArrowProps){
    super(props)
  }

  render() {
    // from = "#{this.props.fromLocation.top}, #{this.props.fromLocation.left}"
    // to = "#{this.props.toLocation.top}, #{this.props.toLocation.left}"
    // console.log "from: #{from} to: #{to}"

    return line({
      // x1: '0'
      // y1: '0'
      // x2: '100'
      // y2: '100'
      x1: this.props.fromLocation.left,
      y1: this.props.fromLocation.top,
      x2: this.props.toLocation.left,
      y2: this.props.toLocation.top,
      stroke: this.props.stroke,
      strokeWidth: this.props.preview ? '10' : '15',
      strokeLinecap: 'round',
      markerEnd: 'url(#arrowhead)',
      opacity: 0.8
    })
  }
}

interface ArrowProps {
  fromLocation: {
    left: number
    top: number
  }
  toLocation: {
    left: number
    top: number
  }
  size: number
  stroke: string
  preview: boolean
}

// ReactArrow.propTypes = {
//   fromLocation: PropTypes.shape({
//     top: PropTypes.number,
//     left: PropTypes.number
//   }).isRequired,
//   toLocation: PropTypes.shape({
//     top: PropTypes.number,
//     left: PropTypes.number
//   }).isRequired,
//   size: PropTypes.number.isRequired,
//   stroke: PropTypes.string.isRequired,
//   preview: PropTypes.bool.isRequired
// }

export { ReactArrow }
