// This hooks up a square to be a drop target. It renders a simple square below
// it in the hierarchy and gets redux actions from squareApp

import * as React from 'react'
import * as PropTypes from 'prop-types'

import * as ReactDnd from 'react-dnd'

import { ReactSquare as Square, ReactSquareProps } from '../components/square'

import * as _ from 'lodash'

import { div } from 'react-dom-factories'

import { TypeKeys } from '../types'

const e = React.createElement;

const squareTarget: ReactDnd.DropTargetSpec<DroppableSquareProps> = {
  // The return value of drop is accessbile by endDrag in the source monitor
  // Not guarenteed to be called where endDrag is so endDrag is a better place
  // to fire redux actions.
  drop(props: DroppableSquareProps) {
    // Potentially takes two other arguments, monitor, and component.
    return props
  },

  // hover: (props, monitor, component) ->
  canDrop(props: DroppableSquareProps) {
    // Potentially takes another arguments, monitor
    return (props.pdn != null)
  }
}

interface DndProps {
  isOver: boolean
  connectDropTarget: ReactDnd.ConnectDropTarget
}

const squareCollect = (connect: ReactDnd.DropTargetConnector,
  monitor: ReactDnd.DropTargetMonitor): DndProps =>
  ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver({ shallow: false })
  })

const squareOptions = {
  arePropsEqual(props: DroppableSquareProps, otherProps: DroppableSquareProps) {
    const wasLastMove = _.includes(props.lastMoveSquares, props.pdn)
    const nowLastMove = _.includes(otherProps.lastMoveSquares, otherProps.pdn)

    const wasMissed = _.includes(props.mustMoveSquares, props.pdn)
    const nowMissed = _.includes(otherProps.mustMoveSquares, otherProps.pdn)

    if ((wasLastMove !== nowLastMove) ||
       (wasMissed !== nowMissed) ||
       (props.pdn !== otherProps.pdn) ||
       (props.whiteOnBottom !== otherProps.whiteOnBottom) ||
       (props.selectedSquare !== otherProps.selectedSquare)) {
      // props.squareRefs != otherProps.squareRefs
      return false
    }
    return true
  }
}

class DroppableSquare extends React.Component<DroppableSquareProps> {
  props!: DroppableSquareProps

  constructor(props: DroppableSquareProps) {
    super(props)
  }

  shouldComponentUpdate(nextProps: DroppableSquareProps) {
    const wasLastMove = _.includes(this.props.lastMoveSquares, this.props.pdn)
    const nowLastMove = _.includes(nextProps.lastMoveSquares, nextProps.pdn)

    const wasMissed = _.includes(this.props.mustMoveSquares, this.props.pdn)
    const nowMissed = _.includes(nextProps.mustMoveSquares, nextProps.pdn)

    if ((wasLastMove !== nowLastMove) ||
       (wasMissed !== nowMissed) ||
       (this.props.pdn !== nextProps.pdn) ||
       (this.props.whiteOnBottom !== nextProps.whiteOnBottom) ||
       (this.props.isOver !== nextProps.isOver) ||
       (this.props.selectedSquare !== nextProps.selectedSquare)) {
      // this.props.squareRefs != nextProps.squareRefs
      return true
    }
    return false
  }


  colLetter() {
    return ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h'][this.props.col]
  }

  render() {
    const { connectDropTarget } = this.props

    const squareProps = {
      lastMoveSquares: this.props.lastMoveSquares,
      selectedSquare: this.props.selectedSquare,
      mustMoveSquares: this.props.mustMoveSquares,
      pdn: this.props.pdn,
      row: this.props.row,
      col: this.props.col,
      pushSquareRef: this.props.pushSquareRef,
      isOver: this.props.isOver,
      whiteOnBottom: this.props.whiteOnBottom,
      showNumberedNotation: this.props.showNumberedNotation
    }

    const squareColor = this.props.pdn ? 'dark' : 'light'
    const squareId = `${this.colLetter()}${this.props.row + 1}`

    if(connectDropTarget) {
      return connectDropTarget(div(
        {
          className: `square-container ${this.props.boardSelection} ${squareColor}`,
          id: squareId
        },
        e(Square, squareProps)
      ))
    } else {
      return null
    }
  }
}

export interface DroppableSquareProps {
  // Injected by react-dnd at the bottom
  connectDropTarget?: ReactDnd.ConnectDropTarget
  isOver?: boolean

  // Needed just so we can update correctly
  whiteOnBottom: boolean

  // Needed by by the square component
  pdn: number | null
  lastMoveSquares: number[]
  mustMoveSquares: number[]
  selectedSquare: number
  boardSelection: string
  showNumberedNotation: boolean
  row: number
  col: number

  pushSquareRef: (pdn: number, ref: HTMLDivElement) => void
}

export default ReactDnd.DropTarget(
  TypeKeys.PIECE, squareTarget,
  squareCollect, squareOptions,
)(DroppableSquare)
