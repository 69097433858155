
import { connect } from 'react-redux'

import OpeningStats from '../components/opening_stats'
import { RootState } from '../reducers/root'

const OpeningStatsApp = {
  mapStateToProps(state: RootState) {
    const show = (state.problemInfo.problemId == null)
    return {
      explorer: state.explorer,
      show
    }
  },

  mapDispatchToProps() {
    return {}
  }
}

export default connect(
  OpeningStatsApp.mapStateToProps,
  OpeningStatsApp.mapDispatchToProps,
)(OpeningStats as any)
