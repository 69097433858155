import * as React from 'react'
import { div } from 'react-dom-factories'

import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'

import 'browsernizr/test/touchevents'
import Modernizr from 'browsernizr'

import { DndProvider } from 'react-dnd'

import ArrowApp from '../containers/arrows_app'
import BoardApp from '../containers/boardApp'
import NavApp from '../containers/navigation_app'
import PiecesApp from '../containers/pieces_app'
import DragLayer from '../components/drag_layer'

import { getCookie } from '../../enhancements/utilities'

import * as _ from "lodash"

const pieceSelection = getCookie('piece_selection').toLowerCase() || 'original'
const boardSelection = getCookie('board_selection').toLowerCase() || 'vinyl'

const showNumberedNotationStr =
  getCookie('show_numbered_notation').toLowerCase() || 'true'
const showAlgebraicNotationStr =
  getCookie('show_algebraic_notation').toLowerCase() || 'true'
const showBoardPerspectiveStr =
  getCookie('show_board_perspective').toLowerCase() || 'true'

const showNumberedNotation = showNumberedNotationStr === 'true'
const showAlgebraicNotation = showAlgebraicNotationStr === 'true'
const showBoardPerspective = showBoardPerspectiveStr === 'true'

const e = React.createElement;

class CenterBoard extends React.Component<ICenterProps> {
  props!: ICenterProps

  constructor(props: ICenterProps) {
    super(props)
  }

  render() {
    let backend: any = HTML5Backend
    if (Modernizr.touchevents && getCookie('board_drag') !== 'mouse') {
      backend = TouchBackend
    }

    // Object liters can't define unknown props, and for some reason
    // typescript cannot figure out that boardSelection and pieceSelection
    // are neccessary.
    const boardProps = {
      boardSelection,
      showBoardPerspective,
      showNumberedNotation,
      showAlgebraicNotation,
      squareRefs: this.props.squareRefs,
      pushSquareRef: this.props.pushSquareRef,
      pushBoardRef: this.props.pushBoardRef
    }
    const pieceProps = {
      pieceSelection,
      squareRefs: this.props.squareRefs,
      time: Date.now()
    }

    return e(DndProvider,
      { backend },
      div(
        { className: "board-area" },
        e(DragLayer, {}),
        e(BoardApp, boardProps),
        e(NavApp, {}),
        e(PiecesApp, pieceProps),
        e(ArrowApp, {
          boardSelection,
          squareRefs: this.props.squareRefs,
          boardRef: this.props.boardRef
        })
      )
    )
  }
}

interface ICenterProps {
  squareRefs: HTMLDivElement[]
  boardRef: HTMLDivElement | null
  pushSquareRef: (pdn: number, ref: HTMLDivElement) => void
  pushBoardRef: (ref: HTMLDivElement) => void
}

// if getCookie may be 'touch' or '' and touchevents are available, use touch.
// let context
// if (Modernizr.touchevents && (getCookie('board_drag') !== 'mouse')) {
//   context = DragDropContext(TouchBackend)(Root)
// } else {
//   context = DragDropContext(HTML5Backend)(Root)
// }

export default CenterBoard
