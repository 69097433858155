/* eslint no-alert: 'off' */

import { currentPosition } from '../reducers/explorerFunctions'

import * as React from 'react'
import * as PropTypes from 'prop-types'

import { div, input, form } from 'react-dom-factories'
import { ExplorerState } from '../reducers/explorerReducer'

class PositionFen extends React.Component<IPositionFenProps, IPositionFenState> {
  props!: IPositionFenProps
  state: IPositionFenState

  constructor(props: IPositionFenProps) {
    super(props)
    this.state = {
      value: ''
    }
  }

  handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      value: event.target.value
    })
  }

  handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault() // Don't submit to the server
    this.props.initFen(this.state.value)
  }

  // Is passed an event if needed.
  handleCopy() {
    const text = currentPosition(this.props.explorer).toFenDescription()
    window.prompt('Copy to clipboard: Ctrl+C, Enter', text)
  }

  render() {
    if (this.props.showInput || this.props.showOutput) {
      return div(
        {},
        this.props.showOutput ?
          input({
            readOnly: true,
            name: 'copy',
            value: 'copy FEN to clipboard',
            className: 'btn btn-info',
            onClick: this.handleCopy.bind(this)
          }) : undefined,
        this.props.showInput ?
          form(
            { className: 'form-group', onSubmit: this.handleSubmit.bind(this) },
            input({
              type: 'text',
              value: this.state.value,
              placeholder: 'FEN String',
              id: 'position-fen',
              className: 'form-control',
              onChange: this.handleChange.bind(this)
            }),
            input({
              type: 'submit',
              name: 'button',
              value: 'Go',
              className: 'btn btn-default'
            }),
          ) : undefined,
      )
    }
    return null
  }
}

interface IPositionFenProps {
  explorer: ExplorerState
  showInput: boolean
  showOutput: boolean
  initFen: (fen: string) => void
}

interface IPositionFenState {
  value: string
}

export default PositionFen
