
/* eslint no-bitwise: ["error", { "allow": ["^"] }] */
/* eslint no-console: ["error", { allow: ["log", "warn", "error"] }] */

/* global $ */
// Need global jquery  because rails modifies it for authenticity token.
import * as _ from 'lodash'

import { TypeKeys, GonAttemptInfo } from '../types'
import * as ActionTypes from '../actions'

import {
  nextMoveIndex, prevMoveIndex, currentMove
} from '../reducers/explorerFunctions'

import { forward } from './explorerActions'
import { SubmitStatus } from '../reducers/problemInfoReducer'

import { Dispatch } from 'redux'
import { RootState } from '../reducers/root'

// The animation timeout variable to cancel when we queue the next animation.
// Maybe this should be in the store?
let timer = 0

function debugAction(msg: string) {
  // console.log(`Check: ${msg}`)
}

function ajaxObject(attemptId: number, data: string, dispatch: Dispatch<RootState>) {
  return {
    url: `/attempts/${attemptId}`,
    type: 'PUT',
    dataType: 'JSON', // The requested format for the response
    data: {
      data
    },
    error(jsqXRH: JQuery.jqXHR, textStatus: string, errorThrown: string) {
      attemptAjaxError(errorThrown, textStatus as SubmitStatus, dispatch)
    },
    success(successData: GonAttemptInfo , textStatus: string) {
      returnAttemptData(successData, textStatus as SubmitStatus, dispatch)
    }
  }
}

// Action creators are functions that optionally take arguments and return
// plain old objects that are redux actions.
export function startProblem() {
  return function dispatchStartProblem(dispatch: Dispatch<RootState>, getState: () => RootState) {
    const state = getState()

    dispatch({
      type: TypeKeys.PROBLEM_START,
      time: Date.now()
    } as ActionTypes.ProblemStartAction)
  }
}

export function returnAttemptData(data: GonAttemptInfo, status: SubmitStatus,
  dispatch: Dispatch<RootState>) {
  dispatch({
    type: TypeKeys.RETURN_ATTEMPT_DATA,
    data,
    status
  } as ActionTypes.ReturnAttemptDataAction)
}

export function attemptAjaxError(error: string, status: SubmitStatus,
  dispatch: Dispatch<RootState> ) {
  dispatch({
    type: TypeKeys.ATTEMPT_AJAX_ERROR,
    status,
    error
  } as ActionTypes.ReturnAttemptAjaxErrorAction)
}

export function submitAttempt() {
  return function dispatchSubmitAttempt(dispatch: Dispatch<RootState>,
    getState: () => RootState) {
    const state = getState()
    const { attempt } = state.problemInfo

    if (attempt && attempt.id) {
      const correct = state.problemInfo.playerResults === TypeKeys.PLAYER_WIN
      const { mistake } = state.problemInfo
      const json = {
        type: TypeKeys.SUBMIT_ATTEMPT,
        correct,
        mistake: correct ? null : mistake
      } as ActionTypes.SubmitAttemptAction
      const ints = _.map(JSON.stringify(json), ((char) => char.charCodeAt(0) ^ 67))
      const encoded = String.fromCharCode.apply(null, ints)

      $.ajax(ajaxObject(attempt.id, encoded, dispatch))
    }
  }
}

export function playerWin(): ActionTypes.PlayerWinProblemAction {
  return {
    type: TypeKeys.PLAYER_WIN,
    time: Date.now()
  }
}

export function playerLose(mistake: string): ActionTypes.PlayerLoseProblemAction {
  return {
    type: TypeKeys.PLAYER_LOSE,
    time: Date.now(),
    mistake
  }
}

export function playerAlt(mistake: string): ActionTypes.PlayerHitAlternateProblemAction {
  return {
    type: TypeKeys.PLAYER_ALT,
    mistake
  }
}

// TODO: This fails if the move made isn't in the problem solution. It can go
// around twice. Is there a better way, maybe something that only checks when
// the've called forward or set_player_move?
export function checkPlayerMove() {
  return function dispatchCheckPlayerMove(dispatch: Dispatch<RootState>,
    getState: () => RootState) {
    let goForward
    clearTimeout(timer)

    // Make sure we play the computer response if there is one.
    const state = getState()
    const { explorer } = state
    const animationDone = state.animate.done
    const animationStarted = state.animate.started
    const problemSolved = state.problemInfo.solved
    const { playerResults } = state.problemInfo

    // What move did they play?
    const moveMade = currentMove(explorer)
    const nextMove = explorer.moves[nextMoveIndex(explorer)]
    // prePos is sometimes null, but post pos is always available.
    const whiteToPlay = !explorer.positions[moveMade.postPos].whiteToPlay

    // And what is the equivalent main line index even if the move is a mistake
    // and not on the main line. Used by alts and mistakes.
    const previousMove = prevMoveIndex(explorer)
    const mainLineIndex = explorer.mainLine.indexOf(previousMove)
    let mistakeIndex = mainLineIndex + 1 // Move forward one ply
    mistakeIndex = Math.ceil((mistakeIndex + 1) / 2) // And convert to move

    let mistake = mistakeIndex.toString()
    mistake += whiteToPlay ? '... ' : '. ' // white moves second.
    mistake += moveMade.description

    debugAction(`move: ${mistake}`)

    // Hopefully we get called again when the animation is finished.
    if (moveMade.isComputerMove || (!animationDone && animationStarted)) {
      if (explorer.goingForward && (moveMade.description === 'Beginning') &&
         state.problemInfo.context_move) {
        debugAction('Context move, going forward')
        goForward = () => dispatch(forward())
        timer = window.setTimeout(goForward, 250)
        return
      }
      debugAction('computerMove, do nothing.')
      return
      // Do nothing
    } else if (moveMade.isPlayerWin && !problemSolved) {
      debugAction('Player win!')
      dispatch(playerWin())
      dispatch(submitAttempt())
      return
    } else if (moveMade.isAltPlayerMove && !problemSolved) {
      // Make sure we don't get called more than once because the timer will
      // receive props will get called again the moment the defense is played.
      debugAction('Player Alt')
      const prevMistake = state.problemInfo.mistake
      if ((playerResults !== TypeKeys.PLAYER_ALT) || (prevMistake !== mistake)) {
        dispatch(playerAlt(mistake))
      }
      const backward = () => dispatch({ type: TypeKeys.BACKWARD })
      timer = window.setTimeout(backward, 250)
      return
    } else if (moveMade.isMainLine && nextMove.isMainLine &&
            (nextMove !== moveMade) && explorer.goingForward) {
      if (!state.userMove.justNavigated) {
        // # don't auto-play on forward button
        debugAction('MainLine, going forward')
        goForward = () => dispatch(forward())
        timer = window.setTimeout(goForward, 250)
        return
      }
      debugAction('Navigation, do nothing')
    } else if (!problemSolved) {
      debugAction('Player Lose')
      dispatch(playerLose(mistake))
      dispatch(submitAttempt())
      return
    }
    debugAction('going backward so nothing?')
  }
}
