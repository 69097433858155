
import * as React from 'react'
import { div, p, a, table, tbody, tr, th, td } from 'react-dom-factories'

import { capitalize } from '../../enhancements/utilities'

import * as _ from 'lodash'
import * as $ from 'jquery'

class SubscriptionInfo extends React.Component<ISubscriptionInfoProps, ISubscriptionInfoState> {
  props!: ISubscriptionInfoProps
  state: ISubscriptionInfoState

  constructor(props: ISubscriptionInfoProps) {
    super(props)
    this.state = {
      populated: false,
      info: {
        next_billing_date: '',
        paid_through_date: '',
        never_expires: false,
        number_of_billing_cycles: 0,
        current_billing_cycle: 0,
        credit_remaining: 0,
        level: '',
        cycle: ''
      },
      ajax: null,
      error: ''
    }
  }

  // Returns an AJAX request on which you can call abort.
  ajaxRequest() {
    // console.log "Ajax call "
    return $.ajax({
      url: '/subscription/braintree',
      dataType: 'json',
      error: ((jqXHR, textStatus, errorThrown) => {
        // console.log('ajax error')
        this.setState({
          error: `AJAX Error: ${errorThrown}`,
          populated: true
        })
      }),
      success: ((data) =>
        // console.log "ajax return"
        this.setState({
          info: data,
          populated: true
        }))
    })
  }

  refresh() {
    this.setState({
      ajax: this.ajaxRequest()
    })
  }

  componentWillUnmount() {
    if (this.state.ajax) {
      this.state.ajax.abort()
    }
  }

  componentDidMount() {
    this.refresh()
  }

  renderError() {
    return div(
      { className: 'alert alert-danger' },
      this.state.error,
    )
  }

  renderEmpty() {
    return div(
      { className: 'well' },
      div(
        { className: 'subscriptoin-info' },
        'Retrieving subscription info.',
      ),
    )
  }

  tableRow(header: string, data: string) {
    return tr(
      {},
      th(
        {},
        header,
      ),
      td(
        {},
        data,
      ),
    )
  }

  billingCycles() {
    const { info } = this.state
    const remaining = info.number_of_billing_cycles - info.current_billing_cycle
    if (remaining >= 1) {
      return this.tableRow('Remaining Cycles', String(remaining))
    }
    return null
  }

  nextBillDate() {
    const { info } = this.state
    const remaining = info.number_of_billing_cycles - info.current_billing_cycle
    const renew = info.never_expires

    if (renew || (remaining >= 1)) {
      return this.tableRow('Next Cycle Begins', info.next_billing_date)
    }
    return null
  }

  renderInfo(info: ISubscriptionAjaxInfo) {
    return div(
      { className: 'well' },
      p(
        {},
        'To change or renew your subscription, simply create a new one ',
        a(
          { href: '/subscription/new' },
          'here',
        ),
        '. Your privacy settings and any remaining credit will transfer to ' +
        'the new one.\n To cancel entirely, shoot me ',
        a(
          { href: 'mailto:brooks@checkercruncher.com' },
          'an email',
        ),
        '.',
      ),

      table(
        { className: 'table table-condensed' },
        tbody(
          {},
          this.tableRow('Level', capitalize(info.level)),
          this.tableRow('Billing Cycle', capitalize(info.cycle)),
          this.tableRow('Paid Through Date', info.paid_through_date),
          this.tableRow('Renews', capitalize(info.never_expires.toString())),
          this.nextBillDate(),
          this.tableRow('Remaining Credit', `$${info.credit_remaining.toFixed(2)}`),
          this.billingCycles(),
        ),
      ),
    )
  }

  render() {
    const { info } = this.state
    if (!_.isEmpty(this.state.error)) {
      return this.renderError()
    } else if (!this.state.populated) {
      return this.renderEmpty()
    }
    return this.renderInfo(info)
  }
}

interface ISubscriptionInfoProps {

}

interface ISubscriptionAjaxInfo {
  next_billing_date: string
  paid_through_date: string
  never_expires: boolean
  number_of_billing_cycles: number
  current_billing_cycle: number
  credit_remaining: number
  level: string
  cycle: string
}

interface ISubscriptionInfoState {
  ajax: JQuery.jqXHR<ISubscriptionAjaxInfo> | null
  error: string
  info: ISubscriptionAjaxInfo
  populated: boolean
}

export default SubscriptionInfo
