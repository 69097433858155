import * as jQuery from 'jquery'
import { isGoNative, isSignedIn } from './utilities'

const gonativeSharedMenu =
  [
    {
      url: `https://${window.location.host}/`,
      label: 'Checker Cruncher',
      subLinks: []
    },
    {
      url: `https://${window.location.host}/problems/start_next`,
      label: 'Next Puzzle',
      subLinks: []
    },
    {
      label: 'Database',
      isGrouping: true,
      subLinks: [
        {
          url: `https://${window.location.host}/games`,
          label: 'Games',
          subLinks: []
        },
        {
          url: `https://${window.location.host}/positions/explore`,
          label: 'Opening Explorer',
          subLinks: []
        },
        {
          url: `https://${window.location.host}/users`,
          label: 'Users',
          subLinks: []
        },
        {
          url: `https://${window.location.host}/problems`,
          label: 'Problems',
          subLinks: []
        },
        {
          url: `https://${window.location.host}/comments`,
          label: 'Comments',
          subLinks: []
        }
      ]
    },
    {
      url: 'http://forum.checkercruncher.com/',
      label: 'Forum',
      subLinks: []
    },
    {
      label: 'Info',
      isGrouping: true,
      subLinks: [
        {
          url: `https://${window.location.host}/news`,
          label: 'News',
          subLinks: []
        },
        {
          label: 'Memberships',
          url: `https://${window.location.host}/memberships`
        },
        {
          url: `https://${window.location.host}/links`,
          label: 'Links',
          subLinks: []
        },
        {
          url: `https://${window.location.host}/supporters`,
          label: 'Supporters',
          subLinks: []
        }
      ]
    },
    {
      label: 'Help',
      isGrouping: true,
      subLinks: [
        {
          url: `https://${window.location.host}/guide`,
          label: 'User Guide',
          subLinks: []
        },
        {
          url: `https://${window.location.host}/rules`,
          label: 'Game Rules',
          subLinks: []
        }
      ]
    }
  ]

const gonativeSignedInMenu =
  {
    label: 'Account',
    isGrouping: true,
    subLinks: [
      {
        label: 'Attempt History',
        url: `https://${window.location.host}/users/me`
      },
      {
        label: 'Profile',
        url: `https://${window.location.host}/users/edit`
      },
      {
        label: 'Settings',
        url: `https://${window.location.host}/settings`
      },
      {
        label: 'Subscription',
        url: `https://${window.location.host}/subscription`
      },
      {
        label: 'Sign Out',
        url: `https://${window.location.host}/users/gonative_logout`
      }
    ]
  }

const gonativeSignedOutMenu =
  {
    url: `https://${window.location.host}/users/sign_in`,
    label: 'Sign In',
    subLinks: []
  }

jQuery(document).ready(() => {
  // console.log("Gonnative initialised")
  if (isGoNative()) {
    let menu
    if (isSignedIn()) {
      menu = gonativeSharedMenu.concat(gonativeSignedInMenu)
    } else {
      menu = gonativeSharedMenu.concat(gonativeSignedOutMenu)
    }

    const json = JSON.stringify(menu)
    window.location.href =
      `gonative://sidebar/setItems?items=${encodeURIComponent(json)}`
  }
})

