import * as React from 'react'
import * as PropTypes from 'prop-types'

import { div, h3 } from 'react-dom-factories'

export class PlayerToMove extends React.PureComponent<IPlayerToMoveProps> {
  props!: IPlayerToMoveProps

  constructor(props: IPlayerToMoveProps) {
    super(props)
  }

  render() {
    const turn = this.props.whiteToPlay ?
      'white-to-play'
      :
      'black-to-play'

    return div(
      { className: `player-turn ${this.props.pieceSelection}` },
      div(
        { className: 'turn-text' },
        h3(
          {},
          'To Move:',
        ),
      ),
      div(
        { className: 'turn-image' },
        div({ className: `to-move ${turn}` }),
      ),
    )
  }
}

export interface IPlayerToMoveProps {
  whiteToPlay: boolean
  pieceSelection: string
}
