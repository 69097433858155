/* eslint no-console: off */

import * as _ from 'lodash'
import { theBoard } from './board'
import { Square } from './square'
import { Piece } from './piece'
import { Direction } from './direction'
import { Position } from './position'

// Builds, stores, and applies a legal move to a position.
class Move {
  startSquare:Square
  landedSquares:Square[]
  capturedSquares:Square[]
  causesPromote:boolean
  legal:boolean
  //TODO: legal should be name isLegal

  constructor(startSquare: Square) {
    this.startSquare = startSquare
    this.landedSquares = [] // An array of padded indexes, not PDNs
    this.capturedSquares = []
    this.causesPromote = false
    this.legal = false
  }

  // Returns a not quite shallow copy of a move. The list of squares is new
  // But it refers to the same squares. Used by the rules when building
  // branching jump paths.
  clone() {
    const clone = new Move(this.startSquare)
    this.landedSquares.forEach((sq) =>
      clone.landedSquares.push(sq))

    this.capturedSquares.forEach((sq) =>
      clone.capturedSquares.push(sq))

    clone.causesPromote = this.causesPromote
    clone.legal = this.legal
    return clone
  }

  // Used both by debugging and the computer responder as the key to its
  // json structure for finding the response.
  toString() {
    if (this.isCapture()) {
      return this.touchedPdns().join('x')
    }
    return this.touchedPdns().join('-')
  }

  isCapture() {
    return this.capturedSquares.length > 0
  }

  touchedPdns() {
    return _.concat(
      this.startSquare.pdn(),
      this.landedSquares.map((sq) => sq.pdn()),
    )
  }

  // Marks this move as a simple (non-capture) rule.
  addSimpleMove(position: Position, direction: Direction) {
    const piece = position.getPiece(this.startSquare)
    if (!piece) {
      throw new Error("Moving null piece")
    }

    const targetSquare = theBoard.adjacentSquare(this.startSquare, direction)
    if ((targetSquare.whoPromotes === piece.player.color) && !piece.isKing) {
      this.causesPromote = true
    }

    console.assert(
      position.squareEmpty(targetSquare),
      "can't move to occupied square",
    )
    console.assert(this.landedSquares.length === 0, "can't move twice")

    this.landedSquares.push(targetSquare)
    this.legal = true
  }

  // Adds one (of possibly several) jumps to this move.
  addJumpMove(position: Position, direction: Direction) {
    const piece = position.getPiece(this.startSquare)
    if (!piece) {
      throw new Error("Jumping with null piece")
    }

    console.assert(this.legal === false, "can't jump after promoting!")
    const last = this.currentEndingSquare()
    const targetSquare = theBoard.jumpSquare(last, direction)
    const capturedSquare = theBoard.adjacentSquare(last, direction)
    if (!targetSquare) {
      throw new Error('jumped off the board')
    }
    if ((targetSquare.whoPromotes === piece.player.color) && !piece.isKing) {
      this.causesPromote = true
      this.legal = true
    }

    const jumped = position.getPiece(capturedSquare)
    if(!jumped || !jumped.isEnemy(piece)) {
      throw new Error("Can't jump empty square or friendly piece")
    }

    console.assert(
      this.capturedSquares.indexOf(capturedSquare) < 0,
      'captured piece twice',
    )
    console.assert(position.squareEmpty(targetSquare) ||
      (position.getPiece(targetSquare) === piece), 'landed on piece.')

    this.landedSquares.push(targetSquare)
    this.capturedSquares.push(capturedSquare)
  }

  // Used by rules to calculate where to search for possible addionional jumps
  currentEndingSquare(): Square {
    if (this.landedSquares.length > 0) {
      return _.last(this.landedSquares)!
    }
    return this.startSquare
  }

  // Used by rules to prevent multiple hopes over the same piece.
  capturedPreviously(square: Square) {
    return this.capturedSquares.indexOf(square) >= 0
  }

  // Modifies our stored position with the results of this move.
  applyMove(position: Position) {
    const piece = position.getPiece(this.startSquare)
    const lastSquare = _.last(this.landedSquares)
    if (!piece || piece.player !== position.playerToMove() || !lastSquare) {
      throw new Error('Applying invalid move')
    }

    if (this.causesPromote) {
      piece.promote()
    }
    piece.moveTo(lastSquare)

    this.capturedSquares.forEach((capturedSquare) => {
      const captured = position.getPiece(capturedSquare)
      if (!captured){
        throw new Error("Can't jump empty square")
      }
      position.removePiece(captured)
    })
    position.swapPlayer2Move()
  }

  // We can't undo moves unless we store what kinds of pieces we captured
  // in addition to what squares we captured
  // undoMove: (position) ->
  //   piece = position.getPiece(this.currentEndingSquare())
  //   if @causesPromote
  //     piece.unPromote()
  //   piece.moveTo(@startSquare)
  //   position.swapPlayer2Move()
  //   for captured in @capturedPieces
  //     @position.addPiece(captured)

  toJSON(): SerializedMove {
    return {
      description: this.toString(),
      startSquare: this.startSquare.pdn(),
      landedSquares: _.map(this.landedSquares, (sq) => sq.pdn()),
      capturedSquares: _.map(this.capturedSquares, (sq) => sq.pdn()),
      causesPromote: this.causesPromote
    }
  }
}

export interface SerializedMove {
  description: string
  startSquare: number
  landedSquares: number[]
  capturedSquares: number[]
  causesPromote: boolean
}

export { Move }

