
import { connect } from 'react-redux'

import { GameList } from '../components/game_list'
import { RootState } from '../reducers/root'
import { ExplorerState } from '../reducers/explorerReducer'

interface StateProps {
  explorer: ExplorerState
  show: boolean
}

const GameListApp = {
  mapStateToProps(state: RootState): StateProps {
    const show = !state.problemInfo.problemId && !state.gameInfo.game
    return {
      explorer: state.explorer,
      show
    }
  },

  mapDispatchToProps() {
    return {}
  }
}

export default connect(
  GameListApp.mapStateToProps,
  GameListApp.mapDispatchToProps,
)(GameList as any)
