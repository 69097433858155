
import * as React from 'react'
import * as PropTypes from 'prop-types'

import * as ReactDnd from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'

import { ReactPiece as Piece } from '../components/piece'
import { ReactSquare as Square } from '../components/square'
import { DroppableSquareProps } from '../containers/droppable_square'

// Specify the type of the drag source
import { TypeKeys } from '../types'

import { div } from 'react-dom-factories'

const e = React.createElement;

// Specifiy the drag source contract. Only beginDrag is required
const pieceSource = {
  // We return the engine piece as that is what is being dragged.
  // It should be available in the drop monitor.
  beginDrag(props: DraggablePieceProps) {
    return props
  },

  // OPTIMIZE: Called slowly if there is no drop_target, is it looking for one?
  // Maybe all square should be drop targets.
  endDrag(props: DraggablePieceProps, monitor: ReactDnd.DragSourceMonitor) {
    // Third argument is a component
    const handled = monitor.didDrop()
    if (handled) {
      const result = monitor.getDropResult() as DroppableSquareProps
      if(result.pdn) {
        props.dragPiece(props.pdn, result.pdn)
      }
    }
  },

  // Implement this to prevent users from dragging illigal pieces
  canDrag(props: DraggablePieceProps) {
    // Second argument is a monitor
    return props.userCanDragNow
  }
}

// Specify which props to inject into the piece component
const pieceCollect = (connect: ReactDnd.DragSourceConnector,
  monitor: ReactDnd.DragSourceMonitor) => ({
  // Second argument is a monitor
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging()
})

// I'm not sure this does anything now. Since we need everything in
// arePropsEqual we shouldn't get any performance benefits.
const pieceOptions = {
  arePropsEqual(props: DraggablePieceProps, nextProps: DraggablePieceProps) {
    if ((props.pdn !== nextProps.pdn) ||
       (props.color !== nextProps.color) ||
       (props.level !== nextProps.level) ||
       (props.size !== nextProps.size) ||
       (props.userCanDragNow !== nextProps.userCanDragNow) ||
       (props.isDragging !== nextProps.isDragging) ||
       (props.colorToPlay !== nextProps.colorToPlay)) {
      return false
    }
    return true
  }
}

class DraggablePiece extends React.Component<DraggablePieceProps> {
  props!: DraggablePieceProps
  constructor(props: DraggablePieceProps) {
    super(props)
  }

  // Here we don't care if you can drag the piece or not it looks the same.
  shouldComponentUpdate(nextProps: DraggablePieceProps) {
    if ((this.props.pdn !== nextProps.pdn) ||
       (this.props.color !== nextProps.color) ||
       (this.props.level !== nextProps.level) ||
       (this.props.size !== nextProps.size) ||
       (this.props.isDragging !== nextProps.isDragging)) {
      return true
    }
    return false
  }

  handleClick() {
    // Could take an event variable
    this.props.clickSquare(this.props.pdn)
  }

  componentDidMount() {
    let img = getEmptyImage();
    this.props.connectDragPreview!(img)
    // img.onload = () => this.props.connectDragPreview(img, {
    //   captureDraggingState: true
    // })
  }

  handleContextMenu(event: MouseEvent) {
    event.preventDefault()
  }

  handleMouseDown(event: MouseEvent) {
    // console.log event.button
    if (this.props.pdn == null) { return }
    if (event.button === 2) {
      this.props.rightMouseDown(this.props.pdn)
    }
  }

  handleMouseUp(event: MouseEvent) {
    if (this.props.pdn == null) { return }
    if (event.button === 2) {
      this.props.rightMouseUp(this.props.pdn)
    }
  }

  handleMouseEnter() {
    // Takes an event argument.
    if (this.props.pdn == null) { return }
    // console.log "mouse enter."
    this.props.rightMouseEnter(this.props.pdn)
  }


  render() {
    const { connectDragSource } = this.props
    const { isDragging } = this.props

    const opacity = isDragging ? 0.0 : 1

    const pieceProps = {
      color: this.props.color,
      level: this.props.level,
      size: this.props.size,
      pieceSelection: this.props.pieceSelection
    }

    return connectDragSource!(
      div(
        {
          onClick: this.handleClick.bind(this),
          onContextMenu: this.handleContextMenu.bind(this),
          onMouseDown: this.handleMouseDown.bind(this),
          onMouseUp: this.handleMouseUp.bind(this),
          onMouseEnter: this.handleMouseEnter.bind(this),
          style: { opacity }
        },
        e(Piece, pieceProps)
    ))
  }
}

interface DraggablePieceProps {
  // Injected by react-dnd from connect down below
  // Marked as optional because the tyings for react-dnd aren't perfect.
  // as of february, 2018.
  connectDragSource?: ReactDnd.ConnectDragSource
  connectDragPreview?: ReactDnd.ConnectDragPreview
  isDragging?: boolean

  // Injected by react-redux from draggable_piece_app
  colorToPlay: string
  dragPiece: (a: number, b: number) => void
  clickSquare: (pdn: number) => void
  rightMouseDown: (pdn: number) => void
  rightMouseUp: (pdn: number) => void
  rightMouseEnter: (pdn: number) => void

  // Needed by components/piece
  color: string
  level: string
  size: number
  pieceSelection: string

  // Needed for endDrag so we can tell which piece the user is dragging.
  pdn: number
  // Needed to forbid the user draging pieces on the computer's turn
  userCanDragNow: boolean
}

// DraggablePiece.propTypes = {
//   // Injected by react-dnd from connect down below
//   connectDragSource: PropTypes.func.isRequired,
//   connectDragPreview: PropTypes.func.isRequired,
//   isDragging: PropTypes.bool.isRequired,

//   // Injected by react-redux from draggable_piece_app
//   colorToPlay: PropTypes.string.isRequired,
//   dragPiece: PropTypes.func.isRequired,
//   clickSquare: PropTypes.func.isRequired,
//   rightMouseDown: PropTypes.func.isRequired,
//   rightMouseUp: PropTypes.func.isRequired,
//   rightMouseEnter: PropTypes.func.isRequired,

//   // Needed by components/piece
//   color: PropTypes.string.isRequired,
//   type: PropTypes.string.isRequired,
//   size: PropTypes.number.isRequired,
//   pieceSelection: PropTypes.string.isRequired,

//   // Needed for endDrag so we can tell which piece the user is dragging.
//   pdn: PropTypes.number.isRequired,
//   // Needed to forbid the user draging pieces on the computer's turn
//   userCanDragNow: PropTypes.bool.isRequired
// }

export default ReactDnd.DragSource(
  TypeKeys.PIECE, pieceSource,
  pieceCollect, pieceOptions,
)(DraggablePiece)
