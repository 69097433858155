/* eslint react/forbid-prop-types: ["error", {"forbid": ["any", "array"] }] */
/* eslint react/no-did-mount-set-state: 'off' */

import { currentPosition } from '../reducers/explorerFunctions'

import * as React from 'react'
import * as PropTypes from 'prop-types'

import { div } from 'react-dom-factories'

import * as $ from 'jquery'

import { ExplorerState } from '../reducers/explorerReducer'
import { Position } from '../../engine/position'

class OpeningStats extends React.Component<IOpeningStatsProps, IOpeningStatsState> {
  props!: IOpeningStatsProps
  state: IOpeningStatsState

  constructor(props: IOpeningStatsProps) {
    super(props)
    this.state = {
      ajax: null,
      timer: 0
    }
  }
  // Returns an AJAX request on which you can call abort.
  // TODO: This should request json and render that in react for cleaner
  // lifecycle, rather than setState in componentDidMount.
  ajaxRequest(element: JQuery<HTMLElement>, positionJSON: Position) {
    if (this.props.show) {
      return $.ajax({
        url: '/positions/stats',
        data: {
          board: positionJSON.toPadded(),
          white_to_play: positionJSON.whiteToPlay
        },
        dataType: 'html',
        error(jqXHR, textStatus) {
          element.html('')
          element.append(`AJAX Error: ${textStatus}`)
        },
        success(data) {
          // Gets textData and jqXHR
          element.html('')
          element.append(data)
        }
      })
    }
    return null
  }

  refresh(props: IOpeningStatsProps) {
    // console.log "refreshing at: #{performance.now()}"
    if (this.state.ajax) {
      this.state.ajax.abort()
    }
    this.setState({
      ajax: this.ajaxRequest(
        $('#opening-stats'),
        currentPosition(props.explorer),
      )
    })
  }

  // Cancel the previous ajax request if it hasn't returned
  // And create the next ajax request, but don't send it. We've already
  // rendered so the element will still exist
  componentDidUpdate(prevProps: IOpeningStatsProps) {
    // console.log "changing props at: #{performance.now()}"
    if (this.props.show && this.props.explorer.currentMove !== prevProps.explorer.currentMove) {
      if (this.state.timer) {
        clearTimeout(this.state.timer)
      }
      this.setState({
        // only update if they aren't changing the state too often.
        timer: window.setTimeout((() => this.refresh(this.props)), 200)
      })
    }
  }

  componentWillUnmount() {
    if (this.state.ajax) {
      this.state.ajax.abort()
    }
  }

  componentDidMount() {
    this.setState({
      ajax: this.ajaxRequest(
        $('#opening-stats'),
        currentPosition(this.props.explorer),
      )
    })
  }

  render() {
    if (this.props.show) {
      return div({ id: 'opening-stats' })
    }
    return null
  }
}


interface IOpeningStatsProps {
  explorer: ExplorerState
  show: boolean
}

interface IOpeningStatsState {
  ajax: JQuery.jqXHR<HTMLDivElement> | null
  timer: number
}

export default OpeningStats
