
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'

import GameTimer from '../components/game_timer'
import * as ExplorerActions from '../actions/explorerActions'
import { RootState } from '../reducers/root'


interface IStateProps {
  animationDone: boolean
  animationStarted: boolean
  autoPlay: boolean
}

interface IDispatchProps {
  autoForward: () => void
}

const GameTimerApp = {
  mapStateToProps(state: RootState): IStateProps {
    return {
      animationDone: state.animate.done,
      animationStarted: state.animate.started,
      autoPlay: state.explorer.autoPlay
    }
  },

  mapDispatchToProps(dispatch: Dispatch<RootState>): IDispatchProps {
    return bindActionCreators(ExplorerActions, dispatch)
  }
}

export default connect(
  GameTimerApp.mapStateToProps,
  GameTimerApp.mapDispatchToProps,
)(GameTimer as any)
