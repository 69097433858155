/* eslint no-console: off */

import * as _ from 'lodash'
import { Piece, PieceData } from './piece'
import { theBoard } from './board'
import { Square } from './square'
import { Move } from './move'
import { Player, blackPlayer, whitePlayer } from './players'
import { GonPositionInfo } from '../components/types'
/*
 * decaffeinate suggestions:
 * DS205: Consider reworking code to avoid use of IIFEs
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
//
// Portable Draughts Notation (PDN) square numbers, note one offset
// Red plays from the top and goes first.
// But the numbers read left to right top to bottom for white.
// ##  ##  ##  ##  ##
//   01  02  03  04
// 05  06  07  08  ##
//   09  10  11  12
// 13  14  15  16  ##
//   17  18  19  20
// 21  22  23  24  ##
//   25  26  27  28
// 29  30  31  32  ##
//   ##  ##  ##  ##
//
// Internal Array Index
// 00  01  02  03  04 ##top border
//   05  06  07  08
// 09  10  11  12  13*
//   14  15  16  17
// 18  19  20  21  22*
//   23  24  25  26
// 27  28  29  30  31*
//   32  33  34  35
// 36  37  38  39  40*
//   41  42  43  44  ##bottom border

export class Position {
  // @initialPosition = '#####rrrrrrrr#rrrr0000#0000wwww#wwwwwwww#####'
  whiteToPlay:boolean
  pieces:Piece[]
  index: number //index into array of positions

  constructor() {
    this.whiteToPlay = false
    this.pieces = []
    this.index = -1
  }

  static initFromGonPosition(positionInfo: GonPositionInfo | SerializedPosition) {
    const position = new Position()

    if (isGonPositionInfo(positionInfo)) {
      position.whiteToPlay = positionInfo.white_to_play
    } else if(isSerializedPosition(positionInfo)) {
      position.whiteToPlay = positionInfo.whiteToPlay
    }
    position.placePieces(positionInfo.board)

    console.assert(
      ((position.whiteToPlay === false) || (position.whiteToPlay === true)),
      'whiteToPlay must be true or false.',
    )
    console.assert((position.pieces.length > 0), 'piece count must be positive')

    return position
  }

  static initFromFenPosition(fenDescription: string) {
    const position = new Position()
    const [player, pieces] = this.fenParts(fenDescription)

    position.playerFromFen(player)
    position.placeFromFen(pieces)

    console.assert(
      ((position.whiteToPlay === false) || (position.whiteToPlay === true)),
      'whiteToPlay must be true or false.',
    )
    console.assert((position.pieces.length > 0), 'piece count must be positive')

    return position
  }

  static fenParts(fenDescription: string) {
    const fenPattern = new RegExp('^' + // Start of string
      '([WB]):' + // Player W or B to play
      '((?:(?:[WB])' + // Player W or B to list pieces
      '(?:(?:K?[\\d]{1,2})[,:]?)' + // Pieces, some of which are kings.
      '+){1,2})' + // The other player
      '$') // End of string

    const match = fenDescription.match(fenPattern)
    if (match == null) {
      throw new Error('invalid fen description')
    }
    const player = match[1]
    const pieces = match[2]
    return [player, pieces]
  }

  playerFromFen(fenPart: string) {
    if (fenPart === 'W') {
      this.whiteToPlay = true
    } else if (fenPart === 'B') {
      this.whiteToPlay = false
    }
  }

  placeFromFen(fenPart: string) {
    let sideResults
    let player
    let pieceResults

    const sidePattern = new RegExp(
      '([WB])((?:(?:K?\\d{1,2})[,:]?)+)',
      'g' // global so we can match it multiple times
    )

    /* eslint no-cond-assign: off */
    while ((sideResults = sidePattern.exec(fenPart)) !== null) {
      const playerLetter = sideResults[1]
      const pieceLocations = sideResults[2]

      if (playerLetter === 'B') {
        player = blackPlayer
      } else if (playerLetter === 'W') {
        player = whitePlayer
      }
      else {
        throw new Error("No player letter in fen")
      }

      const piecePattern = /(K?)(\d{1,2})/g
      while ((pieceResults = piecePattern.exec(pieceLocations)) !== null) {
        const isKing = pieceResults[1] === 'K'
        const pdn = parseInt(pieceResults[2], 10)
        const index = Position.getSquareByPdn(pdn).squareNum

        const piece = new Piece(player, index, isKing)
        this.pieces.push(piece)
      }
    }
  }

  // the position string is a 45 character padded array.
  placePieces(positionString: string) {
    let player
    if (!positionString) { throw new Error('Empty position string') }
    const chars = positionString.split('')
    for (let index = 0; index < chars.length; index += 1) {
      const char = chars[index]
      if ((char !== '0') && (char !== '#')) { // 0s are empty, #s are borders
        const isKing = char === char.toUpperCase() // captial letters are kings.
        if (char.toUpperCase() === 'R') { // r's are red, w's are white.
          player = blackPlayer
        } else {
          player = whitePlayer
        }

        const piece = new Piece(player, index, isKing)
        this.pieces.push(piece)
      }
    }
  }

  // options can be player: "white" or kings: "true", etc.
  getPieces(options: GetPieceOptions = {}) {
    let filteredPieces = _.clone(this.pieces)
    if (options['kings'] != null) {
      filteredPieces = _.filter(filteredPieces, ((piece) =>
        piece.isKing === options['kings']))
    }
    if (options['player'] != null) {
      filteredPieces = _.filter(filteredPieces, ((piece) =>
        piece.player === options['player']))
    }
    return filteredPieces
  }

  friendlyPiecePdns() {
    const friendlyPieces = this.getPieces({ player: this.playerToMove() })
    return friendlyPieces.map((piece) => piece.pdn())
  }

  enemyPiecePdns() {
    const enemyPieces = this.getPieces({ player: this.otherPlayer() })
    return enemyPieces.map((piece) => piece.pdn())
  }

  // This removes a piece from the board. Not yet undoable.
  removePiece(piece: Piece) {
    this.pieces = _.without(this.pieces, piece)
  }

  addPiece(piece: Piece) {
    this.pieces.push(piece)
  }

  // The checkers pdn is a value 1 to 32 marking squares. They go left to right
  // top to bottom from white's perspective
  static getSquareByPdn(pdn: number) {
    if ((pdn < 1) || (pdn > 32)) {
      throw new Error('pdn out of bounds')
    }
    let padded = pdn + 4
    padded += Math.floor((pdn - 1) / 8) // coffeescript integer division
    return theBoard.squares[padded]
  }

  getPieceByPdn(pdn: number) {
    const square = Position.getSquareByPdn(pdn)
    if (square) {
      return this.getPiece(square)
    }
    return null
  }

  // The chess row and col are both 0-7 and start at the bottom left from
  // white's perspective. So 0,0 should give square, 29, 7,7 should give square 4
  // We have no representation of white squares so those return nil
  static getSquareByRowCol(reversedRowNum: number, colNum: number) {
    // First we need to reverse the "bottom" of the board.
    const rowNum = 7 - reversedRowNum

    const square64 = (rowNum * 8) + colNum // 0 - 63, even numbers are light squares
    const square32 = (square64 / 2) + 1
    const pdn = Math.floor(square32)

    if (!((rowNum + colNum) % 2)) {
      return null // white square.
    }
    return Position.getSquareByPdn(pdn)
  }

  swapPlayer2Move() {
    this.whiteToPlay = !this.whiteToPlay
  }

  getPiece(square: Square | null): Piece | null {
    if ((square == null)) {
      return null
    }

    return _.filter(this.pieces, ((piece) =>
      piece.squareNum === square.squareNum))[0]
  }

  squareEmpty(square: Square | null): boolean {
    if (!square) {
      return false
    }
    return ((this.getPiece(square) == null) && !square.isBorder)
  }

  clone(): Position {
    const gonPosition = { board: this.toPadded(), white_to_play: this.whiteToPlay }
    return Position.initFromGonPosition(gonPosition as GonPositionInfo)
  }

  nextPosition(move: Move) {
    const next = this.clone()
    move.applyMove(next)
    return next
  }
  // Need to apply moves to multiple positions so move.position
  // has to be refactored out.

  toPadded() {
    const array = theBoard.squares.map((square) => {
      let char = '0'
      const piece = this.getPiece(square)

      if (square.isBorder) {
        char = '#'
      } else if (piece) {
        char = piece.toPadded()
      }
      return char
    })
    return array.join('')
  }

  playerToMove() {
    if (this.whiteToPlay) {
      return whitePlayer
    }
    return blackPlayer
  }

  otherPlayer() {
    if (this.whiteToPlay) {
      return blackPlayer
    }
    return whitePlayer
  }

  toConsole() {
    const player = this.playerToMove().color as string
    const rowStrings = [player]

    for (let row = 7; row >= 0; row -= 1) {
      const colChars: string[] = []
      for (let col = 0; col < 8; col += 1) {
        const square = Position.getSquareByRowCol(row, col)
        // if square?
        //   colChars.push "#{square.pdn()} "
        if ((square == null)) {
          colChars.push(' ')
        } else {
          const piece = this.getPiece(square)
          if ((piece == null)) {
            colChars.push('0')
          } else {
            colChars.push(piece.toPadded())
          }
        }
      }
      rowStrings.push(colChars.join(''))
    }

    return rowStrings.join('\n')
  }

  toReduxState(): SerializedPosition {
    return {
      board: this.toPadded(),
      whiteToPlay: this.whiteToPlay,
      pieces: this.getPieces().map(
        ((piece) =>
          ({
            pdn: piece.square().pdn(),
            color: piece.colorString(),
            level: piece.levelString()
          })
        ), this,
      ),
      fen: this.toFenDescription()
    }
  }

  toFenDescription() {
    let whitePieces = this.getPieces({ player: whitePlayer })
    let blackPieces = this.getPieces({ player: blackPlayer })

    const whitePiecesStr = whitePieces.map(((piece) => {
      const kingMarker = piece.isKing ? 'K' : ''
      return `${kingMarker}${piece.square().pdn()}`
    }), this).join(',')

    const blackPiecesStr = blackPieces.map(((piece) => {
      const kingMarker = piece.isKing ? 'K' : ''
      return `${kingMarker}${piece.square().pdn()}`
    }), this).join(',')

    const toPlayMarker = this.whiteToPlay ? 'W' : 'B'
    return `${toPlayMarker}:W${whitePiecesStr}:B${blackPiecesStr}`
  }
}

export interface SerializedPosition {
    readonly board: string
    readonly whiteToPlay: boolean
    readonly pieces: PieceData[]
    readonly fen: string
}

function isSerializedPosition(init: SerializedPosition | GonPositionInfo): init is SerializedPosition {
  return (init as SerializedPosition).whiteToPlay !== undefined;
}

function isGonPositionInfo(init: SerializedPosition | GonPositionInfo): init is GonPositionInfo {
  return (init as GonPositionInfo).white_to_play !== undefined;
}

interface GetPieceOptions {
  kings?: boolean
  player?: Player
}
